import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {QuickTip, Input} from 'components';
import {t} from 'shared/core';

const InviteAction = observer(({uiState}) => {
  const {onboardingStatus} = uiState.editEmployee;

  switch(onboardingStatus) {
    case 'invite_sent':
      return (
        <Invited uiState={uiState}/>
      );
    case 'invite_not_sent_after_company_launch':
    case 'invite_skipped':
      return (
        <NotInvitedWithInviteLink uiState={uiState}/>
      );
    default:
      return (
        <NotInvited uiState={uiState}/>
      );
  }
});

const NotInvitedWithInviteLink = observer(({uiState}) => {
  return (
    <ModalButtons saveCaption='employees.profile.Save & Send' onSave={() => uiState.saveAndSendInviteEmail()} onCancel={() => uiState.resetModals()}/>
  );
});

const NotInvited = observer(({uiState}) => {
  return (
    <ModalButtons onSave={() => uiState.saveInviteEmail()} onCancel={() => uiState.resetModals()}/>
  );
});

const Invited = observer(({uiState}) => {
  const {editEmployee} = uiState;

  return (
    <div>
      {editEmployee.hasLink('invitationLink') && <div className='mt2'>
        <QuickTip trait='caution'
          header={t('employees.profile.This employee has not started onboarding.')}
          paragraphs={[
            t('employees.profile.You may manually send this link to the employee or resend the invitation email.'),
            <a href={editEmployee.link('invitationLink')}>{editEmployee.link('invitationLink')}</a>
          ]} />
      </div>}
      <ModalButtons saveCaption='employees.profile.Save & Resend' onSave={() => uiState.saveAndSendInviteEmail()} onCancel={() => uiState.resetModals()}/>
    </div>
  );
});

const EmployeeInviteModal = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal
      size='sm'
      isOpen={uiState.inviteModalOpen}
      onHide={() => uiState.resetModals()}
      title='employees.profile.Edit Invite Email Address'>
      <form>
        <Input
          className='fs-hide'
          value={editEmployee.invitationEmail}
          onChange={(e) => editEmployee.invitationEmail = e.target.value}
          errorMessage={errors.invitationEmail}
        />
        <InviteAction uiState={uiState}/>
      </form>
    </Modal>
  );
});

export default EmployeeInviteModal;
