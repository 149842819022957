import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import CustomHours from './CustomHours';

const EventDetailsModal = observer(({uiState, modalOpen}) => {
  const {selectedEvent} = uiState;

  return (
    <Modal
      title={selectedEvent.title}
      size='sm'
      isOpen={modalOpen}
      onHide={() => uiState.closeEventDetailsModal()}
    >
      <CustomHours event={selectedEvent} onCloseModal={() => uiState.closeEventDetailsModal()}/>
    </Modal>
  );
});

export default EventDetailsModal;
