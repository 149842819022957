import {computed, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';

class ReportIndexState {
  scope;
  store = new DomainStore();

  departmentProfile;
  ageProfile;
  tenureProfile;
  genderProfile;
  legalGenderProfile;
  activeEmployees;
  onLeaveEmployees;
  terminatedEmployees;
  newHiresAndTerminations;
  emergencyContacts;
  timeOffBalances;
  vacationLiability;
  manualAdjustments;
  timeOffRequests;
  payrollReconciliation;
  projectHours;
  payrollUpdates;
  benefitUpdates;
  dependentEligibility;
  candidates;
  customReports;
  employeeGoals;
  performanceReviews;
  surveys;
  anonymousSurveys;
  headcount;
  turnover;
  trainingSummary;
  trainingResponses;
  hiringVelocity;
  documents;

  @action async load() {
    await this.store._compose([
      endpoints.REPORTS.NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;

    this.departmentProfile = this.scope.employeesByDepartmentChart;
    this.ageProfile = this.scope.ageDemographicsChart;
    this.tenureProfile = this.scope.tenureDemographicsChart;
    this.genderProfile = this.scope.genderDemographicsChart && this.scope.genderByDepartmentChart;
    this.legalGenderProfile = this.scope.legalGenderDemographicsChart;
    this.activeEmployees = this.scope.activeEmployees;
    this.onLeaveEmployees = this.scope.onLeaveEmployees;
    this.terminatedEmployees = this.scope.terminatedEmployees;
    this.newHiresAndTerminations = this.scope.hiresAndTerminations && this.scope.hiresAndTerminationsChart;
    this.emergencyContacts = this.scope.emergencyContacts;
    this.timeOffBalances = this.scope.timeOffSummary;
    this.vacationLiability = this.scope.vacationLiability;
    this.manualAdjustments = this.scope.manualAdjustments;
    this.timeOffRequests = this.scope.timeOffRequests;
    this.payrollReconciliation = this.scope.payrollReconciliation;
    this.projectHours = this.scope.projectHours;
    this.payrollUpdates = this.scope.payrollUpdates;
    this.benefitUpdates = this.scope.benefitUpdates;
    this.dependentEligibility = this.scope.dependentEligibility;
    this.candidates = this.scope.candidates;
    this.candidatesBySource = this.scope.candidatesBySourceChart &&
      this.scope.candidatesHireStatusChart;
    this.customReports = this.scope.custom;
    this.employeeGoals = this.scope.employeeGoals;
    this.checkins = this.scope.checkins;
    this.performanceReviews = this.scope.performanceReviews;
    this.surveys = this.scope.surveys;
    this.anonymousSurveys = this.scope.anonymousSurveys;
    this.headcount = this.scope.headcount;
    this.turnover = this.scope.turnover;
    this.trainingSummary = this.scope.trainingSummary;
    this.trainingResponses = this.scope.trainingResponses;
    this.hiringVelocity = this.scope.hiringVelocity;
    this.documents = this.scope.documents;
  }

  @computed get showCompanyPanel() {
    return this.departmentProfile || this.ageProfile || this.tenureProfile || this.genderProfile || this.legalGenderProfile;
  }

  @computed get showHeadcountTurnoverPanel() {
    return this.headcount || this.turnover || this.newHiresAndTerminations;
  }

  @computed get showEmployeePanel() {
    return this.activeEmployees || this.onLeaveEmployees || this.terminatedEmployee || this.newHiresAndTerminations || this.emergencyContacts;
  }

  @computed get showTimeOffPanel() {
    return this.timeOffBalances || this.vacationLiability || this.manualAdjustments || this.timeOffRequests || this.payrollReconciliation;
  }

  @computed get showTimeTrackingPanel() {
    return this.projectHours;
  }

  @computed get showGeneralPanel() {
    return this.payrollUpdates || this.benefitUpdates || this.dependentEligibility || this.documents;
  }

  @computed get showRecruitingPanel() {
    return this.candidates || this.hiringVelocity;
  }

  @computed get showCustomReportsPanel() {
    return this.customReports;
  }

  @computed get showPerformancePanel() {
    return this.employeeGoals || this.checkins || this.performanceReviews;
  }

  @computed get showSurveysPanel() {
    return this.surveys || this.anonymousSurveys;
  }

  @computed get showTrainingPanel() {
    return this.trainingSummary || this.trainingResponses;
  }
}

export default ReportIndexState;
