import React from 'react';
import {createRoot} from 'react-dom/client';
import {IntlProvider} from 'react-intl';
import getMessages from 'messages';
import _ from 'lodash';
import {successAlert, errorAlert} from './alerts';
import renderIfNeeded from './renderIfNeeded';
import openFileStack from './fileStack';
import {
  calendarDate,
  dayOfWeek,
  daysFromNow,
  rangeDate,
  timestampDate,
  dateFormatter,
  relativeCalendar,
  dateSpan,
  longTimestampDate,
  formattedTimestampDate,
  absoluteTimestampDate,
  absoluteDateFormatter,
  relativeRangeDate
} from './dates';
import Steps from './Steps';
import $ from 'jquery';
import {auth, t} from 'shared/core';
import scrollsIntoViewIfErrors from './scrollsIntoViewIfErrors';
import moment from 'moment';
import EarningsFormatter from './EarningsFormatter';
import AnonymousAvatar from 'img/anonymous-avatar.svg';
import setupAutosaveDraft from './autosaveDraft';

export {
  successAlert,
  errorAlert,
  renderIfNeeded,
  openFileStack,
  calendarDate,
  dayOfWeek,
  daysFromNow,
  rangeDate,
  timestampDate,
  dateFormatter,
  relativeCalendar,
  absoluteDateFormatter,
  dateSpan,
  longTimestampDate,
  formattedTimestampDate,
  absoluteTimestampDate,
  relativeRangeDate,
  Steps,
  scrollsIntoViewIfErrors,
  EarningsFormatter,
  setupAutosaveDraft
};

export async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const noop = async () => {
};

export const yesNo = (valueToTest) => {
  return valueToTest ? 'Yes' : 'No';
};

export const testRender = (Component, props, domElement) => {
  const root = createRoot(domElement);
  root.render(
    <IntlProvider locale='en' messages={getMessages('en')}>
      <Component {...props}/>
    </IntlProvider>
  );
};

export const fullName = (user) => {
  const firstName = user.preferredName || user.firstName || '';
  const lastName = user.preferredLastName || user.lastName || '';
  return `${firstName} ${lastName}`;
};

export function withDeleted(activeModels, current) {
  return _.uniqBy(activeModels.concat(current || []), 'id');
}

export function withCompanyName(title) {
  if (auth.company.name) {
    return `${title} · ${auth.unescapedCompanyName}`;
  }

  return title;
}

export function setSidebarCloseCallback() {
  $(function() {
    $('.NavigationOverlay').click(function(e) {
      $('#NavigationHamburgerCheckbox').prop('checked', false);
    });
  });
}

export function termsLinkHr() {
  switch (auth.locale) {
    case 'en':
      return 'https://www.collage.co/service-agreement';
    case 'fr':
      return 'https://www.collage.co/fr/service-agreement';
    default:
      throw new Error(`Invalid locale ${auth.locale}`);
  }
}

export function termsLinkBhq() {
  switch (auth.locale) {
    case 'en':
      return 'https://www.collage.co/service-agreement-hq';
    case 'fr':
      return 'https://www.collage.co/fr/service-agreement-hq';
    default:
      throw new Error(`Invalid locale ${auth.locale}`);
  }
}

export function policyLink() {
  switch (auth.locale) {
    case 'en':
      return 'https://collage.co/privacy-policy';
    case 'fr':
      return 'https://www.collage.co/fr/privacy-policy';
    default:
      throw new Error(`Invalid locale ${auth.locale}`);
  }
}

export async function redirect(location) {
  return new Promise(() => {
    window.location = location;
    // this promise never resolves
  });
}

export async function refresh(location) {
  return new Promise(() => {
    window.location.reload();
    // this promise never resolves
  });
}

export async function newTab(location) {
  return window.open(location, '_blank').focus();
}

export function dateToJson(date) {
  return moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
}

export function tabResourceId(klassName, id) {
  return `${klassName}(${id})`;
}

export function maskValue(value) {
  if (_.isNil(value)) {
    return value;
  }

  return '•'.repeat(value.toString().length);
}

export function allowError(error) {
  return function(status) {
    return (status >= 200 && status < 300) || status === error;
  };
}

export function calculatePercentage(value, total) {
  if (total === 0) return 0;

  return _.clamp((value / total) * 100, 0, 100);
}

export function generateAnonymousEmployee() {
  return {
    firstName: t('models.employee.anonymous_employee.Anonymous'),
    avatarInitials: t('models.employee.anonymous_employee.N/A'),
    avatarColour: 'bg-waterloo',
    links: {
      profilePicture: AnonymousAvatar
    },
  };
}

export function groupEmployeeCustomFields(employeeCustomFields, customFieldGroups) {
  return _.chain(employeeCustomFields)
    .groupBy('customFieldGroupId')
    .map((fields, groupId) => {
      const group = _.find(customFieldGroups, {id: groupId});
      return {
        id: group.id,
        name: group.name,
        order: group.order,
        employeeCustomFields: _.sortBy(fields, 'order')
      };
    })
    .sortBy('order')
    .value();
}

export function transliterate(string) {
  if (!string) return '';

  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
