import React from 'react';
import {FormattedMessage} from 'react-intl';
import {FormLayout, Panel, Input, ObjectSelect2, Select2, QuickTip, ContactUsLink, LabelHelper} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';
import PAY_RATE_FORMATS from 'stores/recruiting/payRateFormats';
import LOCATION_TYPES from 'stores/recruiting/locationTypes';
import cc from 'currency-codes';
import _ from 'lodash';

const IndeedLocationWarning = observer(({locationType, missingFields}) => {
  if (_.isEmpty(missingFields) && locationType !== 'fully_remote') return null;

  const paragraphs = [];

  if (locationType === 'fully_remote') {
    paragraphs.push(
      <FormattedMessage id='recruiting.edit.steps.FULLY_REMOTE_INDEED_WARNING'/>
    );
  } else {
    paragraphs.push(
      <FormattedMessage
        id='recruiting.edit.steps.INDEED_LOCATION_WARNING'
        values={{contactUs: <ContactUsLink/>}}
      />,
      <FormattedMessage id="recruiting.edit.steps.INDEED_MISSING_FIELDS"/>,
      <div className='ml2'>
        <ul>
          {missingFields.map(field => <li>{field}</li>)}
        </ul>
      </div>
    );
  }

  return (
    <div className='col col-12 mb2'>
      <QuickTip
        trait='caution'
        header={t('recruiting.edit.steps.This posting might not appear properly on Indeed')}
        paragraphs={paragraphs}
      />
    </div>
  );
});

const Details = observer(({uiState}) => {
  const {position, errors, jobSite, missingFields} = uiState;

  return (
    <div>
      <Panel.Header title={t('recruiting.edit.steps.Job Details')}/>
      <FormLayout>
        <Input
          label='recruiting.edit.steps.Job Title'
          placeholder='recruiting.edit.steps.Add a posting title'
          errorMessage={errors.title}
          value={position.title || ''}
          onChange={e => position.title = e.target.value}
          className='large'
        />
        <ObjectSelect2
          label='recruiting.edit.steps.Location'
          className='large' value={position.location}
          onChange={value => position.location = value}
          items={uiState.locationsWithDeleted}
          placeholder='recruiting.edit.steps.Specify a location' errorMessage={errors.locationId}
        />
        <Select2
          label='recruiting.edit.steps.Location Type'
          className='large'
          value={position.locationType}
          onChange={e => position.merge({locationType: e.target.value})}
          errorMessage={errors.locationType}
        >
          {LOCATION_TYPES.map(type => <option key={type} value={type}>{t(`recruiting.edit.steps.location_types.${type}`)}</option>)}
        </Select2>
        {jobSite.indeedEnabled && <IndeedLocationWarning missingFields={missingFields} locationType={position.locationType}/>}
        <ObjectSelect2
          label='recruiting.edit.steps.Department'
          className='large'
          value={position.department}
          onChange={value => position.department = value}
          items={uiState.departmentsWithDeleted}
          placeholder='recruiting.edit.steps.Specify a department' errorMessage={errors.departmentId}
        />
        <Select2
          label='recruiting.edit.steps.Type'
          className='large'
          value={position.employmentType || ''}
          onChange={e => position.employmentType = e.target.value}
          placeholder='recruiting.edit.steps.Specify a type' errorMessage={errors.employmentType}
        >
          {EMPLOYMENT_TYPES.map(type => <option key={type} value={type}>{t(type)}</option>)}
        </Select2>
        <div>
          <LabelHelper label='Salary' optional={true}/>
          <div className='flex space-x'>
            <div>
              <Select2
                className='large'
                value={position.payRateFormat}
                onChange={e => uiState.updatePayRateFormat(e.target.value)}
                errorMessage={errors.payRateFormat}
              >
                {PAY_RATE_FORMATS.map(format => <option key={format} value={format}>{t(`recruiting.edit.steps.pay_rate_formats.${format}`)}</option>)}
              </Select2>
            </div>
            <div className='flex flex-grow space-x'>
              {position.payRateFormat !== 'maximum_only' && 
                <div className='flex-grow'>
                  <Input
                    errorMessage={errors.minimumPayRate}
                    value={position.minimumPayRate}
                    onChange={e => position.merge({minimumPayRate: e.target.value})}
                    className='large'
                  />
              </div>}
              {position.payRateFormat === 'range' && <div className='flex align-items-middle justify-content-center'>
                {'-'}
              </div>}
              {(position.payRateFormat === 'range' || position.payRateFormat === 'maximum_only') && <div className='flex-grow'>
                <Input
                  errorMessage={errors.maximumPayRate}
                  value={position.maximumPayRate}
                  onChange={e => position.merge({maximumPayRate: e.target.value})}
                  className='large'
                />
              </div>}
            </div>
            <div>
              <Select2
                className='large'
                value={position.payRateCurrency}
                onChange={e => position.merge({payRateCurrency: e.target.value})}
                errorMessage={errors.payRateCurrency}
                searchable
              >
                {cc.codes().map(c => <option key={c} value={c}>{c}</option>)}
              </Select2>
            </div>
            <div>
              <Select2
                className='large'
                value={position.payRateUnit}
                onChange={e => position.merge({payRateUnit: e.target.value})}
                errorMessage={errors.payRateUnit}
                searchable
              >
                {PAY_RATE_UNIT_TYPES.map(p => <option value={p.id} key={p.id}>{t(`recruiting.edit.steps.pay_rate_units.${p.id}`)}</option>)}
              </Select2>
            </div>
          </div>
        </div>
      </FormLayout>
    </div>
  );
});

export default Details;
