import React from 'react';
import {observer} from 'mobx-react';
import PayRateTable from './PayRateTable';
import EmployeeTable from './EmployeeTable';
import TaxPropertiesTable from './TaxPropertiesTable';
import SyncedHoursTable from './SyncedHoursTable';

const DefaultUpdate = observer(({nmbrUpdate}) => {
  return (
    <div className='space-y-2'>
      <EmployeeTable nmbrUpdate={nmbrUpdate}/>
      <PayRateTable nmbrUpdate={nmbrUpdate}/>
      <TaxPropertiesTable nmbrUpdate={nmbrUpdate}/>
      <SyncedHoursTable nmbrUpdate={nmbrUpdate}/>
    </div>
  );
});

export default DefaultUpdate;
