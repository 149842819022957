import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {endpoints, api} from 'shared/core';

class SetupState {
  store = new DomainStore();

  @action async setup() {
    const {status} = await api.post(
      endpoints.NMBR.SETUP
    );

    if (status === 201) {
      window.location = '/payroll';
    }
  }
}

export default SetupState;
