import BaseReportDataSource from './BaseReportDataSource';
import {endpoints, types} from 'shared/core';
import AsyncDataSource from './AsyncDataSource';
import ManagerDataSource from './ManagerDataSource';
import TerminatedEmployeeDataSource  from './TerminatedEmployeeDataSource';

class CompanyReportDataSource extends BaseReportDataSource {
  departmentDataSource() {
    if (this.customReportId) {
      return new AsyncDataSource(
        endpoints.CUSTOM_REPORT.filterableDepartments(this.customReportId),
        types.DEPARTMENT
      );
    } else {
      return new AsyncDataSource(endpoints.DEPARTMENTS, types.DEPARTMENT);
    }
  }

  employeeDataSource() {
    if (this.customReportId) {
      return new AsyncDataSource(
        endpoints.CUSTOM_REPORT.filterableEmployees(this.customReportId),
        types.EMPLOYEE
      );
    } else {
      return new AsyncDataSource(endpoints.EMPLOYEES.ALL, types.EMPLOYEE);
    }
  }

  locationDataSource() {
    if (this.customReportId) {
      return new AsyncDataSource(
        endpoints.CUSTOM_REPORT.filterableLocations(this.customReportId),
        types.LOCATION
      );
    } else {
      return new AsyncDataSource(endpoints.LOCATIONS.ALL, types.LOCATION);
    }
  }

  managerDataSource() {
    if (this.customReportId) {
      return new AsyncDataSource(
        endpoints.CUSTOM_REPORT.filterableManagers(this.customReportId),
        types.EMPLOYEE
      );
    } else {
      return new ManagerDataSource();
    }
  }

  userDataSource() {
    return new AsyncDataSource(endpoints.USERS.ALL, types.USER);
  }

  projectDataSource() {
    return new AsyncDataSource(endpoints.TIME_TRACKING.PROJECTS.ALL, types.TIME_TRACKING.PROJECT);
  }

  positionDataSource() {
    return new AsyncDataSource(endpoints.RECRUITING.POSITIONS_FOR_REPORT, types.RECRUITING.POSITION);
  }

  documentDataSource() {
    return new AsyncDataSource(endpoints.COMPANY_DOCUMENTS.all, types.COMPANY_DOCUMENT);
  }

  checkinDataSource() {
    return new AsyncDataSource(endpoints.PERFORMANCE.CYCLES, types.PERFORMANCE.CYCLE);
  }

  terminatedEmployeeDataSource() {
    return new TerminatedEmployeeDataSource();
  }

  timeOffTypeDataSource() {
    return new AsyncDataSource(endpoints.TIME_OFF.TYPES.ALL, types.TIME_OFF.TYPE);
  }

  reviewDataSource() {
    return new AsyncDataSource(endpoints.PERFORMANCE_REVIEWS.CYCLES.LAUNCHED, types.PERFORMANCE_REVIEWS.CYCLE);
  }

  surveyDataSource() {
    return new AsyncDataSource(endpoints.SURVEYS.SURVEYS_LAUNCHED, types.SURVEYS.SURVEY);
  }

  anonymousSurveyDataSource() {
    return new AsyncDataSource(endpoints.SURVEYS.SURVEYS_ANONYMOUS, types.SURVEYS.SURVEY);
  }

  programDataSource() {
    return new AsyncDataSource(endpoints.TRAINING.PROGRAMS, types.TRAINING.PROGRAM);
  }
}

export default CompanyReportDataSource;
