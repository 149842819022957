import 'babel-polyfill';
import {auth} from 'shared/core';
import {
  HoursPerWorkday,
  PolicyConfirmationModal,
} from 'time_off';
import {BenefitsPage} from 'benefits';
import ChargifyPaymentFormContent from 'company_settings/billing/components/ChargifyPaymentFormContent';
import CompanyOnboardPage from 'company_onboard/components/CompanyOnboardPage';
import CompanySettingsPage from 'company_settings/CompanySettingsPage';
import {
  BrokerPortalApplication,
  BrokerPortalSignupPage,
  BrokerPortalStopImpersonation,
  EmployeeSignupForm,
  AdminUserSignupForm
} from 'broker_portal';

import {SwitchAccountPage} from 'users/switch_account';
import {EmployeeProfilePage} from 'employees/profile';
import {EmployeeDirectoryPage} from 'employees/directory';
import GlobalSearchContainer from 'global_search';
import {GoalPage} from 'performance_goals';
import InboxPage from 'inbox';
import JobApplicationContainer from 'recruiting/components/job_application/JobApplicationContainer';
import {JobOfferContainer} from 'job_offer';
import {BrokerLoginContainer, LoginContainer, MFASetupContainer} from 'login/components';
import OffersPage from 'offers';
import OnboardBankingContainer from 'onboard/banking/components/OnboardBankingContainer';
import OnboardCoverageContainer from 'onboard/coverage';
import OnboardEmergencyContainer    from 'onboard/emergency/components/OnboardEmergencyContainer';
import OnboardEmployeeDocumentsPage from 'onboard/documents/components/OnboardEmployeeDocumentsPage';
import OnboardEmployeeInfoContainer from 'onboard/employee_info/components/OnboardEmployeeInfoContainer';
import OnboardFamilyStatusContainer from 'onboard/family_status';
import OnboardingDocumentsContainer from 'employees/hiring/components/OnboardingDocumentsContainer';
import OnboardingTrainingContainer from 'employees/hiring/components/OnboardingTrainingContainer';
import {OnboardBenefitInfoReviewPage} from 'onboard';
import PayrollPage from 'payroll';
import PendingDocumentsPage from 'pending_documents/components/PendingDocumentsPage';
import HrUpgradePage from 'hr_upgrade';
import PerformanceManagementPage from 'performance';
import RecruitingPage from 'recruiting';
import ReferralsPage from 'referrals';
import ReportsPage from 'reports';
import SecureUploadPage from 'secure_upload';
import {SurveysPage} from 'surveys';
import {TimeTrackingPage} from 'time_tracking';
import StyleGuideContainer from 'styleguide/components/StyleGuideContainer';
import {TimeOffPage} from 'time_off';
import TrainingPage from 'training';
import {SignUpPage} from 'signup';
import {PerformanceReviewsPage} from 'performance_reviews';
import {ExternalCandidatesContainer} from 'integrations/external_candidates';
import {MarketplaceContainer} from 'integrations/marketplace';
import {PaDashboardPage} from 'pa_dashboard';
import PayrollUpdatesPage from 'payroll_updates';
import {TrialEndedPage} from 'billing_subscriptions';
import {HRDashboardPage} from 'hr_dashboard';

import {
  DelayRender,
  LocaleSelect,
  NotAuthorized,
  StopImpersonation
} from 'components';
import {setupAmplitude} from 'shared/amplitude';
import setupSentry from 'shared/sentry-setup';
import i18n from 'shared/i18n';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
import {setSessionExpirationTimeout} from 'login/tools';
import {renderIfNeeded, setSidebarCloseCallback, relativeCalendar} from 'shared/tools';
import Modal from 'react-modal';
import DemoBanner from 'components/demo_banner/components/DemoBanner';
import FeatureRequestModal from 'components/feature_request/FeatureRequestModal';
import {DemoDataDeletion} from './containers/demo_data_deletion';
import MassUpdatesPage from './containers/mass_updates';
import UserSettingsContainer from 'containers/users/settings/components/UserSettingsContainer';
import Setup from 'containers/nmbr/components/Setup';
import CalendarPage from 'calendar';
/*global $*/

setupSentry();
setupAmplitude();
i18n.init(auth.locale);
moment.locale(auth.locale);
moment.calendarFormat = relativeCalendar;
numeral.locale(auth.locale);
Modal.setAppElement('body');

const bindJsLinks = () => {
  $(document).on('click', '.js-link', function (e) {
    e.preventDefault();
    window.location = $(this).data('link');
  });
};

renderIfNeeded(AdminUserSignupForm, 'cr-admin-invitation-signup');
renderIfNeeded(BenefitsPage, 'cr-benefits');
renderIfNeeded(BrokerLoginContainer, 'cr-branded-login');
renderIfNeeded(BrokerPortalApplication, 'cr-broker-dashboard-page');
renderIfNeeded(BrokerPortalSignupPage, 'cr-broker-portal-signup');
renderIfNeeded(ChargifyPaymentFormContent, 'cr-chargify-payment-form');
renderIfNeeded(CompanyOnboardPage, 'cr-company-setup');
renderIfNeeded(CompanySettingsPage, 'cr-company-settings');
renderIfNeeded(DelayRender, 'cr-delay-render');
renderIfNeeded(DemoBanner, 'cr-demo-banner');
renderIfNeeded(EmployeeDirectoryPage, 'cr-employee-directory');
renderIfNeeded(EmployeeProfilePage, 'cr-employee-profile');
renderIfNeeded(EmployeeSignupForm, 'cr-bhq-employee-signup');
renderIfNeeded(MarketplaceContainer, 'cr-marketplace-index');
renderIfNeeded(ExternalCandidatesContainer, 'cr-greenhouse-index', {vendor: 'greenhouse'});
renderIfNeeded(ExternalCandidatesContainer, 'cr-jazz-hr-index', {vendor: 'jazz_hr'});
renderIfNeeded(GlobalSearchContainer, 'cr-global-search');
renderIfNeeded(GoalPage, 'cr-goals-page');
renderIfNeeded(UserSettingsContainer, 'cr-user-settings');
renderIfNeeded(HoursPerWorkday, 'cr-pto-policy-hours-per-workday');
renderIfNeeded(HrUpgradePage, 'cr-hr-upgrade-page');
renderIfNeeded(InboxPage, 'cr-inbox');
renderIfNeeded(JobApplicationContainer, 'cr-job-application');
renderIfNeeded(JobOfferContainer, 'cr-job-offer');
renderIfNeeded(LocaleSelect, 'cr-locale-select');
renderIfNeeded(MassUpdatesPage, 'cr-mass-salary-updates');
renderIfNeeded(MFASetupContainer, 'cr-mfa-setup');
renderIfNeeded(LoginContainer, 'cr-login');
renderIfNeeded(OffersPage, 'cr-offers-page');
renderIfNeeded(OnboardBankingContainer, 'cr-onboard-banking');
renderIfNeeded(OnboardBenefitInfoReviewPage, 'cr-onboarding-benefit-info-review');
renderIfNeeded(OnboardCoverageContainer, 'onboard-coverage', { onboardStep: 'coverage' });
renderIfNeeded(OnboardEmergencyContainer, 'cr-onboard-emergency');
renderIfNeeded(OnboardEmployeeDocumentsPage, 'cr-onboard-employee-documents');
renderIfNeeded(OnboardEmployeeInfoContainer, 'cr-onboard-employee-info');
renderIfNeeded(OnboardFamilyStatusContainer, 'onboard-family-status', { onboardStep: 'family_status' });
renderIfNeeded(OnboardingDocumentsContainer, 'cr-onboarding-documents');
renderIfNeeded(OnboardingTrainingContainer, 'cr-onboarding-training');
renderIfNeeded(PaDashboardPage, 'cr-pa-dashboard-page');
renderIfNeeded(PaDashboardPage, 'cr-submit-request-page');
renderIfNeeded(PayrollPage, 'cr-payroll-page');
renderIfNeeded(PayrollUpdatesPage, 'cr-payroll-updates-page');
renderIfNeeded(PendingDocumentsPage, 'cr-pending-documents');
renderIfNeeded(PerformanceManagementPage, 'cr-performance-page');
renderIfNeeded(PerformanceReviewsPage, 'cr-reviews-page');
renderIfNeeded(PolicyConfirmationModal, 'cr-pto-policy-confirmation-modal');
renderIfNeeded(RecruitingPage, 'cr-recruiting-page');
renderIfNeeded(ReferralsPage, 'cr-referrals-page');
renderIfNeeded(ReportsPage, 'cr-reports-page');
renderIfNeeded(SecureUploadPage, 'cr-secure-upload');
renderIfNeeded(SignUpPage, 'cr-company-sign-up');
renderIfNeeded(BrokerPortalStopImpersonation, 'cr-broker-portal-stop-impersonation');
renderIfNeeded(StopImpersonation, 'cr-stop-impersonation');
renderIfNeeded(StyleGuideContainer, 'cr-styleguide');
renderIfNeeded(SurveysPage, 'cr-surveys-page');
renderIfNeeded(TimeTrackingPage, 'cr-time-tracking');
renderIfNeeded(SwitchAccountPage, 'cr-switch-account');
renderIfNeeded(TimeOffPage, 'cr-pto');
renderIfNeeded(TrainingPage, 'cr-training-page');
renderIfNeeded(FeatureRequestModal, 'cr-feature-request-modal');
renderIfNeeded(TrialEndedPage, 'cr-trial-ended-page');
renderIfNeeded(NotAuthorized, 'cr-not-authorized');
renderIfNeeded(HRDashboardPage, 'cr-hr-dashboard-page');
renderIfNeeded(DemoDataDeletion, 'cr-demo-data-deletion');
renderIfNeeded(Setup, 'cr-nmbr-setup');
renderIfNeeded(CalendarPage, 'cr-calendar');

setSessionExpirationTimeout();
setSidebarCloseCallback();

bindJsLinks();
