import {action, observable, computed}  from 'mobx';
import {DomainObject} from 'shared/store';
import {t} from 'shared/core';
import CustomFieldOption from './CustomFieldOption';
import {EmployeeGroup} from 'stores/employee_groups';
import _ from 'lodash';

class CustomField extends DomainObject {
  @observable id;
  @observable name;
  @observable description;
  @observable customFieldGroupId;
  @observable fieldType = 'text_field';
  @observable employeeCanEdit = false;
  @observable displayOnHire = false;
  @observable __employeeCanView = false;
  @observable createdAt;
  @observable availableInReports = false;
  @observable displayInPayroll = false;
  @observable required = false;
  @observable order;
  @observable options = [];

  @action merge(other) {
    super.merge(other, {
      options: [CustomFieldOption],
      employeeGroup: EmployeeGroup
    });
  }

  set employeeCanView(value) {
    this.__employeeCanView = value;

    if (!this.employeeCanView) {
      this.employeeCanEdit = false;
    }
  }

  @computed get employeeCanView() {
    return this.__employeeCanView;
  }

  @computed get sortedOptions() {
    return _.sortBy(this.options, 'id');
  }

  @computed get formattedFieldType() {
    return t(`models.custom_field.field_type.${this.fieldType}`);
  }

  toJS() {
    return {
      ...super.toJS(),
      employeeCanView: this.employeeCanView
    };
  }
}

export default CustomField;
