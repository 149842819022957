const LOCATION_TYPES = [
  'on_site',
  'hybrid',
  'remote_citywide',
  'remote_regionwide',
  'remote_nationwide',
  'fully_remote'
];

export default LOCATION_TYPES;
