import {action, observable} from 'mobx';
import {endpoints, api, t} from 'shared/core';
import DayOffViewModel from 'stores/time_off/DayOffViewModel';
import moment from 'moment';

class CustomHoursState {
  @observable event = {};
  @observable daysOff = [];
  
  receiveProps({event}) {
    this.event = event;
  }

  @action async load() {
    this.daysOff = [];

    const response = await api.get(
      endpoints.TIME_OFF.DAYS_OFF.FOR_REQUEST.with({
        employeeId: this.event.extendedProps.employeeId,
        id: this.event.extendedProps.requestId,
      })
    );

    this.daysOff = response.data.map(day => {
      if (day.start_time) {
        day.start_time = moment(day.start_time).format(t('components.dates.TIMESTAMP_FORMAT'));
        day.end_time = moment(day.end_time).format(t('components.dates.TIMESTAMP_FORMAT'));
      }

      return new DayOffViewModel(day);
    });
  }
}

export default CustomHoursState;
