import React from 'react';
import Report from '../Report';
import {QuickTip} from 'components';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Cycle',
  'Leader',
  'Department',
  'Location',
  'Manager',
  'Gender',
  'Employee',
  'Employment Status',
  'Employment Type'
];

const CheckinsReport = () => {
  return (
    <React.Fragment>
      <div className='mb2'>
        <QuickTip
          trait='info'
          header={t('reports.checkins.Viewing Employee Answers')}
          paragraphs={[
            t('reports.checkins.QUICKTIP_BODY')
          ]}
        />
      </div>
      <CompositeReport filters={filters}>
        <Report name={t('reports.CHECKINS')}
                url='/reports/checkins'>
        </Report>
      </CompositeReport>
    </React.Fragment>
  );
};

export default CheckinsReport;
