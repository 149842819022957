import {observable, action, computed} from 'mobx';
import {t, auth, endpoints, types} from 'shared/core';
import {LeaveType} from 'stores/leave_types';
import _ from 'lodash';
import {EmploymentRecord, OnLeaveRecord, TerminationRecord, ReactivationRecord} from 'stores/employees';
import {successAlert, withDeleted, calendarDate} from 'shared/tools';
import moment from 'moment';
import {DomainStore} from 'shared/store';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';
import {EmployeeCustomField} from 'stores/employee_custom_fields';
import {Broker} from 'stores/brokers';

const JOB_DETAILS_RECORD_TYPES = [
  types.EMPLOYMENT_RECORD, types.ON_LEAVE_RECORD, types.REACTIVATION_RECORD
];

class EditEmploymentRoleContainerState {
  store = new DomainStore();
  employeeStore = new DomainStore();
  parentState;
  employee;
  action;
  history;
  match;
  originalManager;

  @observable entries = [];
  @observable editRecord = null;
  @observable deleteRecord = null;
  @observable errors = {};
  @observable editModalOpen = false;
  @observable deleteModalOpen = false;
  @observable locations = [];
  @observable departments = [];
  @observable employees = [];
  @observable broker = null;
  @observable skipHoursPerWeek = false;

  @observable terminationConfirmationModalOpen = false;

  @observable lastDayOfBenefitsChanged;
  @observable addEmployeeToPayrollModal = false;

  receiveProps({parentState, action, history, match}) {
    this.action = action;
    this.parentState = parentState;
    this.employee = this.parentState.employee;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await Promise.all([
      this.store._compose([
        endpoints.EMPLOYEE.EMPLOYMENT_HISTORY.with(this.employee.id),
        endpoints.LOCATIONS.ALL,
        endpoints.DEPARTMENTS,
        endpoints.LEAVE_TYPES
      ]),
      this.employeeStore._compose([endpoints.EMPLOYEES.ALL])
    ]);

    const employmentRecords = this.store._getAll(types.EMPLOYMENT_RECORD).map(r => new EmploymentRecord(r));
    const terminationRecords = this.store._getAll(types.TERMINATION_RECORD).map(r => new TerminationRecord(r));
    const onLeaveRecords = this.store._getAll(types.ON_LEAVE_RECORD).map(r => new OnLeaveRecord(r));
    const reactivationRecords = this.store._getAll(types.REACTIVATION_RECORD).map(r => new ReactivationRecord(r));
    this.entries = [...employmentRecords, ...terminationRecords, ...onLeaveRecords, ...reactivationRecords];

    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
    this.employees = this.employeeStore.getEmployees();
    this.leaveTypes = this.store._getAll(types.LEAVE_TYPE, LeaveType);

    if (auth.company.broker) {
      await this.store._compose([endpoints.CURRENT_BROKER]);
      this.broker = new Broker(this.store._getSingle(types.BROKER));
    }

    switch (this.action) {
      case 'new':
      case 'returnFromLeave':
        this.createNewEmploymentRecord();
        break;
      case 'reactivate':
        this.createNewReactivationRecord();
        break;
      case 'placeOnLeave':
        this.createNewOnLeaveRecord();
        break;
      case 'edit':
        this.editExistingRecord();
        break;
      case 'terminate':
        this.createNewTerminationRecord();
        break;
      default:
        throw new Error(`Action ${this.action} not supported.`);
    }

    this.lastDayOfBenefitsChanged = _.get(this.editRecord, 'terminatedAt') !== _.get(this.editRecord, 'lastDayOfBenefits');
    this.originalManager = this.editRecord.manager;
  }

  @action updateLocation(value) {
    this.editRecord.merge({location: value});
    this.getEmployeeCustomFields();
  }

  @action updateDepartment(value) {
    this.editRecord.merge({department: value});
    this.getEmployeeCustomFields();
  }

  @action updateEmploymentType(value) {
    this.editRecord.merge({employmentType: value});
    this.getEmployeeCustomFields();
  }

  @action async getEmployeeCustomFields() {
    this.store.invalidate(types.EMPLOYEE_CUSTOM_FIELD);
    await this.store._compose([
      endpoints.EMPLOYEE_CUSTOM_FIELDS.EMPLOYMENT_RECORD.with({
        employeeId: this.employee.id,
        locationId: this.editRecord.location.id,
        departmentId: _.get(this.editRecord.department, 'id'),
        employmentType: this.editRecord.employmentType
      })
    ]);

    const employmentRecordCustomFields = this.store._getAll(
      types.EMPLOYEE_CUSTOM_FIELD, EmployeeCustomField
    );

    let currentCustomFields = _.keyBy(this.editRecord.employmentRecordCustomFields, 'customFieldId');

    employmentRecordCustomFields.forEach(c => {
      if (currentCustomFields[c.customFieldId] !== undefined) {
        c.value = currentCustomFields[c.customFieldId].value;
      }
    });
    this.editRecord.employmentRecordCustomFields = employmentRecordCustomFields;
  }

  @action editExistingRecord() {
    this.editRecord = _.find(this.entries, {id: this.match.params.recordId});
  }

  @action createNewEmploymentRecord() {
    this.createNewRecordWithTemplate(this.latestEmploymentRecord, EmploymentRecord);
  }

  @action createNewOnLeaveRecord() {
    this.createNewRecordWithTemplate(this.latestEmploymentRecord, OnLeaveRecord);
  }

  @action createNewReactivationRecord() {
    this.createNewRecordWithTemplate(this.latestEmploymentRecord, ReactivationRecord);
  }

  @action createNewTerminationRecord() {
    this.createNewRecordWithTemplate(this.latestEmploymentRecord, TerminationRecord);
    this.editRecord.terminatedAt = null;
    this.editRecord.personalEmail = this.employee.personalEmail;
    if (this.employee.benefits && !this.editRecord.lastDayOfBenefits) {
      this.editRecord.lastDayOfBenefits = this.editRecord.terminatedAt;
    }
  }

  @action createNewRecordWithTemplate(template, klass = EmploymentRecord) {
    this.editRecord = new klass(template);
    this.editRecord.id = '';
    this.editRecord.comment = '';
    this.editRecord.effectiveDate = null;
    this.editRecord.initial = false;
  }

  @action wipeoutBonusAndCommissionDetailsIfNecessary() {
    if (!this.editRecord.bonus) {
      this.editRecord.bonusStructure = null;
    }
    if (!this.editRecord.commission) {
      this.editRecord.commissionStructure = null;
    }
  }

  @action updateSelectedTasks(taskTemplates) {
    this.editRecord.taskTemplates = taskTemplates;
  }

  @action openTerminationConfirmationModal() {
    this.terminationConfirmationModalOpen = true;
  }

  @action closeTerminationConfirmationModal() {
    this.terminationConfirmationModalOpen = false;
  }

  @action async saveEmploymentRecord() {
    if (this.editRecord._type === types.TERMINATION_RECORD) {
      this.openTerminationConfirmationModal();
    } else {
      this.wipeoutBonusAndCommissionDetailsIfNecessary();
      await this.saveRecord();
    }
  }

  @action async saveRecord() {
    if (!this.validateHoursPerWeek()) return;

    const {model, errors} = this.editRecord.isNew
      ? await this.store.post(endpoints.EMPLOYEE.EMPLOYMENT_RECORDS.with(this.employee.id), this.editRecord._type, this.editRecord)
      : await this.store.patch(this.editRecord);

    this.errors = errors;
    this.closeTerminationConfirmationModal();

    if (model) {
      this.editRecord.update(model);
      const updatedMessage = t('employees.profile.role.EMPLOYMENT_HISTORY_UPDATED', {employee: this.employee.name});
      const changesWillTakeEffectMessage = this.editRecord.effectiveDate < moment().toDate()
        ? t('employees.profile.role.Changes are effective immediately.')
        : t('employees.profile.role.CHANGES_WILL_TAKE_EFFECT_ON', {date: calendarDate(model.effectiveDate)});
      successAlert(`${updatedMessage} ${changesWillTakeEffectMessage}`);
      this.parentState.load();
      this.goBack();
    }
  }

  @action goBack() {
    this.history.push(`/${this.employee.id}/job`);
  }

  @action setTerminatedAtAndLastDayOfBenefits(date) {
    if (this.lastDayOfBenefitsChanged) {
      this.editRecord.merge({terminatedAt: date});
    } else {
      this.editRecord.merge({terminatedAt: date, lastDayOfBenefits: date});
    }
  }

  @action setLastDayOfBenefits(date) {
    this.editRecord.merge({lastDayOfBenefits: date});
    this.lastDayOfBenefitsChanged = true;
  }

  @action setBenefitEnrolments(enrolments) {
    this.editRecord.employeeBenefitEnrollments = enrolments;
  }

  @action setEmployeeInsuranceNumbers(employeeInsuranceNumbers) {
    this.editRecord.employeeInsuranceNumbers = employeeInsuranceNumbers;
  }

  redirectToNmbrDirectory() {
    window.location = '/payroll/employees';
  }

  @action openAddEmployeeToPayrollModal() {
    this.addEmployeeToPayrollModal = true;
  }

  @action closeAddEmployeeToPayrollModal() {
    this.addEmployeeToPayrollModal = false;
  }

  @action async enableEmployeePayroll() {
    const employee = {..._.omit(this.employee, 'employeeCustomFields'), updateCompensation: true, payrollEnabled: true};
    const {model, errors} = await this.store.patch(
      `${endpoints.EMPLOYEES.ALL}/${this.employee.id}`,
      types.EMPLOYEE_DETAILS,
      employee,
    );

    this.errors = errors;
    if (model) {
      this.employee.update(model);

      successAlert(t('payroll.employees.ADDED_TO_PAYROLL', {employeeName: this.employee.name}));
      this.addEmployeeToPayrollModal = false;
    }
  }

  @action updateTerminationType(value) {
    this.editRecord.merge({terminationType: value});
    this.updateROECode(null);
  }

  @action updateROECode(value) {
    this.editRecord.roeCode = value;
    this.editRecord.reasonForQuitting = null;
    this.editRecord.terminatedOnProbation = null;
  }

  @action toggleSkipHoursPerWeek(checked) {
    this.skipHoursPerWeek = checked;
    this.editRecord.hoursPerWeek = null;
  }

  @computed get currentRecord() {
    return _.find(this.entries, r => r.current);
  }

  @computed get sortedEntries() {
    return _.orderBy(this.entries, 'effectiveDate', 'desc');
  }

  @computed get availableManagers() {
    const employees = _.reject(this.employees, {
      id: this.employee.id
    });

    return withDeleted(employees, this.originalManager);
  }

  @computed get locationsWithDeleted() {
    return withDeleted(this.locations, _.get(this.editRecord, 'location'));
  }

  @computed get departmentsWithDeleted() {
    return withDeleted(this.departments, _.get(this.editRecord, 'department'));
  }

  @computed get latestEmploymentRecord() {
    return _.find(this.sortedEntries, r => _.includes(JOB_DETAILS_RECORD_TYPES, r._type));
  }

  @computed get payRateUnitTypes() {
    if (this.editRecord.payRateUnit !== 'day') return PAY_RATE_UNIT_TYPES;

    const perDay = {
      id: 'day',
      name: `models.employment_record.pay_rate_unit.day`
    };

    return _.concat(PAY_RATE_UNIT_TYPES, perDay);
  }

  @computed get displayESA() {
    const lastDayOfBenefits = moment(this.editRecord.lastDayOfBenefits);
    const terminatedAt = moment(this.editRecord.terminatedAt);

    return !lastDayOfBenefits.isSame(terminatedAt, 'day');
  }

  @computed get terminationDescription() {
    const descriptions = [];
    if (auth.moduleEnabled('directory')) {
      descriptions.push(t('employees.profile.role.TERMINATE_EMPLOYEE_DESC'));
    } else {
      descriptions.push(t('employees.profile.role.TERMINATE_EMPLOYEE_DESC_BHQ'));
    }

    return descriptions;
  }

  @computed get employeeHasManager() {
    return !!this.editRecord.manager;
  }

  @computed get payrollEnabled() {
    return auth.company.payrollEnabled && this.employee.payrollEnabled;
  }

  @computed get voluntaryROECodes() {
    return ['d', 'e', 'f', 'n', 'p'];
  }

  @computed get involuntaryROECodes() {
    return ['a', 'm'];
  }

  @computed get validReasonsForQuitting() {
    return [
      'not_sure',
      'follow_spouse',
      'return_to_school',
      'health_reasons',
      'voluntary_retirement',
      'take_another_job',
      'employer_relocation',
      'care_for_dependent',
      'become_self_employed'
    ];
  }

  validateHoursPerWeek() {
    if (this.editRecord._type !== types.TERMINATION_RECORD &&
        (this.editRecord.hoursPerWeek === null && !this.skipHoursPerWeek)) {
      this.errors = { hoursPerWeek: t('employees.profile.role.Hours per week is required') };
      return false;
    }

    return true;
  }
}

export default EditEmploymentRoleContainerState;
