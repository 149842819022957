import {action, computed, observable} from 'mobx';
import {endpoints, types, api, auth, t} from 'shared/core';
import {DomainStore} from 'shared/store';
import {redirect} from 'shared/tools';
import CustomFieldGroup from 'stores/custom_fields/CustomFieldGroup';
import {CustomField, CustomFieldOption} from 'stores/custom_fields';
import _ from 'lodash';
import {CompanyDocument} from 'stores/documents';
import {EmployeeGroup} from 'stores/employee_groups';

class CustomFieldGroupListState {
  store = new DomainStore();

  @observable category;
  @observable groups = [];
  @observable errors = {};
  @observable selectedGroup;
  @observable employeeGroups;
  @observable reorderCustomFieldsModalOpen = false;
  @observable reorderingFields;
  @observable editFieldModalOpen = false;
  @observable newCustomField;
  @observable reorderGroupsModalOpen = false;
  @observable reorderingGroups;
  @observable editGroupModalOpen = false;
  @observable newCustomFieldGroup;
  @observable deleteGroupModalOpen = false;
  @observable removeModalOpen = false;
  @observable documentsToDelete = [];
  @observable selectedField;
  @observable fetchingDocumentsToDelete = false;

  receiveProps({category}) {
    this.category = category;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CUSTOM_FIELD_GROUPS.with(this.category),
      endpoints.EMPLOYEE_GROUPS
    ]);

    this.groups = this.store._getAll(types.CUSTOM_FIELD_GROUP, CustomFieldGroup);
    this.employeeGroups = this.store._getAll(types.EMPLOYEE_GROUP, EmployeeGroup);
  }

  @action openReorderGroupsModal() {
    this.reorderingGroups = this.groups.map(group => new CustomFieldGroup(group));
    this.reorderGroupsModalOpen = true;
  }

  @action closeReorderGroupsModal() {
    this.reorderGroupsModalOpen = false;
  }

  @action openEditGroupModal(group = {}) {
    this.newCustomFieldGroup = new CustomFieldGroup(_.merge(group, {category: group.category || this.category}));
    this.editGroupModalOpen = true;
  }

  @action closeEditGroupModal() {
    this.editGroupModalOpen = false;
    this.errors = {};
  }

  @action async deleteGroup(group) {
    await this.store.destroy(group);
    await this.load();
  }

  @action async saveGroupOrders() {
    const groupParams = {};
    this.reorderingGroups.forEach(group => groupParams[group['id']] = group['order']);

    await api.patch(endpoints.CUSTOM_FIELD_GROUPS.REORDER, {'groups': groupParams});

    await this.load();
    this.closeReorderGroupsModal();
  }

  @action async saveGroup() {
    const {model, errors} = this.newCustomFieldGroup.isNew ?
      await this.store.post(endpoints.CUSTOM_FIELD_GROUPS.ALL, types.CUSTOM_FIELD_GROUP, this.newCustomFieldGroup) :
      await this.store.patch(this.newCustomFieldGroup);
    if(model){
      this.closeEditGroupModal();
      await this.load();
    }
    this.errors = errors;
  }

  @action openReorderCustomFieldsModal(group) {
    this.selectedGroup = group;
    this.reorderingFields = this.selectedGroup.customFields.map(field => new CustomField(field));
    this.reorderCustomFieldsModalOpen = true;
  }

  @action closeReorderCustomFieldsModal() {
    this.reorderCustomFieldsModalOpen = false;
  }

  @action openEditFieldModal(group, field = {customFieldGroupId: group.id}) {
    if (this.isJobCategory) {
      field.displayOnHire = true;
    }

    this.newCustomField = new CustomField(field);
    this.selectedGroup = group;
    this.editFieldModalOpen = true;
  }

  @action closeEditFieldModal() {
    this.editFieldModalOpen = false;
    this.selectedGroup = null;
    this.errors = {};
  }

  @action openDeleteGroupModal() {
    this.deleteGroupModalOpen = true;
    this.editGroupModalOpen = false;
  }

  @action closeDeleteGroupModal() {
    this.deleteGroupModalOpen = false;
    this.editGroupModalOpen = true;
  }

  @action openRemoveModal(field) {
    this.selectedField = field;
    this.removeModalOpen = true;
    this.documentsToDelete = [];
    if(!auth.company.enrolmentOnly){
      this.fetchDocumentsToDelete();
    }
  }

  @action closeRemoveModal() {
    this.removeModalOpen = false;
    this.selectedField = null;
  }

  @action async deleteCustomFieldGroup() {
    await this.store.destroy(this.newCustomFieldGroup);
    await this.load();
    this.deleteGroupModalOpen = false;
  }

  @action async fetchDocumentsToDelete(){
    this.fetchingDocumentsToDelete = true;
    const documentsToDelete = await this.store._compose(
      endpoints.COMPANY_DOCUMENTS.WITH_CUSTOM_FIELD.with(this.selectedField.id)
    );

    this.documentsToDelete = this.store._getAll(
      types.COMPANY_DOCUMENT,
      document => _.map(documentsToDelete[0].data, 'id').includes(document.id),
      CompanyDocument
    );
    this.fetchingDocumentsToDelete = false;
  }

  @action async deleteCustomField() {


    await this.store.destroy(this.selectedField);
    await this.load();
    this.closeRemoveModal();
  }

  @action updateFieldType(fieldType) {
    this.newCustomField.merge({fieldType});

    if (fieldType === 'multiple_choice') {
      this.newCustomField.options = [new CustomFieldOption()];
    } else {
      this.newCustomField.options = [];
    }
  }

  @action async saveOrders() {
    const fieldsParams = {};
    this.reorderingFields.forEach(field => fieldsParams[field['id']] = field['order']);

    await api.patch(endpoints.CUSTOM_FIELDS.REORDER, {'fields': fieldsParams});

    await this.load();
    this.closeReorderCustomFieldsModal();
  }

  @action async saveCustomField() {
    const {model, errors} = this.newCustomField.isNew ?
      await this.store.post(endpoints.CUSTOM_FIELDS.ALL, types.CUSTOM_FIELD, this.newCustomField) :
      await this.store.patch(this.newCustomField);
      if (model) {
        this.closeEditFieldModal();
        await this.load();
      }
      this.errors = errors;
  }

  @action async employeeGroupSelected(group) {
    if (group.id === 'createNewEmployeeGroup') {
      redirect('/company_settings/employee_groups/new/');
    } else {
      this.newCustomField.merge({employeeGroup: group});
    }
  }

  @computed get availableEmployeeGroups() {
    return [
      ...this.employeeGroups.map(group => ({
        id: group.id,
        name: group.name
      })),
      {
        id: 'createNewEmployeeGroup',
        name: t('company_settings.custom_fields.+ Create New Employee Group')
      }
    ];
  }

  @computed get sortedGroups() {
    return _.sortBy(this.groups, 'order');
  }

  @computed get noDocumentsToDelete() {
    return !this.fetchingDocumentsToDelete && this.documentsToDelete.length === 0;
  }

  @computed get selectedGroupHasFields() {
    return this.newCustomFieldGroup && this.newCustomFieldGroup.customFields.length > 0;
  }

  @computed get REMOVE_MODAL_LOCALIZATION() {
    return this.selectedGroupHasFields ?
      {
        header: 'company_settings.custom_fields.Remove Custom Field Group?',
        body: 'company_settings.custom_fields.Cannot remove group as it still has fields in it. Please remove them to allow group deletion.',
      } :
      {
        header: 'company_settings.custom_fields.Remove Custom Field Group?',
        subHeader: 'company_settings.custom_fields.Are you sure you want to remove this custom field group?',
      };
  }

  @computed get isJobCategory() {
    return this.category === 'job';
  }
}

export default CustomFieldGroupListState;
