import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {Calendar} from './components';

const CalendarPage = () => {
  return (
    <Router basename='/calendar'>
      <Switch>
        <Route exact path='/' component={Calendar}/>
        <Redirect from='*' to='/'/>
      </Switch>
    </Router>
  );
};

export default CalendarPage;
