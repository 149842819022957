import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {auth, withState} from 'shared/core';
import PayrollNotEnabledSwitch from './PayrollNotEnabledSwitch';
import PayrollEnabledSwitch from './PayrollEnabledSwitch';
import {PayrollPageState} from './state';
import {NmbrPage} from 'containers/nmbr';

const PayrollPage = observer(({uiState}) => {
  const {scope} = uiState;

  if (auth.company.showNmbrPayroll) {
    return (
      <Router basename='/payroll'>
        <NmbrPage/>
      </Router>
    );
  }

  return (
    <Router basename='/payroll'>
      <Switch>
        {auth.company.payrollEnabled
          ? <PayrollEnabledSwitch scope={scope}/>
          : <PayrollNotEnabledSwitch/>
        }
      </Switch>
    </Router>
  );
});

export default withState(PayrollPage, PayrollPageState);
