import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Avatar, AvatarList, CheckboxWithLabel} from 'components';
import {ReviewRow} from 'components/flow';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const EmailSettings = ({uiState}) => {
  const {policy} = uiState;

  return (
    <div>
      <div>
        <CheckboxWithLabel
          label='time_tracking.manage.policies.edit.Notify employees when a pay period has ended'
          value={policy.sendApprovedTimesheetEditedNotification}
          checked={policy.sendApprovedTimesheetEditedNotification}
          onChange={e => policy.merge({sendApprovedTimesheetEditedNotification: e.checked})}/>
      </div>
      <div className='mt2'>
        <CheckboxWithLabel
          label='time_tracking.manage.policies.edit.Notify approvers when a pay period has ended'
          value={policy.sendPayPeriodEndedNotification}
          checked={policy.sendPayPeriodEndedNotification}
          onChange={e => policy.merge({sendPayPeriodEndedNotification: e.checked})}/>
      </div>
      <div className='mt2'>
        <CheckboxWithLabel
          label='time_tracking.manage.policies.edit.Notify approvers when an approved timesheet is edited'
          value={policy.sendEmployeeTimesheetReminder}
          checked={policy.sendEmployeeTimesheetReminder}
          onChange={e => policy.merge({sendEmployeeTimesheetReminder: e.checked})}/>
      </div>
    </div>
  );
};

 const Review = observer(({uiState}) => {
  const {match, policy} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.Review your policy')}
        description={t('time_tracking.manage.policies.edit.Hover over a section and click edit to make changes.')}
      />
      <ReviewRow title='time_tracking.manage.policies.edit.Policy Name' value={policy.name} editRoute={`/manage/policies/${match.params.id}/edit/name`}/>
      <ReviewRow title='time_tracking.manage.policies.edit.Pay Schedule' value={policy.paySchedule.name} editRoute={`/manage/policies/${match.params.id}/edit/pay_schedule`}/>
      <ReviewRow title='time_tracking.manage.policies.edit.Employees' editRoute={`/manage/policies/${match.params.id}/edit/employees`}>
        <AvatarList employees={policy.employees} moreModalEnabled/>
      </ReviewRow>
      <ReviewRow title='time_tracking.manage.policies.edit.steps.Approver' editRoute={`/manage/policies/${match.params.id}/edit/assignee`}>
        {policy.assigneeType === 'manager' ?
          <FormattedMessage id='time_tracking.manage.policies.edit.Manager'/> :
          <div className='flex'><Avatar size='sm' employee={policy.assigneeUser}/></div>
        }
      </ReviewRow>
      <ReviewRow title='time_tracking.manage.policies.edit.Emails' lastRow>
        <EmailSettings uiState={uiState}/>
      </ReviewRow>
    </div>
  );
});

export default Review;
