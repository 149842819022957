import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';

const Accordion = observer(({ active, children }) => {
  const containerRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [changeHeightTo0, setChangeHeightTo0] = useState(false);
  const activeRef = useRef(false);
  const maxHeightRef = useRef(0);

  const calculateMaxHeight = () => {
    if (active && !containerRef.current) {
      setMaxHeight('none');
    } else {
      if(maxHeightRef.current === 'none') {
        if (!active) {
          // You can't transition from "none" to a value, it just changes immediately,
          // so we need to first set the max-height back to the current height, then
          // after that css is applied, set the height back to 0
          setMaxHeight(containerRef.current.offsetHeight);
          setChangeHeightTo0(true);
        }
      } else {
        setMaxHeight(active ? containerRef.current.offsetHeight : 0);
      }
    }
  };

  useEffect(() => {
    maxHeightRef.current = maxHeight;
  }, [maxHeight]);

  useEffect(() => {
    // We need to set the max-height to 0 in the frame after it rerenders with the max-height height set to the current height
    // We need both the useEffect and requestAnimationFrame to get this to happen consistently in the next frame
    // Using only one had it happening in the same render about 30% of the time
    if (changeHeightTo0) {
      setChangeHeightTo0(false);
      window.requestAnimationFrame(() => {
        setMaxHeight(0);
      });
    }
  }, [changeHeightTo0]);

  useEffect(() => {
    calculateMaxHeight();
  }, [active, children]);

  useEffect(() => {
    activeRef.current = active;
  }, [active]);

  // When the accordion finishes opening we need to change the max-height from a specific value to "none"
  // so that if the content changes size it doesn't transition its max-height, it just lets the change happen
  const handleTransitionEnd = () => {
    if (activeRef.current) {
      setMaxHeight('none');
    }
  };

  return (
    <div className='Accordion-menu' style={{maxHeight}} onTransitionEnd={handleTransitionEnd}>
      <div className='Accordion-container' ref={containerRef}>
        {children}
      </div>
    </div>
  );
});

export default Accordion;
