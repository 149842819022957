import React from 'react';
import {observer} from 'mobx-react';
import {withState, endpoints, types, t} from 'shared/core';
import {EmployeeNameWithAvatar, Layout, Panel, InteractiveContext, Pagination} from 'components';
import {NmbrUpdatesDirectoryState} from '../state';
import {NmbrUpdate} from 'stores/nmbr';
import {FormattedMessage} from 'react-intl';
import NmbrUpdateFactory from './NmbrUpdateFactory';
import Subnav from '../../Subnav';

const EmptyState = () => {
  return (
    <Panel>
      <Panel.Header title={t('nmbr.updates.directory.Updates')}/>
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id={'nmbr.updates.directory.No payroll updates to show.'}/>
      </div>
    </Panel>
  );
};

const Updates = observer(({models}) => {
  return (
    <Panel.Stack loose>
      {models.map(model => <Panel>
        <Panel.Header title={
          <div>
            <div>
              {model.createdAtView}
            </div>
          </div>
        }>
          <div className='jumbo'>
            <FormattedMessage id='nmbr.updates.directory.CREATED_BY_USER' values={{name: model.createdByUser.name}}/>
          </div>
        </Panel.Header>
        <div className='flex'>
          <div className='col-4 mr2'>
            <div className='h3 mb2'>
              <FormattedMessage id={`nmbr.updates.directory.event_types.${model.eventType}`}/>
              <div className='h5 jumbo'>
                <FormattedMessage id='nmbr.updates.directory.EFFECTIVE_DATE' values={{date: model.effectiveDateView}}/>
              </div>
            </div>
            <EmployeeNameWithAvatar employee={model.employee}/>
          </div>
          <div className='col-8'>
            <NmbrUpdateFactory nmbrUpdate={model}/>
          </div>
        </div>
      </Panel>)}
    </Panel.Stack>
  );
});

const NmbrUpdatesDirectory = observer(({uiState}) => {
  return (
    <div>
      <Subnav/>
      <Layout>
        <InteractiveContext
          proxy={{
            type: 'async',
            endpoint: endpoints.NMBR.UPDATES.ALL,
            modelType: types.NMBR.UPDATE,
            model: NmbrUpdate,
            pageSize: 10
          }}
          empty={EmptyState}
        >
          <Updates/>
          <Pagination/>
        </InteractiveContext>
      </Layout>
    </div>
  );
});

export default withState(NmbrUpdatesDirectory, NmbrUpdatesDirectoryState);
