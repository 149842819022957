import FeaturePermission from './FeaturePermission';
import {auth} from 'shared/core';

const PERMISSIONS = [
  {
    name: 'MANAGE_TIME_OFF',
    descriptionItems: [
      'Create, edit, and delete time off policies',
      'Create, edit, and delete company holidays',
      'Approve, reject, and delete time off requests',
      'Adjust employee time off balances',
      'Assign time off policies to employees'
    ]
  },
  {
    name: 'MANAGE_TIME_TRACKING',
    descriptionItems: [
      'Access all time tracking policies',
      'Manage time tracking policies',
      'Assign time tracking policies to employees'
    ]
  },
  {
    name: 'MANAGE_ATS',
    descriptionItems: [
      'Configure job site',
      'Manage all job postings',
      'Manage candidates'
    ]
  },
  {
    name: 'MANAGE_PERFORMANCE_MANAGEMENT',
    descriptionItems: [
      'Access all Check-Ins',
      'Manage Check-In cycles',
      'Access all performance reviews',
      'Manage performance reviews',
      'Access all goals',
      'Manage goals'
    ]
  },
  {
    name: 'MANAGE_SURVEYS',
    descriptionItems: [
      'Access all surveys',
      'Manage surveys'
    ]
  },
  {
    name: 'MANAGE_TRAINING',
    descriptionItems: [
      'Create, edit, and delete training programs',
      'Assign training to employees',
      'View training completion'
    ]
  },
  {
    name: 'HIRE_EMPLOYEES',
    descriptionItems: [
      'Add employees in the system'
    ]
  },
  {
    name: 'TERMINATE_EMPLOYEES',
    descriptionItems: [
      'Terminate employees in the system'
    ]
  },
  {
    name: 'DEPARTMENT_PROFILE_REPORT',
    group: 'reports',
    section: 'company'
  },
  {
    name: 'AGE_PROFILE_REPORT',
    group: 'reports',
    section: 'company'
  },
  {
    name: 'TENURE_PROFILE_REPORT',
    group: 'reports',
    section: 'company'
  },
  {
    name: 'GENDER_PROFILE_REPORT',
    group: 'reports',
    section: 'company'
  },
  {
    name: 'ACTIVE_EMPLOYEES_REPORT',
    group: 'reports',
    section: 'employees'
  },
  {
    name: 'TERMINATED_EMPLOYEES_REPORT',
    group: 'reports',
    section: 'employees'
  },
  {
    name: 'EMERGENCY_CONTACT_INFO_REPORT',
    group: 'reports',
    section: 'employees'
  },
  {
    name: 'TIME_OFF_BALANCES_REPORT',
    group: 'reports',
    section: 'timeoff'
  },
  {
    name: 'VACATION_LIABILITY_REPORT',
    group: 'reports',
    section: 'timeoff'
  },
  {
    name: 'MANUAL_ADJUSTMENTS_REPORT',
    group: 'reports',
    section: 'timeoff'
  },
  {
    name: 'TIME_OFF_REQUESTS_REPORT',
    group: 'reports',
    section: 'timeoff'
  },
  {
    name: 'PAYROLL_RECONCILIATION_REPORT',
    group: 'reports',
    section: 'timeoff'
  },
  {
    name: 'PROJECT_HOURS_REPORT',
    group: 'reports',
    section: 'timeTracking'
  },
  {
    name: 'PAYROLL_UPDATES_REPORT',
    group: 'reports',
    section: 'general'
  },
  {
    name: 'MANAGE_BILLING',
    descriptionItems: [
      'Manage payment details',
      'View statements'
    ]
  },
  {
    name: 'CUSTOM_REPORT',
    group: 'reports',
    section: 'general'
  },
  {
    name: 'BENEFIT_UPDATES_REPORT',
    group: 'reports',
    section: 'general'
  },
  {
    name: 'ON_LEAVE_EMPLOYEES_REPORT',
    group: 'reports',
    section: 'general'
  },
  {
    name: 'DEPENDENT_ELIGIBILITY_REPORT',
    group: 'reports',
    section: 'general'
  },
  {
    name: 'MANAGE_BENEFITS',
    descriptionItems: [
      'View company benefit plans',
      'View and edit employee benefits enrolment information',
      'Enroll employees into benefit classes'
    ]
  },
  {
    name: 'CANDIDATES_REPORT',
    group: 'reports',
    section: 'recruiting'
  },
  {
    name: 'CANDIDATES_BY_SOURCE_REPORT',
    group: 'reports',
    section: 'recruiting'
  },
  {
    name: 'EMPLOYEE_GOALS_REPORT',
    group: 'reports',
    section: 'performance'
  },
  {
    name: 'CHECKINS_REPORT',
    group: 'reports',
    section: 'performance'
  },
  {
    name: 'MANAGE_ANNOUNCEMENTS',
    descriptionItems: [
      'Create, publish, and delete company-wide announcements'
    ]
  },
  {
    name: 'MANAGE_CALENDARS',
    descriptionItems: [
      'Enable and disable company-wide calendars',
      'Create, edit, and delete custom calendars'
    ]
  },
  {
    name: 'MANAGE_FOLDERS',
    descriptionItems: ['Create, edit, and delete folders']
  },
  {
    name: 'MANAGE_GENERAL_INFO',
    descriptionItems: [
      'Update basic company information',
      'Create, edit, and delete locations',
      'Create, edit, and delete departments'
    ]
  },
  {
    name: 'MANAGE_REMINDERS',
    descriptionItems: [
      'Create, edit, and delete reminders'
    ]
  },
  {
    name: 'MANAGE_DOCUMENTS',
    descriptionItems: [
      'Upload, create, edit, and delete documents'
    ]
  },
  {
    name: 'MANAGE_LEAVE_TYPES',
    descriptionItems: [
      'Create, edit, and delete leave types'
    ]
  },
  {
    name: 'MANAGE_EMAIL_TEMPLATES',
    descriptionItems: [
      'Customize automated email templates'
    ]
  },
  {
    name: 'MANAGE_OFFER_TEMPLATES',
    descriptionItems: [
      'Create, edit, and delete offer templates'
    ]
  },
  {
    name: 'MANAGE_TASK_TEMPLATES',
    descriptionItems: [
      'Add, edit, or delete reusable onboarding tasks',
      'Add, edit, or delete reusable offboarding tasks'
    ]
  },
  {
    name: 'MANAGE_ONBOARDING',
    descriptionItems: [
      'Create, edit, and delete onboarding tasks'
    ]
  },
  {
    name: 'PERFORMANCE_REVIEWS_REPORT',
    group: 'reports',
    section: 'performance'
  },
  {
    name: 'HISTORICAL_HEADCOUNT_REPORT',
    group: 'reports',
    section: 'headcountTurnover'
  },
  {
    name: 'TURNOVER_REPORT',
    group: 'reports',
    section: 'headcountTurnover'
  },
  {
    name: 'NEW_HIRES_AND_TERMINATIONS_REPORT',
    group: 'reports',
    section: 'headcountTurnover'
  },
  {
    name: 'SURVEYS_REPORT',
    group: 'reports',
    section: 'surveys'
  },
  {
    name: 'ANONYMOUS_SURVEYS_REPORT',
    group: 'reports',
    section: 'surveys'
  },
  {
    name: 'TRAINING_SUMMARY_REPORT',
    group: 'reports',
    section: 'training'
  },
  {
    name: 'TRAINING_RESPONSES_REPORT',
    group: 'reports',
    section: 'training'
  },
  {
    name: 'HIRING_VELOCITY_REPORT',
    group: 'reports',
    section: 'recruiting'
  },
  {
    name: 'DOCUMENTS_REPORT',
    group: 'reports',
    section: 'general'
  }
];

if (auth.company.payrollEnabled || auth.company.showNmbrPayroll) {
  PERMISSIONS.push({
    name: 'PAYROLL',
    descriptionItems: [
      'View and upload payroll documents'
    ]
  });
}

export default PERMISSIONS.map(f => new FeaturePermission(f));
