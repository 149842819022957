import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Spinner, QuickTip} from 'components';
import PdfPreviewState from 'components/pdf/preview/state/PdfPreviewState';
import PageControls from './PageControls';

const Loading = observer(({uiState, width}) => {
  if (!uiState.loading) return null;

  return (
    <div
      style={{width: `${width}px`}}
      className='absolute center mx-auto bg-white py3 top-0'>
      <Spinner/>
    </div>
  );
});

const PdfError = ({errorMessage}) => {
  return (
    <div className='m2'>
      <QuickTip
        trait='error'
        header={<FormattedMessage id='components.pdf.Error Viewing PDF'/>}
        paragraphs={[
          <FormattedMessage id={errorMessage}/>
        ]}
      />
    </div>
  );
};


@observer class PdfPreview extends React.Component {
  _mounted = false;

  async componentDidMount() {
    this._mounted = true;
    const {uiState} = this.props;
    await uiState.load();

    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    this._mounted = false;
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    const {uiState} = this.props;

    if(e.key === 'Escape' && uiState.fullScreen) {
      uiState.exitFullScreen();
    }
  }

  render() {
    const {width, className, renderOnPasswordError, uiState} = this.props;
    const {errors, viewerContainerId} = uiState;

    if (errors.browserNotSupported) {
      return <PdfError errorMessage={errors.browserNotSupported}/>;
    }

    if (errors.passwordProtected) {
      const PasswordError = renderOnPasswordError;
      return <PasswordError/>;
    }

    return (
      <div className={uiState.fullScreen ? 'FullScreenPDF' : className}
           style={{width: uiState.fullScreen ? '100%' : `${parseInt(width) + 2}px`}}
      >
        <PageControls uiState={uiState}/>
        <div id='pdfViewerContainer' className='relative'>
          <div id={viewerContainerId} className={`ViewerContainer ${(uiState.fullScreen ? 'border-top ' : 'border rounded-bottom ')}`}>
            <div id="viewer" className="pdfViewer"/>
          </div>
          {uiState.includeFabricCanvas && <canvas
            id='fabric-canvas'
            className='absolute'
            width={width}>
          </canvas>}
        </div>
        <Loading uiState={uiState} width={width}/>
      </div>
    );
  }
}

PdfPreview.defaultProps = {
  width: 798,
  className: 'relative',
  fullScreenOption: false,
  includeFabricCanvas: false,
  renderOnPasswordError: () => null
};

class PdfPreviewWrapper extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.pdfUrl !== this.props.pdfUrl;
  }

  render() {
    const {callbacks, pdfUrl, pageNumber, fullScreenOption, title, includeFabricCanvas, ...rest} = this.props;
    const uiState = new PdfPreviewState(callbacks, pdfUrl, pageNumber, fullScreenOption, title, includeFabricCanvas);
    return <PdfPreview key={pdfUrl} uiState={uiState} {...rest}/>;
  }
}

export default PdfPreviewWrapper;
