import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import FilterDropdownToggle from './FilterDropdownToggle';
import FilterDropdownMenu from './FilterDropdownMenu';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

class FilterDropdown extends React.Component {
  render() {
    const {isOpen, onOpen, onCancel, onApply, disabled, selected, searchable, searchValue, onChange, placeholder, separator, children} = this.props;

    if (disabled) {
      return (
        <div className='FilterDropdown-button placeholder'>
          <div>{_.isEmpty(selected) ? placeholder :
            selected.map((s, index) => <span key={index}>{s}</span>).reduce((accu, el) => accu != null ? [accu, <span key={accu} className='FilterDropdown-or'>{separator}</span>, el] : el)}
          </div>
        </div>
      );
    }

    return (
      <div className='inline-block relative'>
        <FilterDropdownToggle trait='filter' isOpen={isOpen} onOpen={onOpen} onCancel={onCancel}>
          <div className='inline'>
            {_.isEmpty(selected) ? placeholder :
              selected.map((s, index) => <span key={index}>{s}</span>).reduce((accu, el) => accu != null ? [accu, <span key={accu} className='FilterDropdown-or'>{separator}</span>, el] : el)
            }
          </div>
          <i className='material-icons inline'>{'arrow_drop_down'}</i>
        </FilterDropdownToggle>
        <FilterDropdownMenu isOpen={isOpen} onCancel={onCancel} onApply={onApply} children={children} searchable={searchable} searchValue={searchValue} onChange={onChange}/>
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool
};

FilterDropdown.defaultProps = {
  separator: <FormattedMessage id='reports.or'/>,
  placeholder: <FormattedMessage id='reports.All'/>
};

export default observer(FilterDropdown);
