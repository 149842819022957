import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {HtmlRender} from 'components';

const CandidateEmailModal = observer(({uiState}) => {
  const {detailsState} = uiState;
  const {openedEmail} = detailsState;

  return (
    <Modal
      isOpen={detailsState.emailModalOpen}
      title={openedEmail.subject}
      size='lg'
      translateTitle={false}
      onHide={() => detailsState.closeEmailModal()}
    >
      <HtmlRender html={openedEmail.body}/>
    </Modal>
  );
});

export default CandidateEmailModal;
