import React from 'react';
import {observer} from 'mobx-react';
import {t, endpoints, types, withState} from 'shared/core';
import {PolicyDirectoryState} from '../state';
import {Layout, AvatarList, EmptyState, Tag, InteractiveTable, HelperPanel} from 'components';
import {TimeOffPolicy} from 'stores/time_off';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import CreatePolicyModal from './CreatePolicyModal';
import RenameModal from './RenameModal';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';

const PolicyName = observer(({model}) => {
  return (
    <div>
      <div className='PanelTitle table-cell pr1'>
        <a href={`/time_off/policies/${model.id}/edit`}>{model.name}</a>
      </div>
      <div className='table-cell'>
        {!model.completed && <Tag><FormattedMessage id='Draft'/></Tag>}
      </div>
    </div>
  );
});

const PolicyMembers = observer(({model}) => {
  return (
    <div>
      {!_.isEmpty(model.employees) && <AvatarList
        size='sm'
        moreModalEnabled
        employees={model.employees}
      />}
      <div className={`${!_.isEmpty(model.employees) ? 'mt1' : ''} jumbo`}>
        <FormattedMessage
          id='employee_count'
          values={{count: model.employees.length}} />
      </div>
    </div>
  );
});

const TimeOffTypes = observer(({model}) => {
  return (
    <div>
    {model.completed && <div className='clearfix'>
      {model.sortedTypePolicies.map(typePolicy => <div key={typePolicy.id} >
        <div className='clearfix mx1'>
          <div className='col col-6 px1 right-align'>
            {typePolicy.accrualFrequency === 'unlimited' ? <FormattedMessage id='time_off.policy.Flexible'/> : typePolicy.baseAccrualRate}
          </div>
          <div className='col col-6 px1 jumbo left-align'>{typePolicy.type.name}</div>
        </div>
      </div>)}
    </div>}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'time_off.policy.Policy Name',
    width: 3,
    component: PolicyName
  },
  {
    header: 'time_off.policy.Policy Members',
    width: 3,
    component: PolicyMembers
  },
  {
    header: 'time_off.policy.Time Off Types',
    width: 5,
    component: TimeOffTypes,
    className: 'center'
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'time_off.policy.Delete Policy',
    body: 'time_off.policy.DELETE_DESC'
  },
  interactive: {
    addModel: 'time_off.policy.Add Time Off Policy'
  }
};

const LINKS = [
  {
    translations: {
      en: 'http://support.collage.co/en/articles/3793579',
      fr: 'http://support.collage.co/fr/articles/3793579'
    },
    title: 'time_off.Adding a new time off type to an existing time off policy'
  },
  {
    translations: {
      en: 'http://support.collage.co/en/articles/2714437'
    },
    title: "time_off.Changing an employee's time off policy"
  },
];

const Empty = observer(({uiState}) => {
  return (
    <EmptyState
      title={t('time_off.policy.Set up your Time Off')}
      descriptionItems={[t('time_off.policy.Save time managing time off. Customize time off rules, approve employee requests, sync to a shared calendar and easily reconcile with payroll. Start by setting up your time off rules.')]}
      callToAction={{
        onClick: () => uiState.openCreateModal(),
        title: t('time_off.policy.Get Started')
      }}
    />
  );
});

const PolicyDirectory = observer(({uiState, scope}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope}/>
      <Layout>
        <Layout.Section>
          <InteractiveTable
            uiState={uiState}
            title={t('time_off.policy.Policies')}
            onMount={agent => uiState.setInteractiveAgent(agent)}
            columns={COLUMNS}
            saveStateToUrl={true}
            searchable={false}
            localization={LOCALIZATION}
            onAdd={() => uiState.openCreateModal()}
            onRemove={policy => uiState.removePolicy(policy)}
            editRoute=':id/edit'
            customLinks={model => uiState.customLinksFor(model)}
            rowStyle='align-top'
            empty={Empty}
            proxy={{
              type: 'async',
              modelType: types.TIME_OFF.POLICY,
              model: TimeOffPolicy,
              endpoint: endpoints.TIME_OFF.POLICIES.ALL
            }}
          />
          <CreatePolicyModal uiState={uiState} />
          <RenameModal uiState={uiState} />
        </Layout.Section>
        <Layout.Section secondary>
          <HelperPanel links={LINKS}/>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(PolicyDirectory, PolicyDirectoryState);
