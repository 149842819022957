import React from 'react';
import {observer} from 'mobx-react';
import {Button, InputError, ObjectSelect2, Panel, PanelTooltip, Table, TimeInput, Spinner} from 'components';
import {dayOfWeek} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import TimesheetEntry from 'stores/time_tracking/TimesheetEntry';
import EditEntryNoteModal from 'containers/time_tracking/components/EditEntryNoteModal';
import {t, types, withState} from 'shared/core';
import TimesheetEditorState from 'containers/time_tracking/state/TimesheetEditorState';
import {ReportDownloadButton} from 'containers/time_tracking/components/download';
import CompletedIcon from 'img/list-element-complete@2x.png';
import useIsMobile from 'shared/hooks/useIsMobile';
import moment from 'moment';
import _ from 'lodash';
import EditEntryBreakModal from 'containers/time_tracking/components/EditEntryBreakModal';

const StartTime = observer(({model, uiState}) => {
  const {timesheet} = uiState;

  if (model._type === types.HOLIDAY) {
    return <div className='bold'>{model.name}</div>;
  }

  if (model._type === types.TIME_OFF.ABSENCE) {
    return <div>{uiState.typeNameById(String(model.typeId))}</div>;
  }

  if (timesheet.readOnly) return <div>{model.startTimeString}</div>;

  return (
    <div className='relative'>
      <TimeInput
        key={model.uuid}
        className={uiState.startTimeChangedSinceApproval(model) && 'bg-caution'}
        initialValue={model.startTimeString}
        onFormat={({value, error}) => (!error && value !== model.startTimeString) && uiState.updateEntry(model, {startTimeString: value})}
        errorMessage={model.errors.startTime}
        style={uiState.startTimeChangedSinceApproval(model) ? {paddingRight: '1.5rem'} : {}}
      />
      {uiState.startTimeChangedSinceApproval(model) &&
        <div className='absolute' style={{lineHeight: 0, top: '11px', right: '8px'}}>
          <PanelTooltip>
            {model.approvedStartTime ?
              <div style={{lineHeight: 1}}>
                <div className='pb2'>
                  <FormattedMessage id={'time_tracking.timesheet.EDITED_AFTER_APPROVAL'}/>
                </div>
                <div>
                  <FormattedMessage id={'time_tracking.timesheet.PREVIOUS_VALUE'} values={{time: <span className='bold'>{moment(model.approvedStartTime).utc().format(t('components.dates.TIMESTAMP_FORMAT'))}</span>}}/>
                </div>
              </div> :
              <div style={{lineHeight: 1.42857143}}>
                <FormattedMessage id={'time_tracking.timesheet.ADDED_AFTER_APPROVAL'}/>
              </div>
            }
          </PanelTooltip>
        </div>
      }
      {(model.saveStatus === 'saved' || model.saveStatus === 'fadingAway') && <div className={`h6 jumbo absolute ${model.saveStatus === 'fadingAway' ? 'fade-away' : ''}`}>
        <FormattedMessage id='time_tracking.timesheet.Saved'/>
      </div>}
      <InputError style={{padding: 0}} message={model.errors.base}/>
    </div>
  );
});

const EndTime = observer(({model, uiState}) => {
  const {timesheet, errors} = uiState;

  if (model._type === types.HOLIDAY) {
    return <div/>;
  }

  if (model._type === types.TIME_OFF.ABSENCE) {
    return <div>{model.timeRange}</div>;
  }

  if (timesheet.readOnly) return <div>{model.endTimeString}</div>;

  return (
    <div className='relative'>
      <TimeInput
        key={model.uuid}
        className={uiState.endTimeChangedSinceApproval(model) && 'bg-caution'}
        initialValue={model.endTimeString}
        lowerBound={model.startTimeString}
        onFormat={({value, error}) => (!error && value !== model.endTimeString) && uiState.updateEntry(model, {endTimeString: value})}
        style={uiState.endTimeChangedSinceApproval(model) ? {paddingRight: '1.5rem'} : {}}
      />
      {uiState.endTimeChangedSinceApproval(model) &&
        <div className='absolute' style={{lineHeight: 0, top: '11px', right: '8px'}}>
          <PanelTooltip>
            {model.approvedEndTime ?
              <div style={{lineHeight: 1}}>
                <div className='pb2'>
                  <FormattedMessage id={'time_tracking.timesheet.EDITED_AFTER_APPROVAL'}/>
                </div>
                <div>
                  <FormattedMessage id={'time_tracking.timesheet.PREVIOUS_VALUE'} values={{time: <span className='bold'>{moment(model.approvedEndTime).utc().format(t('components.dates.TIMESTAMP_FORMAT'))}</span>}}/>
                </div>
              </div> :
              <div style={{lineHeight: 1.42857143}}>
                <FormattedMessage id={'time_tracking.timesheet.ADDED_AFTER_APPROVAL'}/>
              </div>
            }
          </PanelTooltip>
        </div>
      }
      <InputError style={{padding: 0}} message={_.get(errors, `entries.${model.id}.endTime`)}/>
    </div>
  );
});

const Date = observer(({model}) => {
  if (!model.showDate) return null;

  return (
    <div className='flex'>
      <div>{(dayOfWeek(model.date))}</div>
    </div>
  );
});

const Note = observer(({model}) => {
  if (!model.hasNote) return null;

  return (
    <div className='relative'>
      <PanelTooltip icon='description'>{model.note}</PanelTooltip>
    </div>
  );
});

const Project = observer(({model, uiState}) => {
  const {timesheet, projects} = uiState;

  if (model._type === types.TIME_OFF.ABSENCE || model._type === types.HOLIDAY) return null;
  if (timesheet.readOnly) return <div>{_.get(model.project, 'name')}</div>;

  const availableProjects = _.compact(_.unionBy(projects, [model.project], 'id'));

  return (
    <div className='relative'>
      <ObjectSelect2
        searchable
        allowClear
        value={model.project}
        disabled={_.isEmpty(availableProjects)}
        onChange={value => uiState.updateEntry(model, {project: value})}
        items={availableProjects}
      />
    </div>
  );
});

const Hours = observer(({model}) => {
  if (model._type === types.TIME_OFF.ABSENCE) {
    return <div className='right-align pr1'>{parseFloat(model.amount)}</div>;
  }

  return (
    <div className='right-align pr1'>
      <div className='Tooltip'>
        <div>{model.hours}</div>
        {model.unpaidBreakMinutes > 0 && <div className='h6 jumbo'>
          <FormattedMessage id='time_tracking.timesheet.UNPAID_BREAK_HOURS' values={{hours: model.unpaidBreakHours}}/>
          <span className='tooltiptext dateTooltip'>
            <FormattedMessage id='time_tracking.timesheet.HOURS_CALCULATION' values={{worked: model.hours, unpaid: model.unpaidBreakHours}}/>
          </span>
        </div>}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'time_tracking.timesheet.Date',
    width: 2,
    component: Date
  },
  {
    header: 'time_tracking.timesheet.In',
    width: 2,
    component: StartTime
  },
  {
    header: 'time_tracking.timesheet.Out',
    width: 2,
    component: EndTime
  },
  {
    header: 'time_tracking.timesheet.Hours',
    width: 1,
    component: Hours
  },
  {
    header: 'time_tracking.timesheet.Project',
    width: 3.25,
    component: Project
  },
  {
    header: 'time_tracking.timesheet.Note',
    width: 0.75,
    component: Note
  }
];

const PTOBreakdownTooltip = observer(({uiState}) => {
  const {timesheet} = uiState;

  if (_.isEmpty(timesheet.totals.pto)) return null;

  return (
    <PanelTooltip>
      {Object.entries(timesheet.totals.pto).map(
        ([key, value]) => <div><span className='mr2'>{uiState.typeNameById(key)}</span><span className='medium'>{value}</span></div>
      )}
    </PanelTooltip>
  );
});

const HolidayBreakdownTooltip = observer(({uiState}) => {
  const {timesheet} = uiState;

  if (_.isEmpty(timesheet.totals.holidays)) return null;

  return (
    <PanelTooltip>
      {Object.entries(timesheet.totals.holidays).map(
        ([key, value]) => <div><span className='mr2'>{uiState.holidayNameById(key)}</span><span className='medium'>{value}</span></div>
      )}
    </PanelTooltip>
  );
});

const Metric = ({label, value, children}) => {
  return (
    <div className='mr2 flex align-items-middle'>
      <div className='jumbo mr1'>{t(label)}</div>
      <div className='black'><span>{value}</span><span>{children}</span></div>
    </div>
  );
};

const MetricSpacer = () => {
  return (
    <div className='mr2 jumbo'>{'|'}</div>
  );
};

const Totals = observer(({uiState}) => {
  const {timesheet} = uiState;
  return (
    <div className='border-bottom border-top flex py3 mb2 align-items-middle flex-wrap' style={{marginLeft: '-1.5rem', marginRight: '-1.5rem', paddingRight: '1.5rem', paddingLeft: '1.5rem'}}>
      <Metric label='time_tracking.timesheet.Regular' value={timesheet.totals.regular}/>
      <MetricSpacer/>
      <Metric label='time_tracking.timesheet.Overtime' value={timesheet.totals.overtime}/>
      <MetricSpacer/>
      <Metric label='time_tracking.timesheet.Double Overtime' value={timesheet.totals.doubleOvertime}/>
      <MetricSpacer/>
      <React.Fragment>
        <Metric label='time_tracking.timesheet.Holiday Worked' value={timesheet.totals.holidaysTotal}>
          <HolidayBreakdownTooltip uiState={uiState}/>
        </Metric>
        <MetricSpacer/>
      </React.Fragment>
      <Metric label='time_tracking.timesheet.Paid Time Off' value={timesheet.totals.ptoTotal}>
        <PTOBreakdownTooltip uiState={uiState}/>
      </Metric>
      <MetricSpacer/>
      <Metric label='time_tracking.timesheet.Total' value={timesheet.totals.total}/>
    </div>
  );
});

const ApproveButton = observer(({uiState}) => {
  const {timesheet} = uiState;

  if (timesheet.approved) {
    return (
      <div className='flex align-items-center px2 ml2 border rounded bg-haze' style={{minHeight: 36}}>
        <div className='mr1'>
          <FormattedMessage id='time_tracking.timesheet.Approved'/>
        </div>
        <img src={CompletedIcon} width='18' alt='complete'/>
      </div>
    );
  }

  if (!timesheet.canApprove) return null;

  return (
    <div className='ml2'>
      <Button onClick={() => uiState.approve()}>
        <FormattedMessage id='time_tracking.timesheet.Approve'/>
      </Button>
    </div>
  );
});

const TimesheetActions = observer(({uiState}) => {
  const {payPeriod, employee, errors} = uiState;

  return (
    <div>
      <div className='flex justify-content-end'>
        <ReportDownloadButton
          reportType='timesheet'
          name={t('time_tracking.timesheet.Timesheet')}
          params={{
            payPeriodId: payPeriod.id,
            employeeId: employee.id
          }}
        />
        <ApproveButton uiState={uiState}/>
      </div>
      <div className='flex justify-content-end left-align'>
        <InputError className='inline-block relative nowrap' message={errors.base}/>
      </div>
    </div>
  );
});

const TimesheetEditor = observer(({uiState}) => {
  const editEntryNoteModalOpen = useModal(uiState, 'EditEntryNoteModal', 'editingEntry', TimesheetEntry);
  const editEntryBreakModalOpen = useModal(uiState, 'EditEntryBreakModal', 'editingEntry', TimesheetEntry);
  const {payPeriod, payPeriods, employee, isLoadingEntries} = uiState;
  const isMobile = useIsMobile();

  if (isLoadingEntries) return <Panel><Spinner/></Panel>;

  return (
    <Panel>
      {payPeriods ? <div className='flex flex-wrap justify-content-between align-items-end mb3'>
          <ObjectSelect2
            value={payPeriod}
            items={payPeriods}
            display='effectiveDateView'
            label='time_tracking.timesheet.Pay Period'
            onChange={value => uiState.onPayPeriodSelected(value)}
          />
          <TimesheetActions uiState={uiState}/>
        </div> :
        <Panel.Header title={employee.name}>
          <TimesheetActions uiState={uiState}/>
        </Panel.Header>
      }
      <Totals uiState={uiState}/>
      <Table
        uiState={uiState}
        models={uiState.rows()}
        columns={COLUMNS}
        customLinks={model => uiState.customLinksFor(model)}
        onlyShowCustomLinks
        onRemove={model => uiState.removeEntry(model.data)}
        horizontalScroll={isMobile}
      />
      <EditEntryNoteModal
        uiState={uiState}
        isOpen={editEntryNoteModalOpen}
      />
      <EditEntryBreakModal
        uiState={uiState}
        isOpen={editEntryBreakModalOpen}
      />
    </Panel>
  );
});

export default withState(TimesheetEditor, TimesheetEditorState);
