import {action, observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';
import {endpoints, types, t} from 'shared/core';
import {EmployeeBenefitEnrollment} from 'stores/benefits';
import {Employee} from 'stores/employees';
import _ from 'lodash';
import BenefitEnrolmentViewModel from './BenefitEnrolmentViewModel';

class EmployeeBenefitUnenrolmentFormState {
  store = new DomainStore();
  history;
  employeeId;

  @observable errors = {};
  @observable employee;
  @observable companyInsuranceCarriers = [];
  @observable enrolmentsNotMarkedForDeletion;

  receiveProps({history, employeeId}) {
    this.history = history;
    this.employeeId = employeeId;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(this.employeeId),
      endpoints.EMPLOYEE.EMPLOYEE_BENEFIT_ENROLMENTS.with(this.employeeId),
      endpoints.COMPANY_INSURANCE_CARRIERS.WITH_OPTIONAL
    ]);

    const employeeBenefitEnrolments = this.store._getAll(
      types.EMPLOYEE_BENEFIT_ENROLLMENT, EmployeeBenefitEnrollment
    );
    this.enrolmentsNotMarkedForDeletion = employeeBenefitEnrolments.filter(enrolment => _.isNil(enrolment.endDate));
    this.employee = new Employee(this.store._getSingle(types.EMPLOYEE_DETAILS, {id: this.employeeId}));

    this.setDefaultEndDateForEnrolments(this.enrolmentsNotMarkedForDeletion);
  }

  @action toggleUnenrol(enrolmentViewModel) {
    enrolmentViewModel.selectedToKeep = !enrolmentViewModel.selectedToKeep;
    const endDate = enrolmentViewModel.selectedToKeep ? null : new Date();
    enrolmentViewModel.enrolment.merge({endDate: endDate, reasonForUnenrolment: null});
  }

  @action async unenrolEmployee() {
    const {model, errors} = await this.store.post(
      endpoints.EMPLOYEE.MARK_FOR_UNENROLMENT.with(this.employeeId),
      types.EMPLOYEE_DETAILS,
      {
        employeeBenefitEnrollments: this.enrolmentsNotMarkedForDeletion
      }
    );

    this.errors = errors;

    if (model) {
      successAlert(t('benefit_enrolment.unenrol.SUCCESSFULLY_TERMINATED_COVERAGE', {name: this.employee.name}));
      this.history.push('/benefit_enrolments');
    }
  }

  @action async cancelUnenrolment() {
    const {model} = await this.store.post(
      endpoints.EMPLOYEE.MARK_FOR_UNENROLMENT.with(this.employeeId),
      types.EMPLOYEE_DETAILS,
      {
        employeeBenefitEnrollments: this.enrolmentsNotMarkedForDeletion
      }
    );

    if (model) {
      successAlert(t('benefit_enrolment.unenrol.Cancelled unenrolment'));
    }
  }

  @action setDefaultEndDateForEnrolments(enrolments) {
    enrolments.forEach(enrolment => enrolment.merge({endDate: new Date()}));
  }

  @computed get enrolmentViewModels() {
    return this.enrolmentsNotMarkedForDeletion.map(enrolment => new BenefitEnrolmentViewModel(enrolment));
  }

  @computed get showUnenrolToggle() {
    return this.enrolmentsNotMarkedForDeletion.length > 1;
  }
}

export default EmployeeBenefitUnenrolmentFormState;
