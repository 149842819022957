import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Panel, InteractiveTable} from 'components';
import BankingChequeHelperModal from './BankingChequeHelperModal';
import EditBankingInfoModal from './EditBankingInfoModal';
import {auth, endpoints, types, t} from 'shared/core';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';
import SinInfo from './SinInfo';
import BankingInfo from './BankingInfo';
import {NmbrPayStub} from 'stores/nmbr';

const ToggleBankingInfoButton = observer(({uiState}) => {
  if (uiState.bankingInformationHidden) {
    return (
      <ActionLink onClick={() => uiState.showBankingInformation()}>
        <FormattedMessage id='employees.profile.banking.Show'/>
      </ActionLink>
    );
  }

  return (
    <ActionLink onClick={() => uiState.hideBankingInformation()}>
      <FormattedMessage id='employees.profile.banking.Hide'/>
    </ActionLink>
  );
});

const DownloadButton = observer(({model}) => {
  let origin = window.location.origin;

  if (!origin) {
    origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  return (
    <a href={`${origin}/api/v1${endpoints.NMBR.PAY_STUBS.DOWNLOAD.with(model.id)}`} download={true}>
      <FormattedMessage id='employees.profile.banking.Download'/>
    </a>
  );
});

const COLUMNS = [
  {
    header: 'employees.profile.banking.Pay Period',
    width: 10,
    attribute: 'dateRange'
  },
  {
    header: 'employees.profile.banking.Download',
    width: 2,
    className: 'right-align',
    component: DownloadButton
  }
];

const BankingContainer = observer(({uiState}) => {
  const {employee, nmbrPayStubs, userIsEmployee} = uiState;

  return (
    <EmployeeProfileContainer uiState={uiState}>
      <div className='fs-hide'>
        <Panel.Stack loose>
          {uiState.viewDataPermission('::BANKING_INFORMATION') && (auth.company.bankingEnabled || auth.company.sinEnabled) && <Panel>
            <Panel.Header title={t('employees.profile.banking.Banking Information')}>
              <ToggleBankingInfoButton uiState={uiState}/>
              {uiState.editDataPermission('::BANKING_INFORMATION') &&
                <ActionLink onClick={() => uiState.openBankingInfoModal()} className='ml2'>
                  <FormattedMessage id='employees.profile.banking.Edit'/>
                </ActionLink>}
            </Panel.Header>
            <SinInfo uiState={uiState}/>
            <BankingInfo uiState={uiState}/>
          </Panel>}
          {employee.nmbrPayrollEnabled && auth.company.showNmbrPayroll && (auth.hasAccess('::PAYROLL') || userIsEmployee) && <InteractiveTable
            title={t('employees.profile.banking.Pay Stubs')}
            models={nmbrPayStubs}
            columns={COLUMNS}
            showLinks={false}
            searchable={false}
            sortable={false}
            proxy={{
              type: 'async',
              modelType: types.NMBR.PAY_STUB,
              model: NmbrPayStub,
              endpoint: endpoints.NMBR.PAY_STUBS.with(employee.id),
              pageSize: 15
            }}
          />}
        </Panel.Stack>
        <BankingChequeHelperModal uiState={uiState}/>
        <EditBankingInfoModal uiState={uiState}/>
      </div>
    </EmployeeProfileContainer>
  );
});

export default BankingContainer;
