import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table, Tag} from 'components';
import {t, withState} from 'shared/core';
import _ from 'lodash';
import moment from 'moment';
import DayOffHoursAndDays from 'components/DayOffHoursAndDays';
import {calendarDate, redirect} from 'shared/tools';
import {CustomHoursState} from '../state';
import {FormattedMessage} from 'react-intl';
import {ModalButtons} from 'components/modals';

const DatesHeader = observer(({startDate, endDate, event}) => {
  return (
    <div className='flex align-items-middle'>
      <div>{`${startDate} - ${endDate}`}</div>
      {event.extendedProps.status === 'pending' && <Tag colour='sun' className='ml1'>
        <FormattedMessage id='calendar.time_off_requests.Pending'/>
      </Tag>}
    </div>
  );
});

const DayOffDate = observer(({model}) => {
  const {date} = model;

  return (
    <div>
      <div>{calendarDate(date)}</div>
      <div className='jumbo'>{`${moment(date).format('dddd')} ${model.timeRange ? model.timeRange : ''}`}</div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 6,
    component: DayOffDate
  },
  {
    width: 6,
    component: DayOffHoursAndDays
  },
];

const CustomHours = observer(({uiState, onCloseModal}) => {
  const {event, daysOff} = uiState;

  return (
    <div>
      <Panel.Header title={<DatesHeader startDate={calendarDate(_.head(daysOff).date)} endDate={calendarDate(_.last(daysOff).date)} event={event}/>}/>
      <Panel.Separator/>
      <Panel.Subheader title={t('calendar.time_off_requests.Days Off')}/>
      <Table
        indexBy='date'
        showHeaders={false}
        showLinks={false}
        models={daysOff}
        columns={COLUMNS}
        trait='xcompact'
      />
      {<ModalButtons
        hideSave={event.extendedProps.status === 'approved'}
        cancelCaption='calendar.time_off_requests.Close'
        saveCaption='calendar.time_off_requests.View & Action'
        onCancel={() => onCloseModal()}
        onSave={() => redirect(`/time_off/pending_requests/${event.extendedProps.requestId}`)}
      />}
    </div>
  );
});

export default withState(CustomHours, CustomHoursState);
