import _ from 'lodash';
import {fullName} from 'shared/tools';
/*global window, DOMParser */

const domParser = new DOMParser();

const featurePermissions = [
  'MANAGE_TIME_OFF',
  'MANAGE_PERFORMANCE_MANAGEMENT',
  'MANAGE_SURVEYS',
  'MANAGE_TIME_TRACKING',
  'MANAGE_ATS',
  'MANAGE_TRAINING',
  'MANAGE_BILLING',
  'MANAGE_BENEFITS',
  'HIRE_EMPLOYEES',
  'TERMINATE_EMPLOYEES',
  'MANAGE_COMPANY_SETTINGS',
  'DEPARTMENT_PROFILE_REPORT',
  'AGE_PROFILE_REPORT',
  'TENURE_PROFILE_REPORT',
  'GENDER_PROFILE_REPORT',
  'ACTIVE_EMPLOYEES_REPORT',
  'TERMINATED_EMPLOYEES_REPORT',
  'NEW_HIRES_AND_TERMINATIONS_REPORT',
  'EMERGENCY_CONTACT_INFO_REPORT',
  'TIME_OFF_BALANCES_REPORT',
  'VACATION_LIABILITY_REPORT',
  'MANUAL_ADJUSTMENTS_REPORT',
  'TIME_OFF_REQUESTS_REPORT',
  'PAYROLL_RECONCILIATION_REPORT',
  'PROJECT_HOURS_REPORT',
  'PAYROLL_UPDATES_REPORT',
  'BENEFIT_UPDATES_REPORT',
  'CUSTOM_REPORT',
  'PAYROLL',
  'ON_LEAVE_EMPLOYEES_REPORT',
  'DEPENDENT_ELIGIBILITY_REPORT',
  'CANDIDATES_REPORT',
  'CANDIDATES_BY_SOURCE_REPORT',
  'EMPLOYEE_GOALS_REPORT',
  'CHECKINS_REPORT',
  'PERFORMANCE_REVIEWS_REPORT',
  'SURVEYS_REPORT',
  'ANONYMOUS_SURVEYS_REPORT',
  'HISTORICAL_HEADCOUNT_REPORT',
  'TURNOVER_REPORT',
  'TRAINING_SUMMARY_REPORT',
  'TRAINING_RESPONSES_REPORT',
  'HIRING_VELOCITY_REPORT',
  'DOCUMENTS_REPORT',
  'MANAGE_ANNOUNCEMENTS',
  'MANAGE_CALENDARS',
  'MANAGE_FOLDERS',
  'MANAGE_GENERAL_INFO',
  'MANAGE_REMINDERS',
  'MANAGE_DOCUMENTS',
  'MANAGE_LEAVE_TYPES',
  'MANAGE_EMAIL_TEMPLATES',
  'MANAGE_TASK_TEMPLATES',
  'MANAGE_OFFER_TEMPLATES',
  'MANAGE_ONBOARDING'
];

const allModules = [
  'directory',
  'ats',
  'performance',
  'training',
  'task_management',
  'inbox',
  'benefits',
  'payroll',
  'surveys',
  'pto',
  'billing',
  'account_management'
];

const dataPermissions = [
  'TASKS',
  'PERFORMANCE_GOALS',
  'TIME_TRACKING',
  'DOCUMENTS'
];

class Auth {
  constructor(auth) {
    _.merge(this, auth);

    _.set(this, 'user.name', fullName(this.user || {}));
  }

  moduleEnabled(module_to_check) {
    if (!_.includes(allModules, module_to_check))
      throw new Error(`Unsupported module value: ${module_to_check}`);

    return _.includes(this.modules, module_to_check);
  }

  featureEnabled(feature) {
    if (!_.startsWith(feature, ':') || _.startsWith(feature, '::')) {
      throw new Error(`Feature name must be prefixed with a single colon. Use auth.featureEnabled(':${feature}').`);
  }
    return _.includes(this.features, _.trimStart(feature, ':'));
  }

  hasAccess(permission) {
    if (!_.startsWith(permission, '::')) {
      throw new Error(`Target name must be prefixed with two colons. Use auth.hasAccess('::${permission}').`);
    }

    const trimmedPermission = _.trimStart(permission, '::');
    if (!_.includes(featurePermissions, trimmedPermission)) {
      throw new Error(`Target ${permission} not a valid feature permission`);
    }

    return _.includes(this.permissions, trimmedPermission);
  }

  viewData(permission) {
    if (!_.startsWith(permission, '::')) {
      throw new Error(`Target name must be prefixed with two colons. Use auth.viewData('::${permission}').`);
    }

    const trimmedPermission = _.trimStart(permission, '::');
    if (!_.includes(dataPermissions, trimmedPermission)) {
      throw new Error(`Target ${permission} not a valid employee data permission`);
    }

    return _.includes(this.dataPermissions, trimmedPermission);
  }

  get unescapedCompanyName() {
    const dom = domParser.parseFromString(
      '<!doctype html><body>' + this.company.name, 'text/html'
    );

    return dom.body.textContent;
  }
}

const auth = new Auth(window.auth);

export default auth;
