import React from 'react';
import {observer} from 'mobx-react';
import {auth, withState, t, types, endpoints} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {EmployeeNameWithAvatar, Layout, InteractiveTable, FancyFilter, PanelTooltip} from 'components';
import {calendarDate} from 'shared/tools';
import {NmbrDirectoryEmployee} from 'stores/nmbr';
import AddEmployeeModal from './AddEmployeeModal';
import RemoveEmployeeModal from './RemoveEmployeeModal';
import useModal from 'shared/hooks/useModal';
import {NmbrEmployeesDirectoryState} from '../state';
import BatchAddEmployeesModal from './BatchAddEmployeesModal';
import BatchAddEmployeesResultsModal from './BatchAddEmployeesResultsModal';
import _ from 'lodash';
import {statusTagColour} from 'employees/tools';

const SyncErrors = observer(({missingFields, errorsFromLastSync}) => {
  return (
    <div className='flex align-items-middle'>
      <PanelTooltip
        icon={_.isEmpty(errorsFromLastSync) ?
          <i className='material-icons sun mr1 mln1 h2'>{'warning'}</i> :
          <i className='material-icons scarlet mr1 mln1 h2'>{'warning'}</i>
        }
      >
        <div className='space-y-2'>
          {!_.isEmpty(missingFields) && <div>
            <div className='mb1'><FormattedMessage id='nmbr.employees.directory.The following required fields have not been filled out for this employee:'/></div>
            {missingFields.map(field => <li className='ml2'>
              <FormattedMessage id={`nmbr.errors.missing_fields.${field}`}/>
            </li>)}
          </div>}
          {!_.isEmpty(errorsFromLastSync) && <div>
            <div className='mb1'><FormattedMessage id='nmbr.employees.directory.The following fields are invalid and could not be processed for this employee:'/></div>
            <div className='space-y'>
              {Object.entries(errorsFromLastSync).map(([fieldType, errors]) => <div>
                <div className='medium'>
                  <FormattedMessage id={`nmbr.employees.directory.sync_error_headers.${fieldType}`}/>
                </div>
                <ul className='ml2'>
                  {errors.map(error => <li>
                    {t(`nmbr.employees.directory.sync_errors.${fieldType}`, {
                      effectiveDate: calendarDate(error.effectiveDate),
                      recordType: t(`nmbr.errors.record_types.${error.recordType}`),
                      errors: error.translateErrors ? error.errors.map(e => t(`nmbr.errors.messages.${e}`)).join(' ') : error.errors.join(' ')
                    })}
                  </li>)}
                </ul>
              </div>)}
            </div>
          </div>}
        </div>
      </PanelTooltip>
      <FormattedMessage id={_.isEmpty(errorsFromLastSync) ? 'nmbr.employees.directory.Missing Info' : 'nmbr.employees.directory.Errors'}/>
    </div>
  );
});

const EmployeeName = observer(({model}) => {
  const tags = model.statusTags.map(status => {
    return { 
      content: t(`employees.profile.${status}`),
      colour: statusTagColour(status)
    };
  });

  return (
    <EmployeeNameWithAvatar
      href={`/employees/${model.id}/`} employee={model} size='sm' tags={tags}
    />
  );
});

const NmbrStatus = observer(({model}) => {
  if (!_.isEmpty(model.missingFields) || !_.isEmpty(model.errorsFromLastSync)) {
    return <SyncErrors missingFields={model.missingFields} errorsFromLastSync={model.errorsFromLastSync}/>;
  }

  if (model.nmbrPayrollEnabled) {
    return (
      <div className='flex align-items-middle'>
        <i className='material-icons grass mr1 h2'>{'done'}</i>
        <FormattedMessage id='nmbr.employees.directory.In Payroll'/>
      </div>
    );
  }

  return (
    <div className='flex align-items-middle'>
      <i className='material-icons waterloo mr1 h2'>{'horizontal_rule'}</i>
      <FormattedMessage id='nmbr.employees.directory.Not In Payroll'/>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'nmbr.employees.directory.Employee',
    component: EmployeeName,
    width: 3,
    sortBy: 'name'
  },
  {
    header: 'nmbr.employees.directory.Department',
    width: 2,
    attribute: 'department.name'
  },
  {
    header: 'nmbr.employees.directory.Location',
    width: 2,
    attribute: 'location.name'
  },
  {
    header: 'nmbr.employees.directory.Employment Type',
    width: 2,
    render: model => <FormattedMessage id={`nmbr.employees.directory.employment_type.${model.employmentType}`}/>
  },
  {
    header: 'nmbr.employees.directory.Status',
    component: NmbrStatus,
    width: 2
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'nmbr.employees.directory.Batch Add Employees',
  },
  emptyState: 'nmbr.employees.directory.No employees to display'
};

function fancyFilters() {
  const filters = ['location', 'department', 'employment_type'];

  if (auth.hasAccess('::TERMINATE_EMPLOYEES')) {
    filters.push('employment_status');
  }

  return filters;
}

const NmbrEmployeesDirectoryWithState = observer(({uiState}) => {
  const addEmployeeModalOpen = useModal(uiState, 'AddEmployeeModal', 'selectedEmployee', null, 'errors');
  const removeEmployeeModalOpen = useModal(uiState, 'RemoveEmployeeModal', 'selectedEmployee', null, 'errors');
  const batchAddEmployeesModalOpen = useModal(uiState, 'BatchAddEmployeesModal');
  const batchAddEmployeesResultsModalOpen = useModal(uiState, 'BatchAddEmployeesResultsModal');

  return (
    <Layout>
      <InteractiveTable
        title={t('nmbr.employees.directory.Employees')}
        onAdd={() => uiState.openBatchAddEmployeesModal()}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        saveStateToUrl
        fancyFiltersEnabled
        initialFilter={auth.hasAccess('::TERMINATE_EMPLOYEES') ? {employment_status: 'non_terminated'} : {}}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        customLinks={model => uiState.customLinksFor(model)}
        proxy={{
          type: 'async',
          modelType: types.EMPLOYEE,
          model: NmbrDirectoryEmployee,
          endpoint: endpoints.NMBR.EMPLOYEES.ALL
        }}
      >
        <FancyFilter filters={fancyFilters()}/>
      </InteractiveTable>
      <AddEmployeeModal uiState={uiState} modalOpen={addEmployeeModalOpen}/>
      <RemoveEmployeeModal uiState={uiState} modalOpen={removeEmployeeModalOpen}/>
      <BatchAddEmployeesModal uiState={uiState} modalOpen={batchAddEmployeesModalOpen}/>
      <BatchAddEmployeesResultsModal uiState={uiState} modalOpen={batchAddEmployeesResultsModalOpen}/>
    </Layout>
  );
});

export default withState(NmbrEmployeesDirectoryWithState, NmbrEmployeesDirectoryState);
