import _ from 'lodash';
import {observable, action, computed, toJS} from 'mobx';
import ContentSection from 'stores/recruiting/ContentSection';
import Collaborator from 'stores/recruiting/Collaborator';
import Question from 'stores/recruiting/Question';
import PositionRule from 'stores/recruiting/PositionRule';
import {FunnelStage, defaultFunnelStages} from 'stores/recruiting/FunnelStage';
import {withDeleted} from 'shared/tools';
import steps from 'recruiting/state/steps';
import {types, t} from 'shared/core';
import RULE_QUESTION_TYPES from 'stores/recruiting/ruleQuestionTypes';

class EditPositionState {
  store;
  history;
  recruitingState;

  @observable currentStep;
  @observable position;
  @observable errors = {};
  @observable departments = [];
  @observable locations = [];
  @observable users = [];
  @observable emailPreviewModalOpen = false;
  @observable editQuestionModalOpen = false;
  @observable editRuleModalOpen = false;
  @observable editRequireResumeModalOpen = false;
  @observable editingQuestion = new Question({questionType: 'text_field'});
  @observable editingRule = new PositionRule();
  @observable originalRule;
  @observable previewModalTitle;
  @observable previewModalContent;
  @observable confirmationPreview;
  @observable disqualificationPreview;
  @observable jobSite;
  @observable isNew = false;

  constructor(recruitingStore, history, recruitingState) {
    this.store = recruitingStore;
    this.history = history;
    this.recruitingState = recruitingState;
  }

  @action onStepChanged({location}) {
    if (location === 'description' && !this.position.contentSection) {
      this.position.contentSection = new ContentSection();
    }
    if (location === 'settings' && _.isEmpty(this.position.funnelStages)) {
      this.position.merge({funnelStages: defaultFunnelStages()});
    }
    if (location === 'review' && this.position.isDraft) {
      this.position.merge({status: 'published'});
    }
  }

  @action async load(id) {
    this.position = await this.store.loadSingle(id);
    this.departments = this.store.getDepartments();
    this.locations = this.store.getLocations();
    this.users = this.store.getUsers();
    this.isNew = this.position.isDraft;
    await this.setEmailPreviews();
    await this.loadJobSite();

    this.errors = {};
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'details': return this.submitDetails();
      case 'description': return this.submitDescription();
      case 'questions': return this.submitQuestions();
      case 'rules': return this.submitRules();
      case 'settings': return this.submitSettings();
      case 'review': return this.publishPosition();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  async submitStep(props) {
    const position = _.pick(this.position, props);
    const {model, errors} = await this.store.patchPosition(position, this.position.id);

    this.errors = errors;
    if (!model) return false;

    this.position.update(model);
    return true;
  }

  @action moveStage(fromIndex, toIndex) {
    const dragStage = this.position.stages[fromIndex];
    const hoverStage = this.position.stages[toIndex];

    hoverStage.order = fromIndex;
    dragStage.order = toIndex;
  }

  @action addStage() {
    const newStageOrder = this.position.stages.length;

    this.position.funnelStages.push(new FunnelStage({
      name: '',
      order: newStageOrder
    }));

    this.position.finalStages.forEach(stage => stage.order += 1);
  }

  @action removeStage(stage) {
    this.position.funnelStages.remove(stage);

    const laterStages = _.filter(this.position.funnelStages, s => s.order > stage.order);
    for (const s of laterStages) {
      s.order -= 1;
    }
  }

  @action async submitDetails() {
    return this.submitStep([
      'title', 'department', 'location', 'locationType', 'employmentType',
      'minimumPayRate', 'maximumPayRate', 'payRateCurrency', 'payRateUnit', 'payRateFormat'
    ]);
  }

  @action updatePayRateFormat(payRateFormat) {
    this.position.merge({payRateFormat});

    if (payRateFormat === 'minimum_only' || payRateFormat === 'exact_amount') this.position.maximumPayRate = '';
    if (payRateFormat === 'maximum_only') this.position.minimumPayRate = '';
  }

  @action updateDescription(description) {
    this.position.contentSection.richText = description.html;
    this.position.contentSection.lexicalState = description.state;
  }

  @action async submitDescription() {
    return this.submitStep([
      'contentSection'
    ]);
  }

  @action moveQuestion(fromIndex, toIndex) {
    const dragQuestion = this.position.sortedQuestions[fromIndex];
    const hoverQuestion = this.position.sortedQuestions[toIndex];

    hoverQuestion.order = fromIndex;
    dragQuestion.order = toIndex;
  }

  @action addQuestion() {
    const newQuestion = new Question({
      name: '',
      order: this.position.questions.length,
      questionType: 'text_field'
    });
    this.editQuestion(newQuestion);
  }

  @action removeQuestion(question) {
    this.position.questions.remove(question);
  }

  @action async submitQuestions() {
    return this.submitStep([
      'questions', 'requireResume'
    ]);
  }

  @action removeRule(rule) {
    this.position.rules.remove(rule);
  }

  @action async submitRules() {
    return this.submitStep([
      'rules'
    ]);
  }

  @action async submitSettings() {
    return this.submitStep([
      'hiringCollaborators', 'funnelStages'
    ]);
  }

  @action goBack() {
    this.history.push(steps.before(this.currentStep).location);
  }

  @action async publishPosition() {
    const modelUpdated = await this.submitStep([
      'status',
      'sendEmailToApplicants',
      'sendDisqualifiedEmailToApplicants',
      'allowReferrals',
      'locale'
    ]);

    if (modelUpdated) {
      this.history.push('/positions');
      if (this.isNew) this.recruitingState.directoryState.openPublishModal(this.position);
    }
  }

  @action openConfirmationEmailModal() {
    this.emailPreviewModalOpen = true;
    this.previewModalTitle = 'recruiting.emails.confirmation.Confirmation email to applicants';
    this.emailPreview = this.confirmationPreview;
  }

  @action openDisqualifiedEmailModal() {
    this.emailPreviewModalOpen = true;
    this.previewModalTitle = 'recruiting.emails.disqualified.Disqualified email to applicants';
    this.emailPreview = this.disqualifiedPreview;
  }

  @action closeEmailPreviewModal() {
    this.emailPreviewModalOpen = false;
  }

  @action editQuestion(question) {
    this.editingQuestion = new Question(question);
    this.editQuestionModalOpen = true;
  }

  @action closeQuestionModal() {
    this.editQuestionModalOpen = false;
  }

  @action editRule(rule) {
    this.originalRule = rule;
    this.editingRule = new PositionRule(toJS(rule));
    this.editRuleModalOpen = true;
  }

  @action closeRuleModal() {
    this.errors = {};
    this.editRuleModalOpen = false;
  }

  @action openRequireResumeModal(question) {
    this.editRequireResumeModalOpen = true;
  }

  @action closeRequireResumeModal() {
    this.editRequireResumeModalOpen = false;
  }

  @action updateRequireResume(checked) {
    this.requireResume = checked;
  }

  @action saveRequireResume() {
    this.position.requireResume = this.requireResume;
    this.closeRequireResumeModal();
  }

  @action saveQuestion() {
    if (!this.validateQuestion()) return;

    const existingQuestion = _.find(this.position.questions, q => q.order === this.editingQuestion.order);

    if (existingQuestion) {
      existingQuestion.update(this.editingQuestion);
    } else {
      this.position.questions.push(this.editingQuestion);
    }
    this.closeQuestionModal();
  }

  @action onQuestionAdded(question) {
    this.position.questions.push(question);
  }

  @action onQuestionRemoved(question) {
    this.position.questions.remove(question);
  }

  @action validateQuestion() {
    this.errors = this.editingQuestion.validate();

    return _.isEmpty(this.errors);
  }

  @action toggleDisqualifier(disqualifier, checked) {
    if(checked) {
      this.editingRule.disqualifiers.push(disqualifier);
    } else {
      _.remove(this.editingRule.disqualifiers, d => d === disqualifier);
    }
  }

  @action updateRuleQuestion(question) {
    this.editingRule.question = question;
    this.editingRule.disqualifiers = [];
  }

  @action validateRule() {
    this.errors = this.editingRule.validate();

    return _.isEmpty(this.errors);
  }

  @action saveRule() {
    if (!this.validateRule()) return;

    if (this.originalRule) {
      this.originalRule.update(this.editingRule);
    } else {
      this.position.rules.push(this.editingRule);
    }
    this.closeRuleModal();
  }

  @action async setEmailPreviews() {
    await this.store.loadEmailPreview();

    this.confirmationPreview = this.store._getSingle(
      types.COMPANY_EMAIL_TEMPLATE, { emailType: 'ats_application' }
    );

    this.disqualifiedPreview = this.store._getSingle(
      types.COMPANY_EMAIL_TEMPLATE, { emailType: 'ats_disqualification' }
    );
  }

  @action async loadJobSite() {
    const jobSite = await this.store.loadJobSite();
    this.jobSite = jobSite;
  }

  @computed get availableCollaborators() {
    const all = this.users.map(u => new Collaborator({user: u}));
    return _.reject(all, u => _.includes(this.position.selectedCollaborators, u.user.id));
  }

  @computed get locationsWithDeleted() {
    return withDeleted(this.locations, _.get(this.position, 'location'));
  }

  @computed get departmentsWithDeleted() {
    return withDeleted(this.departments, _.get(this.position, 'department'));
  }

  @computed get missingFields() {
    if (!this.jobSite.indeedEnabled || this.position.location.id == null) {
      return [];
    }

    return _.chain(this.position.indeedLocationRequiredFields)
      .map(field => !this.position.location[field] && t(`recruiting.edit.steps.missing_fields.${field}`))
      .compact()
      .value();
  }

  @computed get defaultQuestions() {
    return [
      {
        name: 'recruiting.edit.steps.First Name',
        questionType: 'text_field',
      },
      {
        name: 'recruiting.edit.steps.Last Name',
        questionType: 'text_field',
      },
      {
        name: 'recruiting.edit.steps.Email',
        questionType: 'text_field',
      },
      {
        name: 'recruiting.edit.steps.Phone Number',
        questionType: 'text_field',
      },
      {
        name: 'recruiting.edit.steps.Resume',
        questionType: 'attachment',
        optional: !this.position.requireResume,
        onEdit: () => this.openRequireResumeModal()
      }
    ];
  }

  @computed get availableQuestions() {
    let usedQuestions = this.position.rules.map(rule => rule.question.id);
    if (this.editingRule.question) {
      usedQuestions = usedQuestions.filter(questionId => questionId !== this.editingRule.question.id);
    }

    const unusedQuestions = this.position.questions.filter(q => !usedQuestions.includes(q.id));
    const availableQuestions = unusedQuestions.filter(q => RULE_QUESTION_TYPES.includes(q.questionType));

    return availableQuestions.sort((a, b) => a.order - b.order);
  }

  @computed get availableDisqualifiers() {
    if (!this.editingRule.question) return [];

    const questionOptions = this.editingRule.question.options.map(o => o.value);
    if (!this.originalRule || this.originalRule.question.id !== this.editingRule.question.id) {
      return questionOptions;
    }

    return _.uniq(this.originalRule.disqualifiers.concat(questionOptions));
  }

  customLinksForRules() {
    return [
      {
        text: 'Edit',
        action: model => this.editRule(model)
      },
      {
        text: 'Remove',
        action: model => this.removeRule(model)
      }
    ];
  }

  @computed get showAddLink() {
    const validQuestions = this.position.questions.filter(q => RULE_QUESTION_TYPES.includes(q.questionType));
    return validQuestions.length > this.position.rules.length;
  }

  @computed get RULES_LOCALIZATION() {
    return {
      removeModal: {
        header: 'recruiting.edit.steps.Remove Disqualification Rule',
        subHeader: 'recruiting.edit.steps.Are you sure you want to remove this disqualification rule?',
        body: 'recruiting.edit.steps.Candidates already disqualified will not be modified.'
      },
      addModel: 'recruiting.edit.steps.+ Add a disqualification rule',
      emptyState: this.showAddLink ?
        'recruiting.edit.steps.No disqualification rules to display' :
        'recruiting.edit.steps.Disqualification rules require a multiple choice (single answer) question'
    };
  }
}

export default EditPositionState;
