import axios from 'axios';
import {formErrors} from 'shared/forms';
import _ from 'lodash';
import {resetSessionExpirationTimeout} from 'login/tools';
import {errorAlert} from 'shared/tools';
import {t} from 'shared/core';
/*global window*/
/*global $*/
/*global Intercom*/

let origin = window.location.origin;

if (!origin) {
  origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
}

const getCsrfToken = () => {
  return _.get(document.getElementsByName('csrf-token')[0], 'content');
};

const setCsrfToken = (token) => {
  return _.set(document.getElementsByName('csrf-token')[0], 'content', token);
};

const api = axios.create({
  baseURL: `${origin}/api/v1`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': getCsrfToken(),
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export function errorBody(errorText, includeContactUs = true) {
  const div = $('<div>').text(errorText);
  const contactUs = $('<a>')
    .addClass('white underline medium')
    .click(e => {e.preventDefault(); window.Intercom && window.Intercom('show');})
    .text(t('contact us'));
  return includeContactUs ? div.append(contactUs) : div;
}

api.interceptors.response.use(function (response) {
  const token = _.get(response, 'headers["x-csrf-token"]');
  if (token) {
    setCsrfToken(token);
    api.defaults.headers['X-CSRF-Token'] = token;
  }
  resetSessionExpirationTimeout();
  return response;
}, function (error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const status = _.get(error, 'response.status');
  switch (status) {
    case 422:
      error.formErrors = formErrors(error.response);
      break;
    case 401:
      window.location.reload();
      break;
    case 405:
      errorAlert({
        header: t("Disabled while impersonating"),
        body: errorBody(t("READ_ONLY_ERROR_BODY"), false)
      });
      break;
    default:
      errorAlert({
        header: t("Sorry, something went wrong."),
        body: errorBody(t("UNEXPECTED_ERROR_BODY", {contact_us: ' '}))
      });
      break;
  }
  return Promise.reject(error);
});

if (process.env.__DEV__) {
  const BACK_END_HOST = /http:\/\/localhost:(\d)+/g;
  const FRONT_END_HOST = 'http://localhost:' + (process.env.PORT || 3001);

  api.interceptors.response.use(function (response) {
    const newResponse = _.replace(
      JSON.stringify(response),
      BACK_END_HOST,
      FRONT_END_HOST
    );
    return JSON.parse(newResponse);
  });
}

export default api;
