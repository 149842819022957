import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Label} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import PayRate from './PayRate';
import EmploymentRecordCustomFieldGroups from './EmploymentRecordCustomFieldGroups';
import ChangedBy from './ChangedBy';
import {auth} from 'shared/core';

const StartDateLabel = observer(({isInitial}) => {
  if (isInitial && auth.moduleEnabled('directory')) {
    return <FormattedMessage id='employees.profile.role.Start Date'/>;
  } else if (isInitial && !auth.moduleEnabled('directory')) {
    return <FormattedMessage id='employees.profile.role.Enrolment Date'/>;
  } else {
    return <FormattedMessage id='employees.profile.role.Effective Date'/>;
  }
});

const EmploymentRecordFields = observer(({uiState, viewModel}) => {
  const {record} = viewModel;
  const {parentState} = uiState;

  return (
    <div className='col col-12 sm-col-10 pt1'>
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'>
          <StartDateLabel isInitial={record.initial}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'effectiveDate'} render={value => calendarDate(value)} />
        </div>
      </div>}
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Employment Type'/></div>
        <div className='col col-6'>
          <Label model={record} path={'employmentTypeView'} translate={true}/>
        </div>
      </div>}
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Job Title'/></div>
        <div className='col col-6'>
          <Label model={record} path={'jobTitle'} />
        </div>
      </div>}
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Location'/></div>
        <div className='col col-6'>
          <Label model={record} path={'location.name'} />
        </div>
      </div>}
      {auth.moduleEnabled('directory') && parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Department'/></div>
        <div className='col col-6'>
          <Label model={record} path={'department.name'} />
        </div>
      </div>}
      {auth.moduleEnabled('directory') && parentState.viewDataPermission('::ROLE_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Manager'/></div>
        <div className='col col-6'>
          <Label model={record} path={'manager.name'} />
        </div>
      </div>}
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Salary (or pay rate)'/></div>
        <div className='col col-6'><PayRate record={record}/></div>
      </div>}
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Pay Frequency'/></div>
        <div className='col col-6'>
          <Label model={record} path={'payFrequency'} translate={true} />
        </div>
      </div>}
      {parentState.viewDataPermission('::ROLE_INFORMATION') &&
        <div className='clearfix pb1'>
          <div className='col col-6'><FormattedMessage id='employees.profile.role.Hours worked per week'/></div>
          <div className='col col-6'>
            <Label model={record} path={'hoursPerWeek'}/>
          </div>
        </div>
      }
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Employee earns commission'/></div>
        <div className='col col-6'>
          <Label model={record} path={'commissionView'} translate={true} />
        </div>
      </div>}
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && record.commission && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Commission Details'/></div>
        <div className='col col-6'>
          <Label model={record} path={'commissionStructure'} />
        </div>
      </div>}
      {record.commission && parentState.viewDataPermission('::COMPENSATION_INFORMATION') &&
        auth.company.benefitsEnabled && <div className='clearfix pb1'>
          <div className='col col-6'><FormattedMessage id='employees.profile.role.Estimated Yearly Commission'/></div>
          <div className='col col-6'>
            <Label model={record} path={'annualizedCommissionFormatted'} />
          </div>
        </div>
      }
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') &&
        auth.company.benefitsEnabled && <div className='clearfix pb1'>
          <div className='col col-6'><FormattedMessage id='employees.profile.role.Yearly Insurable Earnings'/></div>
          <div className='col col-6'>
            <Label model={viewModel} path={'insurableEarningsLabel'} />
          </div>
        </div>
      }
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Employee earns a bonus'/></div>
        <div className='col col-6'>
          <Label model={record} path={'bonusView'} translate={true} />
        </div>
      </div>}
      {parentState.viewDataPermission('::COMPENSATION_INFORMATION') && record.bonus &&  <div className='clearfix pb1'>
        <div className='col col-6'><FormattedMessage id='employees.profile.role.Bonus Details'/></div>
        <div className='col col-6'>
          <Label model={record} path={'bonusStructure'} />
        </div>
      </div>}
      {parentState.viewDataPermission('::ROLE_INFORMATION') && uiState.customFieldsForRecord(record).length > 0 &&
        <div className='pb1'>
          <EmploymentRecordCustomFieldGroups record={record} uiState={uiState}/>
        </div>
      }
    </div>
  );
});

const EmploymentRecord = observer(({uiState, viewModel}) => {
  const {parentState} = uiState;

  return (
    <div className='clearfix pt2'>
      <EmploymentRecordFields uiState={uiState} viewModel={viewModel}/>
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <ChangedBy viewModel={viewModel}/>}
    </div>
  );
});

export default EmploymentRecord;
