import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {QuickTip, Panel, Button, AnswerResults} from 'components';
import {FormattedMessage} from 'react-intl';
import {absoluteDateFormatter, absoluteTimestampDate} from 'shared/tools';
import ReviewNotes from 'containers/performance_reviews/components/reviews/components/view/components/review_details/components/ReviewNotes';

const ReviewReader = observer(({title, description, review, onUnsubmit}) => {
  return (
    <Panel className='print-page-break-always'>
      {review.reviewee && review.reviewee.sharedAt && review.isManagerReview &&
        <div className='mb2'>
          <QuickTip
            trait='info'
            paragraphs={review.submitted ?
              [<FormattedMessage
                id='performance_reviews.view.SHARED_WITH_MESSAGE'
                values={{
                  sharedAt: absoluteDateFormatter(review.reviewee.sharedAt),
                  employeeName: review.reviewee.employee.name
                }}
              />] :
              [<FormattedMessage
                id='performance_reviews.view.This review has been closed and can no longer be submitted'
              />]
            }
          />
        </div>
      }
      <Panel.Header title={title}/>
      <div className='h4 jumbo mb2 print-hide'>
        {description && <i className='material-icons h3 align-middle waterloo mr1'>{'visibility'}</i>}
        {description}
      </div>
      <AnswerResults answers={review.sortedAnswers} />
      {review.canUnsubmit && <div className='mt3 right-align print-hide'>
        <span className='waterloo'>
          <FormattedMessage
            id='performance_reviews.view.SUBMITTED'
            values={{
              submittedAt: absoluteTimestampDate(review.submittedAt)
            }}
          />
        </span>
        {onUnsubmit && <Button onClick={() => onUnsubmit(review)} className='ml2'>
          <FormattedMessage id={auth.employee.id === review.reviewer.id ? 'performance_reviews.view.UNSUBMIT' : 'performance_reviews.view.UNSUBMIT_FOR_EMPLOYEE'}/>
        </Button>}
      </div>}
      <ReviewNotes review={review} />
    </Panel>
  );
});

export default ReviewReader;
