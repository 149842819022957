import React from 'react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {UploadFiles, FormLayout, Input, DatePicker, FormError} from 'components';
import {observable} from 'mobx';
import {auth} from 'shared/core';

const SinFields = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <FormLayout>
      <Input value={editEmployee.sin}
        label='employees.profile.banking.Social Insurance Number (SIN)'
        errorMessage={_.get(errors, 'sin')}
        onChange={(e) => editEmployee.merge({sin: e.target.value})}
      />
      <DatePicker disabled={!_.startsWith(editEmployee.sin, '9')}
        value={_.startsWith(editEmployee.sin, '9') ? editEmployee.sinExpiryDate : ''}
        label='employees.profile.banking.Date of Expiry (SIN)'
        errorMessage={_.get(errors, 'sinExpiryDate')}
        onChange={date => editEmployee.merge({sinExpiryDate: date})}
      />
    </FormLayout>
  );
});

const BankingFields = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;
  const {bankAccount} = editEmployee;

  return (
    <FormLayout>
      <Input value={bankAccount.nickname}
        label='employees.profile.banking.Bank Account Nickname'
        errorMessage={_.get(errors, 'bankAccount.nickname')}
        onChange={(e) => bankAccount.merge({nickname: e.target.value})}
      />
      <Input value={bankAccount.transitNumber}
        label='employees.profile.banking.Transit Number'
        errorMessage={_.get(errors, 'bankAccount.transitNumber')}
        onChange={(e) => bankAccount.merge({transitNumber: e.target.value})}
      />
      <Input value={bankAccount.institutionNumber}
        label='employees.profile.banking.Institution Number'
        errorMessage={_.get(errors, 'bankAccount.institutionNumber')}
        onChange={(e) => bankAccount.merge({institutionNumber: e.target.value})}
      />
      <Input value={bankAccount.accountNumber}
        label='employees.profile.banking.Account Number'
        errorMessage={_.get(errors, 'bankAccount.accountNumber')}
        onChange={(e) => bankAccount.merge({accountNumber: e.target.value})}
      />
      {auth.company.payrollEnabled && <div>
        <FormattedMessage id='employees.profile.banking.Upload a cheque'/>
        <UploadFiles
          defaultFiles={bankAccount.cheque ? observable([bankAccount.cheque]) : observable([])}
          onChange={(files) => uiState.updateFile(files)}
          maxFiles={1}
          accept={['image/*', 'application/pdf']}
          errorMessage={_.get(errors, 'bankAccount.cheque')}
          modelType='bank_account/cheque'
        />
      </div>}
    </FormLayout>
  );
});

const EditBankingInfoModal = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <Modal isOpen={uiState.bankingInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'>
        <FormattedMessage id='employees.profile.banking.Edit Banking Information'/>
      </div>
      <form>
        {auth.company.sinEnabled && <SinFields uiState={uiState}/>}
        {auth.company.bankingEnabled && <BankingFields uiState={uiState}/>}
        <FormError message={_.get(errors, 'base')}/>
        <ModalButtons onSave={() => uiState.saveBankingInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditBankingInfoModal;
