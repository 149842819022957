import {DomainObject} from 'shared/store';
import {computed, observable} from 'mobx';
import {dateSpan} from 'shared/tools';

class NmbrPayStub extends DomainObject {
  @observable periodStart;
  @observable periodEnd;
  @observable id;

  @computed get dateRange() {
    return dateSpan(this.periodStart, this.periodEnd, true);
  }
}

export default NmbrPayStub;
