import _ from 'lodash';
import {action, computed, observable, observe} from 'mobx';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import EmployeeCustomField from 'stores/employee_custom_fields/EmployeeCustomField';
import {redirect, successAlert, openFileStack, transliterate} from 'shared/tools';
import {auth, t, endpoints, types, api} from 'shared/core';
import {DomainStore, fetchModels} from 'shared/store';
import countryStore from 'stores/countries/CountryStore';
import {Employee, OnLeaveRecord, TerminationRecord} from 'stores/employees';
import {maskValue} from 'shared/tools';
import DocumentAction from 'stores/documents/DocumentAction';
/*global $*/

const INVITE_BANNER_PARAMS = {
  invite_skipped: {
    trait: 'caution',
    text: 'employees.profile.STATUS_BANNER.INVITE_SKIPPED_MESSAGE',
    buttonText: 'employees.profile.Edit & Send'
  },
  invite_not_sent: {
    trait: 'caution',
    text: 'employees.profile.STATUS_BANNER.INVITE_NOT_SENT_MESSAGE',
    buttonText: 'employees.profile.Edit Invite'
  },
  invite_not_sent_after_company_launch: {
    trait: 'caution',
    text: 'employees.profile.STATUS_BANNER.INVITE_NOT_SENT_MESSAGE',
    buttonText: 'employees.profile.Edit & Send'
  },
  invite_scheduled: {
    trait: 'success',
    text: 'employees.profile.STATUS_BANNER.INVITE_SCHEDULED_MESSAGE',
    buttonText: 'employees.profile.Edit Invite'
  },
  invite_sent: {
    trait: 'success',
    text: 'employees.profile.STATUS_BANNER.INVITE_SENT_MESSAGE',
    buttonText: 'employees.profile.Edit & Resend'
  },
  onboarding: {
    trait: 'success',
    text: 'employees.profile.STATUS_BANNER.ONBOARDING'
  },
  terminated: {
    trait: 'default',
    text: 'employees.profile.STATUS_BANNER.TERMINATED',
    placeholderText: 'employees.profile.STATUS_BANNER.TERMINATED_PLACEHOLDER',
    buttonText: 'employees.profile.View Details'
  },
  on_leave: {
    trait: 'default',
    text: 'employees.profile.STATUS_BANNER.ON_LEAVE',
    placeholderText: 'employees.profile.STATUS_BANNER.ON_LEAVE_PLACEHOLDER',
    buttonText: 'employees.profile.View Details'
  }
};

class EmployeeProfileState {
  store = new DomainStore();
  countryStore = countryStore;
  history;
  match;

  @observable employee;
  @observable editEmployee;
  @observable editingCustomField = {};
  @observable errors = {};
  @observable inviteModalOpen = false;
  @observable basicInfoModalOpen = false;
  @observable addressInfoModalOpen = false;
  @observable contactInfoModalOpen = false;
  @observable bankingInfoModalOpen = false;
  @observable bankingChequeHelperOpen = false;
  @observable emergencyContactModalOpen = false;
  @observable editCustomFieldModalOpen = false;
  @observable removeDocumentModalOpen = false;
  @observable currentDocumentAction;
  @observable removeBankInfoStepModalOpen = false;
  @observable deleteConfirmationName;

  @observable documentActions = [];
  @observable countries = [];
  @observable regions = [];
  @observable employeeCalendars = [];
  @observable calendarSubscriptions = [];

  @observable currentRecord;
  @observable currentRecordLoaded = false;

  @observable bankingInformationHidden = true;

  @observable directReports = [];
  @observable directReportsCurrentPage = 1;
  @observable directReportsPageSize = 10;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(this.match.params.id)
    ]);

    this.employee = new EmployeeDetails(
      this.store._getSingle(types.EMPLOYEE_DETAILS, {id: this.match.params.id})
    );
    if (!this.employee.onboardingComplete && auth.hasAccess('::HIRE_EMPLOYEES') && auth.moduleEnabled('directory')) {
      const documentActions = await fetchModels(
        endpoints.DOCUMENT_ACTIONS.withEmployee(this.match.params.id),
        types.DOCUMENT_ACTION
      );
      this.documentActions = documentActions.map(model => new DocumentAction(model));
    }
    this.countries = await this.countryStore.loadCountries();
    this.editEmployee = new EmployeeDetails(this.employee);
    if (this.employee.countryCode) {
      await this.refreshRegions(this.employee.countryCode);
    }

    if (_.get(this.employee, 'links.directReports')) {
      const directReports = await fetchModels(
        this.employee.link('directReports'),
        types.EMPLOYEE
      );
      this.directReports = directReports.map(model => new Employee(model));
    }

    await this.loadCalendars();
    this.employeeCalendars = this.store._getAll(types.EMPLOYEE_CALENDAR);
    this.calendarSubscriptions = this.store._getAll(types.CALENDAR_SUBSCRIPTION);

    await this.redirectToHireFlowIfDraft();
    observe(this.employee, 'employmentStatus', () => this.loadCurrentRecord(),  true);
  }

  @action async redirectToHireFlowIfDraft() {
    if (!this.employee.draft) return null;

    return redirect(`/employees/hire/${this.employee.id}`);
  }

  @action async refreshRegions(countryCode) {
    this.regions = await this.countryStore.getOrLoadRegions(countryCode);
  }

  @action async loadCalendars() {
    if (auth.company.enrolmentOnly || !this.userIsEmployee) return;

    await this.store._compose([
      endpoints.CALENDAR_SUBSCRIPTIONS,
      endpoints.EMPLOYEE_CALENDARS
    ]);
  }

  @action openBasicInfoModal() {
    this.resetModals();
    this.basicInfoModalOpen = true;
  }

  @action openAddressInfoModal() {
    this.resetModals();
    this.addressInfoModalOpen = true;
  }

  @action openContactInfoModal() {
    this.resetModals();
    this.contactInfoModalOpen = true;
  }

  @action openBankingInfoModal() {
    this.resetModals();
    this.bankingInfoModalOpen = true;
  }

  @action openBankingChequeHelper() {
    this.resetModals();
    this.bankingChequeHelperOpen = true;
  }

  @action openEmergencyContactModal() {
    this.resetModals();
    this.emergencyContactModalOpen = true;
  }

  @action openCustomFieldModal(customField) {
    this.editingCustomField = new EmployeeCustomField(customField);
    this.editCustomFieldModalOpen = true;
  }

  @action showInviteModal() {
    this.resetModals();
    this.inviteModalOpen = true;
  }

  @action async openRemoveBankInfoStepModal() {
    this.resetModals();
    this.removeBankInfoStepModalOpen = true;
  }

  @action async confirmRemoveBankInfoStep() {
    const employee = this.employee.pick(['collectPayroll', 'collectSin']);
    employee.collectPayroll = false;
    employee.collectSin = false;
    this.resetModals();
    const {model} = await this.store.patch(employee);
    if (model) {
      this.employee.merge(model);
    }
  }

  @action async openRemoveDocumentModal(documentAction) {
    this.currentDocumentAction = documentAction;
    this.resetModals();
    this.removeDocumentModalOpen = true;
  }

  @action async confirmRemoveDocument() {
    await this.store.destroy(this.currentDocumentAction);
    await this.load();
    this.resetModals();
  }

  @action trackProgressModal() {
    this.resetModals();

    const url = this.employee.link('onboardProgressDialog');

    $.ajax({
      url,
      method: 'get',
      success: function (data) {
        $('main').append(data);
        $('.js-employee-details-modal').modal('show');
        $('.js-employee-details-modal').on('hidden.bs.modal', function (e) {
          $(e.target).remove();
        });
      }
    });
  }

  @action goToJobTab() {
    this.history.push(`/${this.match.params.id}/job`);
  }

  @action handleActionForStatus(status) {
    switch (status) {
      case 'invite_skipped':
      case 'invite_not_sent':
      case 'invite_not_sent_after_company_launch':
      case 'invite_scheduled':
      case 'invite_sent':
        return this.showInviteModal();
      case 'onboarding':
        return this.trackProgressModal();
      case 'terminated':
      case 'on_leave':
        return this.goToJobTab();
      default:
        throw new Error(`Unsupported status of ${status}`);
    }
  }

  @action resetModals() {
    this.inviteModalOpen = false;
    this.basicInfoModalOpen = false;
    this.addressInfoModalOpen = false;
    this.contactInfoModalOpen = false;
    this.bankingInfoModalOpen = false;
    this.bankingChequeHelperOpen = false;
    this.emergencyContactModalOpen = false;
    this.editCustomFieldModalOpen = false;
    this.removeDocumentModalOpen = false;
    this.removeBankInfoStepModalOpen = false;
    this.editEmployee = new EmployeeDetails(this.employee);
  }

  @action async saveBasicInfo() {
    return this._patchEmployee([
      'firstName',
      'middleName',
      'lastName',
      'preferredName',
      'preferredLastName',
      'gender',
      'customGender',
      'birthDate'
    ]);
  }

  @action async saveAddress() {
    return this._patchEmployee([
      'homeAddress1',
      'homeAddress2',
      'countryCode',
      'regionCode',
      'city',
      'postalCode'
    ]);
  }

  @action async saveContactInfo() {
    return this._patchEmployee([
      'workEmail',
      'personalEmail',
      'workPhone',
      'workPhoneExtension',
      'personalPhone'
    ]);
  }

  @action async saveEmergencyContactInfo() {
    return this._patchEmployee([
      'ec1Name',
      'ec1Relationship',
      'ec1Phone',
      'ec2Name',
      'ec2Relationship',
      'ec2Phone'
    ]);
  }

  @action async saveBankingInfo() {
    const {model, errors} = await this.store.patch(
      endpoints.BANKING_INFO.with(this.editEmployee.id),
      types.EMPLOYEE_DETAILS,
      this.editEmployee
    );
    this.errors = errors;

    if (model) {
      this.employee.update(model);
      this.resetModals();
    }
  }

  @action async saveInviteEmail() {
    return this._patchEmployee([
      'invitationEmail',
      'editAndResendInvitationEmail'
    ]);
  }

  @action async _patchEmployee(fields) {
    const employee = this.editEmployee.pick(fields);
    const {model, errors} = await this.store.patch(employee);
    this.errors = errors;

    if (model) {
      if(this.editEmployee.countryCode !== this.employee.countryCode){
        await this.refreshRegions(this.editEmployee.countryCode);
      }
      this.employee.update(model);
      this.resetModals();
    }
  }

  @action async saveCustomField() {
    this.editEmployee.employeeCustomFields = [this.editingCustomField];
    await this._patchEmployee([
      'employeeCustomFields'
    ]);
  }

  @action async saveAndSendInviteEmail() {
    this.editEmployee.editAndResendInvitationEmail = true;
    await this.saveInviteEmail();
    this.editEmployee.editAndResendInvitationEmail = false;

    if (!_.isEmpty(this.errors)) return;

    await this.sendInvite(this.employee.id);
    await this.load();
    successAlert(t('employees.profile.Employee invite has been sent!'));
  }

  @action async uploadAvatar() {
    openFileStack(
      { accept: ['image/*'],
        storeTo: { path: '/avatars/' },
        transformations: { crop: { aspectRatio: 1, force: true }, rotate: true },
        imageMax: [500, 500],
      }, (file) => this.__saveAvatar(file)
    );
  }

  @action async __saveAvatar(file) {
    this.editEmployee.merge({avatar: file});
    await this._patchEmployee([
      'avatar'
    ]);
  }

  @action showBankingInformation() {
    this.bankingInformationHidden = false;
  }

  @action hideBankingInformation() {
    this.bankingInformationHidden = true;
  }

  @action removeFile() {
    this.editEmployee.bankAccount.cheque = null;
  }

  @action mergeFile(file) {
    this.editEmployee.bankAccount.merge({cheque: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @action async deleteEmployee() {
    await this.store.destroy(this.employee);
    successAlert(t('employees.profile.delete_employee.Employee successfully deleted'));
    redirect('/employees');
  }

  @action updateDirectReportsPagination(pagination) {
    this.directReportsCurrentPage = pagination.currentPage;
  }

  @computed get directReportsPagination() {
    return {
      currentPage: this.directReportsCurrentPage,
      totalPages: Math.ceil(this.directReports.length / this.directReportsPageSize),
      totalCount: this.directReports.length,
      pageSize: this.directReportsPageSize
    };
  }

  @computed get paginatedDirectReports() {
    return this.directReports.slice(
      (this.directReportsCurrentPage - 1) * this.directReportsPageSize,
      this.directReportsCurrentPage * this.directReportsPageSize
    );
  }

  @computed get customGenderVisible() {
    return this.editEmployee.gender === 'other';
  }

  @computed get countryView() {
    return _.get(_.find(this.countries, { code: this.employee.countryCode }), 'name');
  }

  @computed get regionView() {
    return _.get(_.find(this.regions, { code: this.employee.regionCode }), 'name');
  }

  @computed get adminOrMe() {
    return auth.user.admin || this.userIsEmployee;
  }

  @computed get userIsEmployee() {
    const currentEmployeeId = _.get(auth, 'employee.id');
    return currentEmployeeId && currentEmployeeId === this.employee.id;
  }

  @computed get employeeLocationAndJobTitle() {
    return `${this.employee.location.name}  ·  ${t(this.employee.employmentType)}`;
  }

  @computed get canViewOnboardingStatus() {
    return auth.hasAccess('::HIRE_EMPLOYEES');
  }

  @computed get canViewRoleInformation() {
    return this.viewDataPermission('::ROLE_INFORMATION');
  }

  @computed get userIsAdmin() {
    return auth.user.admin;
  }

  @computed get statusParams() {
    if (auth.employee.id === this.employee.id) return [];

    return _.chain(this.employee.statusTags)
      .filter(s => this.canActionStatus(s))
      .map(s => {
        const params = INVITE_BANNER_PARAMS[s];

        if (!this.currentRecordLoaded && params.placeholderText) {
          params.message = params.placeholderText;
        } else {
          params.message = params.text;
        }

        params.action = [{
          caption: t(params.buttonText),
          onClick: () => this.handleActionForStatus(s)
        }];

        return params;
      })
      .value();
  }

  @computed get tabs() {
    return {
      job: this.viewDataPermission('::ROLE_INFORMATION') || this.viewDataPermission('::COMPENSATION_INFORMATION'),
      timeOff: auth.moduleEnabled('pto') &&
        (auth.hasAccess('::MANAGE_TIME_OFF') || (this.viewDataPermission('::TIME_OFF') && this.employee.hasTimeOff)),
      banking: this.viewDataPermission('::BANKING_INFORMATION') && (auth.company.bankingEnabled || auth.company.sinEnabled),
      payroll: this.employee.nmbrPayrollEnabled && auth.company.showNmbrPayroll && (auth.hasAccess('::PAYROLL') || this.userIsEmployee),
      emergencyContact: this.viewDataPermission('::EMERGENCY_CONTACT_INFORMATION'),
      documents: this.viewDataPermission('::DOCUMENTS'),
      notes: this.viewDataPermission('::NOTES'),
      calendar: this.hasCalendars && this.userIsEmployee,
      onboarding: auth.hasAccess('::MANAGE_ONBOARDING')
    };
  }

  @computed get deleteConfirmationMatch() {
    return transliterate(this.deleteConfirmationName) === transliterate(this.employee.name);
  }

  @computed get hasCalendars() {
    return this.employeeCalendars.length > 0 || this.calendarSubscriptions.length > 0;
  }

  canActionStatus(status) {
    switch (status) {
      case 'invite_skipped':
      case 'invite_not_sent':
      case 'invite_not_sent_after_company_launch':
      case 'invite_scheduled':
      case 'invite_sent':
      case 'onboarding':
        return this.canViewOnboardingStatus && !this.employee.terminated;
      case 'on_leave':
      case 'terminated':
        return this.canViewRoleInformation;
      default:
        return false;
    }
  }

  async sendInvite(id) {
    return api.post(endpoints.EMPLOYEE.SEND_INVITE.with(id));
  }

  async loadCurrentRecord() {
    if (!this.canViewRoleInformation || auth.employee.id === this.employee.id) {
      this.currentRecordLoaded = true;
      return null;
    }

    if (this.employee.terminated || this.employee.onLeave) {
      await this.store._compose([
        endpoints.EMPLOYEE.CURRENT_EMPLOYMENT_RECORD.with(this.employee.id)
      ]);

      this.currentRecord = this.initializeCurrentRecord();
    }

    this.currentRecordLoaded = true;
  }

  initializeCurrentRecord() {
    switch (this.employee.employmentStatus) {
      case 'terminated':
        return new TerminationRecord(this.store._getSingle(types.TERMINATION_RECORD));
      case 'on_leave':
        return new OnLeaveRecord(this.store._getSingle(types.ON_LEAVE_RECORD));
      default:
        throw new Error(`Unsupported employment_status of ${this.employee.employmentStatus}`);
    }
  }

  viewDataPermission(permission) {
    return !!this.employee.accessLevel(permission);
  }

  editDataPermission(permission) {
    return this.employee.accessLevel(permission) === 'w';
  }

  formatMaskedValue(value, isHidden) {
    if (isHidden) {
      return maskValue(value);
    } else {
      return value;
    }
  }
}

export default EmployeeProfileState;
