import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {calendarDate, dateSpan} from 'shared/tools';
import {QuickTip, Table} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const visibleColumns = (fieldUpdates) => {
  const cols = new Set();

  fieldUpdates.forEach(fieldUpdate => {
    Object.entries(fieldUpdate).forEach(([hourType, hours]) => {
      if (hours !== null) cols.add(hourType);
    });
  });

  return cols;
};

const columns = (visibleColumns) => {
  const cols = [{
    header: 'nmbr.updates.directory.Effective Period',
    width: 3,
    render: model => dateSpan(model.startDate, model.endDate)
  }];

  if (visibleColumns.has('regular')) {
    cols.push({
      header: 'nmbr.updates.directory.Regular',
      width: 2,
      attribute: 'regular'
    });
  }
  if (visibleColumns.has('overtime')) {
    cols.push({
      header: 'nmbr.updates.directory.Overtime',
      width: 1.5,
      attribute: 'overtime'
    });
  }
  if (visibleColumns.has('doubleOvertime')) {
    cols.push({
      header: 'nmbr.updates.directory.Double Overtime',
      width: 1.5,
      attribute: 'doubleOvertime'
    });
  }
  if (visibleColumns.has('holiday')) {
    cols.push({
      header: 'nmbr.updates.directory.Holiday',
      width: 2,
      attribute: 'holiday'
    });
  }
  if (visibleColumns.has('vacation')) {
    cols.push({
      header: 'nmbr.updates.directory.Paid Time Off',
      width: 2,
      attribute: 'vacation'
    });
  }

  const unusedWidth = 12 - _.sumBy(cols, 'width');
  _.last(cols).width += unusedWidth;

  return cols;
};

const SyncedHoursTable = observer(({nmbrUpdate}) => {
  if (!(nmbrUpdate.hasFieldUpdate('synced_hours') || nmbrUpdate.hasFieldUpdate('retroactive_synced_hours'))) return null;

  return (
    <div className='space-y'>
      <div className='h3 medium'><FormattedMessage id='nmbr.updates.directory.Hours Worked'/></div>
      {nmbrUpdate.hasFieldUpdate('synced_hours') && <Table
        columns={columns(visibleColumns(nmbrUpdate.fieldUpdatesForType('synced_hours')))}
        models={nmbrUpdate.fieldUpdatesForType('synced_hours')}
        showLinks={false}
      />}
        {nmbrUpdate.hasFieldUpdate('retroactive_synced_hours') && <QuickTip
          trait='caution'
          header={t('nmbr.updates.directory.Changes Not Applied')}
          paragraphs={[t(
            'nmbr.updates.directory.CHANGES_NOT_SYNCED_WARNING',
            {
              effectiveDate: calendarDate(nmbrUpdate.fieldUpdatesForType('retroactive_synced_hours')[0].effectiveDate),
              cutoffDate: calendarDate(nmbrUpdate.fieldUpdatesForType('retroactive_synced_hours')[0].cutoffDate)
            }
          )]}
        />}
    </div>
  );
});

export default SyncedHoursTable;
