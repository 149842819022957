import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import {withState} from 'shared/core';
import NmbrPageState from '../state/NmbrPageState';
import {NmbrPayrollContainer} from './manage';
import {NmbrEmployeesDirectory} from './employees';
import {NmbrUpdatesDirectory} from './updates';

const NmbrPage = observer(({uiState}) => {
  return (
    <Switch>
      <Route exact path='/' render={() => <NmbrPayrollContainer/>}/>
      <Route path='/employees' render={() => <NmbrEmployeesDirectory/>}/>
      <Route path='/updates' render={() => <NmbrUpdatesDirectory/>}/>
    </Switch>
  );
});

export default withState(NmbrPage, NmbrPageState);
