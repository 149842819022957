import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const PositionSummary = injectIntl(observer(({position}) => {
  const locationString = position.locationString ? `${position.locationString} | ` : '';
  const remoteString = position.remoteString ? `${position.remoteString} | ` : '';

  return (
    <span>
      {`${locationString}${remoteString}${position.departmentView} | `} <FormattedMessage id={position.employmentType}/>
    </span>
  );
}));

export default PositionSummary;
