import React from 'react';

const EmojiTitle = ({Emoji, emojiKey, title}) => {
  return (
    <div className='flex align-items-middle'>
      {Emoji && <div className='flex mr1'>
        <Emoji emoji={emojiKey} size={22}/>
      </div>}
      {title}
    </div>
  );
};

export default EmojiTitle;
