import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, t} from 'shared/core';
import {TimeTrackingPaySchedule} from 'stores/time_tracking';
import {InteractiveTable, Tag} from 'components';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const Name = observer(({model, uiState}) => {
  return (
    <span>
      <div className='table-cell align-middle'>
        <Link to={uiState.paySchedulePath(model)}>
          {model.name}
        </Link>
      </div>
      <div className='table-cell align-middle pl1'>
        {model.readOnly && <Tag className='ml1' colour='meadow'>
          <FormattedMessage id='time_tracking.manage.pay_schedules.directory.In Use'/>
        </Tag>}
      </div>
    </span>
  );
});

const COLUMNS = [
  {
    header: 'time_tracking.manage.pay_schedules.directory.Pay schedule name',
    width: 11,
    component: Name
  }
];

const LOCALIZATION = {
  emptyState: 'time_tracking.manage.pay_schedules.directory.No pay schedules to display',
  removeModal: {
    header: 'time_tracking.manage.pay_schedules.directory.Remove Pay Schedule?',
    subHeader: 'time_tracking.manage.pay_schedules.directory.Are you sure you want to remove this pay schedule?'
  }
};

const PayScheduleDirectory = observer(({uiState}) => {
  return (
    <InteractiveTable
      title={t('time_tracking.manage.Pay Schedules')}
      uiState={uiState}
      searchable={false}
      columns={COLUMNS}
      onMount={agent => uiState.setPayScheduleInteractiveAgent(agent)}
      localization={LOCALIZATION}
      onRemove={paySchedule => uiState.removePaySchedule(paySchedule)}
      onEdit={paySchedule => uiState.openEditPayScheduleModal(paySchedule)}
      proxy={{
        type: 'async',
        modelType: types.TIME_TRACKING.PAY_SCHEDULE,
        model: TimeTrackingPaySchedule,
        endpoint: endpoints.TIME_TRACKING.PAY_SCHEDULES.DIRECTORY
      }}>
    </InteractiveTable>
  );
});

export default PayScheduleDirectory;
