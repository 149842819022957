import React from 'react';
import {observer} from 'mobx-react';
import TimesheetEditor from 'containers/time_tracking/components/TimesheetEditor';
import {auth, withState} from 'shared/core';
import {Layout} from 'components';
import MyTimesheetState from 'containers/time_tracking/components/my_timesheet/state/MyTimesheetState';
import _ from 'lodash';

const MyTimesheetContainer = observer(({uiState}) => {
  const {sortedPayPeriods, selectedPayPeriod, projects} = uiState;

  if (_.isEmpty(sortedPayPeriods)) return null;

  return (
    <div>
      <Layout>
        <TimesheetEditor
          payPeriods={sortedPayPeriods}
          employee={auth.employee}
          projects={projects}
          payPeriod={selectedPayPeriod}
          onPayPeriodSelected={(payPeriod) => uiState.selectPayPeriod(payPeriod)}
        />
      </Layout>
    </div>
  );
});

export default withState(MyTimesheetContainer, MyTimesheetState);
