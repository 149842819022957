import {observable, action}  from 'mobx';
import {DomainObject} from 'shared/store';

class CalendarEvent extends DomainObject {
  @observable title
  @observable start;
  @observable end;
  @observable allDay;
  @observable extendedProps;

  constructor(event) {
    super(event);
    this.setEventClasses();
  }

  @action setEventClasses() {
    this.backgroundColor = this.extendedProps.status === 'approved' ? '#1776F5' : '#FFFFFF';
    this.textColor = this.extendedProps.status === 'approved' ? '#FFFFFF' : '#1776F5';
    this.borderColor = '#1776F5';
  }
}

export default CalendarEvent;
