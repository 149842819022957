import React from 'react';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import {Display, Layout, Panel} from 'components';
import {auth, withState, t} from 'shared/core';
import useModal from 'shared/hooks/useModal';
import {CalendarState} from '../state';
import EventDetailsModal from './EventDetailsModal';

const Calendar = observer(({uiState}) => {
  const {FullCalendar, dayGridPlugin, timeGridPlugin, frLocale, events, isLoading} = uiState;

  const eventDetailsModalOpen = useModal(uiState, 'EventDetailsModal', 'selectedEvent');

  return (
    <div>
      <Display title={t('calendar.Calendar')}/>
      <Layout>
        <Panel className='relative'>
          {isLoading && <div className='CalendarOverlay'></div>}
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView='dayGridMonth'
            events={toJS(events)}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            dayMaxEvents
            eventClick={auth.hasAccess('::MANAGE_TIME_OFF') && ((info) => uiState.openEventDetailsModal(info.event))}
            datesSet={(info) => uiState.fetchEvents(info.start.toISOString(), info.end.toISOString())}
            locale={auth.locale === 'fr' && frLocale}
          />
        </Panel>
        <EventDetailsModal uiState={uiState} modalOpen={eventDetailsModalOpen}/>
      </Layout>
    </div>
  );
});

export default withState(Calendar, CalendarState);
