import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {CalendarEvent} from 'stores/calendar_events';

class CalendarState {
  FullCalendar;
  dayGridPlugin;
  timeGridPlugin;
  frLocale;

  @observable events = [];
  @observable isLoading = false;
  @observable selectedEvent = {};

  @action async load() {
    const [FullCalendar, dayGridPlugin, timeGridPlugin, frLocale] = await Promise.all([
      import(/* webpackChunkName: 'fullcalendar-core' */ '@fullcalendar/react'),
      import(/* webpackChunkName: 'fullcalendar-daygrid' */ '@fullcalendar/daygrid'),
      import(/* webpackChunkName: 'fullcalendar-timegrid' */ '@fullcalendar/timegrid'),
      import(/* webpackChunkName: 'fullcalendar-frlocale' */ '@fullcalendar/core/locales/fr')
    ]);

    this.FullCalendar = FullCalendar.default;
    this.dayGridPlugin = dayGridPlugin.default;
    this.timeGridPlugin = timeGridPlugin.default;
    this.frLocale = frLocale.default;
  }

  @action async fetchEvents(startDate, endDate) {
    this.isLoading = true;
    const store = new DomainStore();
    await store._compose([
      endpoints.CALENDAR_EVENTS.with(startDate, endDate)
    ]);

    this.events = store._getAll(types.CALENDAR_EVENT, CalendarEvent);
    this.isLoading = false;
  }
}

export default CalendarState;
