import {$applyNodeReplacement, DecoratorNode} from 'lexical';
import * as React from 'react';
import ImageComponent from 'components/lexical_editor/nodes/ImageComponent';

function convertImageElement(domNode) {
  const dataEntityType = domNode.getAttribute('data-entity-type');
  const dataKey = domNode.getAttribute('data-key');

  if (domNode.children[0] instanceof HTMLImageElement) {
    const {alt: altText, src, width} = domNode.children[0];
    const node = $createImageNode({width, altText, src, dataKey, dataEntityType});
    return {node};
  }
  return null;
}

export class ImageNode extends DecoratorNode {
  static getType() {
    return 'image';
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__key,
      node.__dataKey,
      node.__dataEntityType
    );
  }

  static importJSON(serializedNode) {
    const {altText, width, maxWidth, src, dataKey, dataEntityType} = serializedNode;
    return $createImageNode({
      altText,
      maxWidth,
      src,
      width,
      dataKey,
      dataEntityType
    });
  }

  exportDOM(_editor) {
    const span = document.createElement('span');
    if (this.__dataKey) {
      span.setAttribute('data-key', this.__dataKey);
    }
    if (this.__dataEntityType) {
      span.setAttribute('data-entity-type', this.__dataEntityType);
    }
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__altText);
    element.setAttribute('width', this.__width);
    element.style.maxWidth = this.__maxWidth ? `${this.__maxWidth}px` : '100%';
    span.appendChild(element);
    return {element: span};
  }

  static importDOM() {
    return {
      span: (node) => {
        if (node.childElementCount === 1 && node.children[0].tagName === 'IMG') {
          return {
            conversion: convertImageElement,
            priority: 4,
          };
        }
        return null;
      },
    };
  }

  constructor(src, altText, maxWidth, width, key, dataKey, dataEntityType) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || 'inherit';
    this.__dataKey = dataKey;
    this.__dataEntityType = dataEntityType;
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      maxWidth: this.__maxWidth ? this.__maxWidth : '100%',
      src: this.getSrc(),
      type: 'image',
      version: 1,
      width: this.__width === 'inherit' ? 0 : this.__width,
      dataKey: this.__dataKey,
      dataEntityType: this.__dataEntityType
    };
  }

  setWidth(width) {
    const writable = this.getWritable();
    writable.__width = width;
  }

  createDOM(config, _editor) {
    const span = document.createElement('span');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    if (this.__dataKey) {
      span.setAttribute('data-key', this.__dataKey);
    }
    if (this.__dataEntityType) {
      span.setAttribute('data-entity-type', this.__dataEntityType);
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <ImageComponent
        src={this.__src}
        altText={this.__altText}
        width={this.__width}
        maxWidth={this.__maxWidth}
        nodeKey={this.getKey()}
        dataKey={this.__dataKey}
        dataEntityType={this.__dataEntityType}
        resizable={true}
      />
    );
  }
}

export function $createImageNode({altText, maxWidth, src, width, key, dataKey, dataEntityType}) {
  return $applyNodeReplacement(
    new ImageNode(
      src,
      altText,
      maxWidth,
      width,
      key,
      dataKey,
      dataEntityType
    ),
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
