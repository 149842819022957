import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PayrollHoursSummaryView from './PayrollHoursSummaryView';
import _ from 'lodash';

const COLUMNS = [
  {
    header: 'time_tracking.payroll_hours.Employee Name',
    width: 4
  },
  {
    header: 'time_tracking.payroll_hours.Regular',
    width: 1
  },
  {
    header: 'time_tracking.payroll_hours.Overtime',
    width: 1
  },
  {
    header: 'time_tracking.payroll_hours.Double Overtime',
    width: 1
  },
  {
    header: 'time_tracking.payroll_hours.Holiday Worked',
    width: 1
  },
  {
    header: 'time_tracking.payroll_hours.Paid Time Off',
    width: 1
  },
  {
    header: 'time_tracking.payroll_hours.Total',
    width: 1
  },
  {
    width: 2
  }
];

const PayrollHoursTableOverlay = observer(({isUpdating = false}) => {
  if (!isUpdating) return null;

  return (
    <div className='AccordionTableOverlay'/>
  );
});

const PayrollHoursTableHeaders = observer(({horizontalScroll}) => {
  return (
    <tr className='AccordionTableHeaders'>
      {COLUMNS.map(column => (
        <th
          key={column.header}
          className={`${horizontalScroll ? '' : `col-${column.width}`}`}
          style={horizontalScroll ? {minWidth: `${column.width * 5}rem`} : {}}
        >
          {column.header && <FormattedMessage id={column.header}/>}
        </th>
      ))}
    </tr>
  );
});

const PayrollHoursTable = observer(({models, isUpdating, horizontalScroll, uiState}) => {
  if (_.isEmpty(models)) {
    return (
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id='time_tracking.payroll_hours.No employees to display'/>
      </div>
    );
  }

  return (
    <div className={`AccordionTable ${horizontalScroll ? 'overflow-auto' : ''}`}>
      <PayrollHoursTableOverlay isUpdating={isUpdating}/>
      <table className={horizontalScroll ? 'width-auto min-width-full' : 'full-width'}>
        <thead>
          <PayrollHoursTableHeaders horizontalScroll={horizontalScroll}/>
        </thead>
        <tbody>
          {models.map(payrollHoursSummaryViewModel => <div className='AccordionTableView'>
            <PayrollHoursSummaryView model={payrollHoursSummaryViewModel} horizontalScroll={horizontalScroll} uiState={uiState}/>
          </div>)}
        </tbody>
      </table>
    </div>
  );
});

export default PayrollHoursTable;
