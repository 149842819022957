import React from 'react';
import {observer} from 'mobx-react';
import {Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import _ from 'lodash';

const BatchAddEmployeesResultsModal = observer(({modalOpen, uiState}) => {
  const {addInProgress, addedEmployees, erroredEmployees} = uiState;

  return (
    <Modal
      size='md'
      title={'nmbr.employees.directory.Batch Add Employees'}
      isOpen={modalOpen}
    >
      {
        addInProgress ?
          <div>
            <FormattedMessage id='nmbr.employees.directory.Adding employees to payroll. This may take a couple minutes.'/>
            <Spinner/>
          </div>
          :
          <div>
            {!_.isEmpty(addedEmployees) && <div className='mb2'>
              <FormattedMessage id='nmbr.employees.directory.Successfully Added Employees:'/>
              <ul className='mb2'>
                {
                  addedEmployees.map(addedEmployee => <li className='ml2'>{addedEmployee.name}</li>)
                }
              </ul>
            </div>
            }
            {!_.isEmpty(erroredEmployees) && <div>
              <FormattedMessage id='nmbr.employees.directory.Employees With Errors:'/>
              <ul>
                {
                  erroredEmployees.map(erroredEmployee => <li className='ml2'>{`${erroredEmployee.name} - ${erroredEmployee.errorMessage}`}</li>)
                }
              </ul>
            </div>
            }
          </div>
      }
      <ModalButtons
        cancelCaption='nmbr.employees.directory.Close'
        onCancel={() => uiState.closeBatchAddEmployeesResultsModal()}
        hideSave
      />
    </Modal>
  );
});

export default BatchAddEmployeesResultsModal;
