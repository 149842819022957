import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import {t, endpoints, types} from 'shared/core';
import CompanyInsuranceCarrier from 'stores/benefits/CompanyInsuranceCarrier';
import {successAlert} from 'shared/tools';

class BenefitEnrolmentListState {
  store = new DomainStore();
  agent;
  history;

  @observable statusOptions = [
    {
      id: 'live',
      display: t('models.benefit_enrolment_summary.status.active')
    },
    {
      id: 'terminated',
      display: t('models.benefit_enrolment_summary.status.terminated')
    }
  ];

  @observable companyInsuranceCarriers = [];
  @observable employees = [];
  @observable hasProbation = true;
  @observable errors = {};

  receiveProps({history}) {
    this.history = history;
  }

  routeForEnrolment(model) {
    if (model.terminated && !model.effectiveDate) return null;

    if (model.enrolmentStatus !== 'not_enrolled' || model.terminated) {
      return `/benefit_enrolments/${model.employee.id}/${model.employee.urlSlug}`;
    } else if (model.canEnrol) {
      return `/benefit_enrolments/${model.employee.id}/new`;
    }
  }

  @action async load() {
    await this.store._compose([
      endpoints.COMPANY_INSURANCE_CARRIERS.WITHOUT_OPTIONAL
    ]);

    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER,
      CompanyInsuranceCarrier
    );
  }

  @action async initFormFields() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    this.employees = this.store.getEmployees();
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action navigateToCreateEnrolment() {
    this.history.push('/benefit_enrolments/enrol');
  }

  @computed get carrierOptions() {
    return this.companyInsuranceCarriers.map(c => {
      return {
        id: c.id,
        display: c.name
      };
    });
  }

  @action async undoMarkForUnenrolment(enrolmentSummary) {
    const {model} = await this.store.post(
      endpoints.EMPLOYEE.UNDO_MARK_FOR_UNENROLMENT.with(enrolmentSummary.employee.id),
      types.EMPLOYEE_DETAILS,
      { companyInsuranceCarrierId: enrolmentSummary.companyInsuranceCarrierId }
    );

    if (model) {
      successAlert(t('benefit_enrolment.unenrol.Termination Undone'));
      this.agent.refresh();
    }
  }

  customLinksFor(model) {
    const links = [];

    if (model.canEnrol) {
      links.push(
        {
          text: 'benefit_enrolment.new.Enrol in Benefits',
          action: '/benefit_enrolments/:id/new'
        }
      );
    }

    if (model.canEditEnrolment) {
      links.push(
        {
          text: 'benefit_enrolment.change.Change Enrolment',
          action: '/benefit_enrolments/:id/change'
        }
      );
    }

    if (model.canUnenrol) {
      links.push(
        {
          text: 'benefit_enrolment.unenrol.Terminate Benefits',
          action: '/benefit_enrolments/:id/unenrol'
        }
      );
    }

    if (model.canUndoUnenrolment) {
      links.push(
        {
          text: 'benefit_enrolment.unenrol.Undo Termination',
          action: () => this.undoMarkForUnenrolment(model)
        }
      );
    }

    return links;
  }
}

export default BenefitEnrolmentListState;
