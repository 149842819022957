import _, {noop} from 'lodash';
import {observable} from 'mobx';
import {patchModel} from '../store';
import {errorAlert} from './index';
import {t} from 'shared/core';
import {errorBody} from 'shared/core/api';

class Autosaver {
  @observable status;
  @observable model;
  @observable callback;

  constructor(model, callback) {
    this.model = model;
    this.callback = callback;
    this.autosave = _.debounce(this.__performAutosave, 500, {
      maxWait: 30000
    });
    this.autosaveImmediately = () => this.__performAutosave();
  }

  async __performAutosave() {
    if (_.get(this.model, 'links.self.href')) {
      this.status = 'saving';
      this.model.autosavedAt = Date.now();
      const {status, errors, model} = await patchModel(this.model.link('self'), this.model._type, this.model);
      if (status === 409) {
        this.status = 'error';

        errorAlert(
          {
            header: t('components.autosave.Autosaving failed'),
            body: t('components.autosave.Please refresh the page to continue editing.')
          }
        );
      } else if (status === 422) {
        this.model.errors = errors;
      } else {
        this.status = 'saved';
        this.model.errors = {};
        this.model.previouslyAutosavedAt = this.model.autosavedAt;
        this.callback(model);
      }
    } else {
      errorAlert({
        header: t("Disabled while impersonating"),
        body: errorBody(t("READ_ONLY_ERROR_BODY"), false)
      });
    }
  }
}

export default function setupAutosave(model, callback = noop) {
  return new Autosaver(model, callback);
}
