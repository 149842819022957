import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PayrollUpdatesContainer from './PayrollUpdatesContainer';

const PayrollUpdatesPage = observer(() => {
  return (
    <Router basename='/payroll_updates'>
      <Route path='/' render={() => <PayrollUpdatesContainer/>}/>
    </Router>
  );
});

export default PayrollUpdatesPage;
