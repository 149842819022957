import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel, Spinner} from 'components';
import TimesheetEditor from 'containers/time_tracking/components/TimesheetEditor';
import TimesheetsState from 'containers/time_tracking/components/timesheets/state/TimesheetsState';
import EmployeeSelectorPanel from 'containers/time_tracking/components/timesheets/components/EmployeeSelectorPanel';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const NoEmployeePanel = observer(() => {
  return (
    <Panel>
      <div className='h2 center jumbo p4'>
        <FormattedMessage id='time_tracking.timesheets.No employee selected'/>
      </div>
    </Panel>
  );
});

const TimesheetsContainerWithState = observer(({uiState}) => {
  const {timesheet, paySchedules, selectedPayPeriod, initialLoad} = uiState;

  if (_.isEmpty(paySchedules)) return null;

  return (
    <div>
      <Layout>
        <Layout.Section secondary>
          <EmployeeSelectorPanel uiState={uiState}/>
        </Layout.Section>
        <Layout.Section>
          {initialLoad ? <Spinner/> : timesheet ? <TimesheetEditor payPeriod={selectedPayPeriod} employee={timesheet.employee} projects={timesheet.projects} employeeSelectorState={uiState}/> : <NoEmployeePanel uiState={uiState}/>}
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(TimesheetsContainerWithState, TimesheetsState);
