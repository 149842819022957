import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {Employee} from 'stores/employees';
import TimesheetEntry from 'stores/time_tracking/TimesheetEntry';
import TimeTrackingPayPeriod from 'stores/time_tracking/TimeTrackingPayPeriod';
import _ from 'lodash';
import Absence from 'stores/time_off/Absence';
import Holiday from 'stores/holidays/Holiday';

class Timesheet extends DomainObject {
  @observable entries = [];
  @observable absences = [];
  @observable holidays = [];
  @observable totals = {};
  @observable approved = false;
  @observable readOnly;
  @observable canApprove;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      entries: [TimesheetEntry],
      absences: [Absence],
      holidays: [Holiday],
      payPeriod: TimeTrackingPayPeriod
    });
  }

  @computed get totalHours() {
    return Math.round(_.sumBy(this.entries, 'hours') * 100) / 100;
  }
}

export default Timesheet;
