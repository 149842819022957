import React from 'react';
import {observer} from 'mobx-react';
import Input from './Input';
import Checkbox from './Checkbox';
import FilterDropdownButtons from './FilterDropdownButtons';
import PropTypes from 'prop-types';
import _ from 'lodash';

const FilterDropdownMenu = observer(({isOpen, onCancel, onApply, position, showDropdownButtons, searchable, searchValue, onChange, scrollable, wide, children}) => {
  return (
    <div className='relative'>
      <div className={`FilterDropdown-container ${position}-0 ${isOpen ? 'is-opened' : 'is-closed'}`} style={{maxHeight: '474px'}}>
        {searchable && <div className='pt2 px2'>
          <Input type='search'
                 value={searchValue}
                 onChange={e => onChange(e.target.value)}
                 className='InputSearch full-width'
                 placeholder='reports.Search'/>
        </div>}
        <div className={`FilterDropdown${wide ? '--wide' : ''}`}>
          <div className='FilterDropdown-list'
               style={{overflowY: _.get(children, '[1].length') > 9 ? 'auto' : 'none', maxHeight: scrollable ? '320px' : 'none'}}>{children}</div>
          {showDropdownButtons && <FilterDropdownButtons onCancel={onCancel} onApply={onApply}/>}
        </div>
      </div>
    </div>
  );
});

FilterDropdownMenu.Checkbox = observer(({children, checked, className, onChange}) => {
  return (
    <label className={`clearfix table clickable ${className}`}>
      <div className='col-1 table-cell align-middle pr1'>
        <Checkbox checked={checked} onChange={onChange}/>
      </div>
      <div className={`col-11 table-cell align-middle pl1 word-break ${checked ? 'tuatara' : 'jumbo'}`}>
        {children}
      </div>
    </label>
  );
});

FilterDropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  position: PropTypes.string,
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  searchValue: PropTypes.string
};

FilterDropdownMenu.defaultProps = {
  position: 'left',
  showDropdownButtons: true,
  searchable: false,
  scrollable: true
};

FilterDropdownMenu.Checkbox.defaultProps = {
  className: 'pb2'
};

export default FilterDropdownMenu;
