import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, LabelWithHeader} from 'components';

const PayScheduleDetails = observer(({uiState}) => {
  if (!uiState.displayPayPeriodDetails) return null;

  const {latestPayPeriod, nextPayPeriod, selectedPaySchedule} = uiState;

  const headerText = selectedPaySchedule.isNew ?
    t('time_tracking.manage.policies.edit.Initial Pay Period') :
    t('time_tracking.manage.policies.edit.Current Pay Period');

  return (
    <div>
      <Panel.Separator/>
      <LabelWithHeader
        header={headerText}
        model={latestPayPeriod.effectiveDateView}
      />
      <div className='mt2'>
        <LabelWithHeader
          header={t('time_tracking.manage.policies.edit.Next Pay Period')}
          model={nextPayPeriod.effectiveDateView}
        />
      </div>
    </div>
  );
});

export default PayScheduleDetails;
