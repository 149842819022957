import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {OutOfOfficeState} from '../state';
import {Panel, ActionLink, Avatar, EmojiTitle, DatePicker, Button, Table} from 'components';
import {dateSpan, newTab} from 'shared/tools';
import {FormattedMessage} from 'react-intl';

const Date = observer(({uiState}) => {
  const {selectedDate, formattedSelectedDate} = uiState;

  return (
    <div className='flex align-items-middle mb2 OutOfOffice-datepicker'>
      <Button trait='default' className='PaginationBtn--left Btn--icon' onClick={() => uiState.selectDate(selectedDate.add(-1, 'day'))}>
        <i className="material-icons">{'arrow_drop_up'}</i>
      </Button>
      <div className='flex-grow z-index-1'>
        <DatePicker
          key={formattedSelectedDate}
          className='rounded-none'
          onChange={date => uiState.selectDate(date)}
          value={selectedDate}
          options={{todayHighlight: true}}
        />
      </div>
      <Button trait='default' className='PaginationBtn--right Btn--icon' onClick={() => uiState.selectDate(selectedDate.add(1, 'day'))}>
        <i className="material-icons">{'arrow_drop_up'}</i>
      </Button>
    </div>
  );
});

const LOCALIZATION = {
  emptyState: 'hr_dashboard.out_of_office.Nobody is off on this date',
};

const RequestDetails = observer(({timeOffRequest}) => {
  const {employee} = timeOffRequest;

  return (
    <div className='flex'>
      <Avatar size='sm' employee={employee} />
      <div className='ml2 flex-grow'>
        <span>{`${employee.firstName} ${employee.lastName}`}</span>
        <div className='OutOfOffice'>
          {timeOffRequest.timeOffType && <span className='people-off-today info'>
            {`${timeOffRequest.timeOffType.name} ${timeOffRequest.todaysTimeRange ? `(${timeOffRequest.todaysTimeRange})` : timeOffRequest.todaysAbsenceDuration === '0.0' ? '' : `(${timeOffRequest.todaysAbsenceDurationFormatted})`}`}
          </span>}
          <span className='people-off-today info'>{`${dateSpan(timeOffRequest.startDate, timeOffRequest.endDate)}`}</span>
        </div>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 12,
    render: model => <RequestDetails timeOffRequest={model} />
  }
];

const OutOfOffice = observer(({uiState}) => {
  const {ptoRequests, loadingRequests, Emoji} = uiState;

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='airplane' title={t("hr_dashboard.out_of_office.Who's Out")}/>}>
        <ActionLink className='flex align-items-middle justify-end h4' onClick={() => newTab('/calendar')}>
          <i className='material-icons align-middle mr1 h3' style={{paddingBottom: 2}}>{'calendar_month'}</i>
          <FormattedMessage id='hr_dashboard.out_of_office.Calendar'/>
        </ActionLink>
      </Panel.Header>
      <Date uiState={uiState}/>
      <Table
        localization={LOCALIZATION}
        columns={COLUMNS}
        models={ptoRequests}
        isUpdating={loadingRequests}
        showHeaders={false}
        showLinks={false}
        uiState={uiState}
      />
    </Panel>
  );
});

export default withState(OutOfOffice, OutOfOfficeState);
