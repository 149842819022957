import React     from 'react';
import PropTypes from 'prop-types';
import {PdfPreview} from 'components/pdf';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import DOCUMENT_TYPES from 'stores/documents/documentTypes';
import {FormattedMessage} from 'react-intl';
import {AttachedDocumentDownload} from 'components';

const PdfViewer = ({resumeUrl, width, onFullscreen}) => {
  return (
    <div>
      <PdfPreview
        pdfUrl={resumeUrl}
        width={width}
        title={t('recruiting.hiring_funnel.Resume')}
        fullScreenOption
        callbacks={{
          onFullscreen
        }}
      />
    </div>
  );
};

const ResumeViewer = observer(({resume, width, onFullscreen}) => {
  switch (resume.documentType) {
    case DOCUMENT_TYPES.PDF:
      return <PdfViewer resumeUrl={resume.link('url')} width={width} onFullscreen={onFullscreen}/>;
    case DOCUMENT_TYPES.OFFICE:
    case DOCUMENT_TYPES.OTHER:
    default:
      return null;
  }
});

@observer class ResumePreview extends React.Component {
  @observable width;

  @action updateWidth(width) {
    this.width = width;
  }

  render() {
    if (!this.width) {
      return (
        <div ref={div => div && this.updateWidth(div.offsetWidth)}/>
      );
    }

    const {candidate, onFullscreen} = this.props;
    if (!candidate.hasResume || !candidate.resume.hasLink('url')) return null;

    return (
      <div className='pt2'>
        <div className='medium mb2 h3'>
          <FormattedMessage id='recruiting.hiring_funnel.Resume'/>
        </div>
        <div className='mb2'>
          <AttachedDocumentDownload doc={candidate.resume}/>
        </div>
        <ResumeViewer resume={candidate.resume} width={this.width} onFullscreen={onFullscreen}/>
      </div>
    );
  }
}

ResumePreview.propTypes = {
  resume: PropTypes.object
};

export default ResumePreview;
