import React from 'react';
import {observer} from 'mobx-react';
import RoeRequired from './RoeRequired';
import DefaultUpdate from './DefaultUpdate';
import TaxFormCompleted from './TaxFormCompleted';
import EmployeeRemoved from './EmployeeRemoved';

const NmbrUpdateFactory = observer(({nmbrUpdate}) => {
  switch (nmbrUpdate.eventType) {
    case 'employee_added':
    case 'employee_updated':
    case 'employment_record_created':
    case 'employment_record_updated':
    case 'employment_record_destroyed':
    case 'on_leave_record_updated':
    case 'on_leave_record_destroyed':
    case 'termination_record_destroyed':
    case 'reactivation_record_created':
    case 'reactivation_record_updated':
    case 'reactivation_record_destroyed':
    case 'return_from_leave_record_created':
    case 'return_from_leave_record_updated':
    case 'return_from_leave_record_destroyed':
    case 'time_off_request_approved':
    case 'time_off_request_updated':
    case 'time_off_request_destroyed':
    case 'timesheet_approved':
    case 'added_to_time_tracking':
    case 'removed_from_time_tracking':
      return <DefaultUpdate nmbrUpdate={nmbrUpdate}/>;
    case 'employee_removed':
      return <EmployeeRemoved nmbrUpdate={nmbrUpdate}/>;
    case 'on_leave_record_created':
    case 'termination_record_created':
      return <RoeRequired nmbrUpdate={nmbrUpdate}/>;
    case 'tax_form_completed':
      return <TaxFormCompleted nmbrUpdate={nmbrUpdate}/>;
    default:
      throw new Error(`Unsupported event type of ${nmbrUpdate.eventType}`);
  }
});

export default NmbrUpdateFactory;
