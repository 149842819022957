import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {QuickTip} from 'components';
import {t, auth} from 'shared/core';

const PayrollDisabledWarning = observer(({uiState}) => {
  const {employee} = uiState;

  if (!(auth.company.payrollEnabled && !employee.payrollEnabled) && !(auth.company.nmbrEnabled && !employee.nmbrPayrollEnabled)) {
    return null;
  }

  return (
    <div className='mb2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='employees.profile.role.PAYROLL_WARNING_HEADER'/>}
        paragraphs={[
          <FormattedMessage id={`employees.profile.role.${auth.company.nmbrEnabled ? 'NMBR_' : ''}PAYROLL_WARNING_MESSAGE`} values={{employee: employee.name}}/>
        ]}
        actions={[
          {
            caption: t('payroll.employees.Add to Payroll'),
            onClick: employee => auth.company.nmbrEnabled ? uiState.redirectToNmbrDirectory() : uiState.openAddEmployeeToPayrollModal(employee)
          }
        ]}
      />
    </div>
  );
});

export default PayrollDisabledWarning;
