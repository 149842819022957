import React from 'react';
import {observer} from 'mobx-react';
import {calendarDate} from 'shared/tools';
import {FormattedMessage} from 'react-intl';

const EmployeeRemoved = observer(({nmbrUpdate}) => {
  return (
    <FormattedMessage
      id={'nmbr.updates.directory.employee_removed.EXPLAINER'}
      values={
        {
          name: nmbrUpdate.employee.name,
          effectiveDate: calendarDate(nmbrUpdate.effectiveDate)
        }
      }
    />
  );
});

export default EmployeeRemoved;
