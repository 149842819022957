import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Input, DatePicker, CheckboxWithLabel, QuickTip, RadioButtonGroup, RadioButton, UploadFiles, FormLayout} from 'components';
import {FormattedMessage} from 'react-intl';
import {observable} from 'mobx';

const PersonalEmailInput = observer(({uiState}) => {
  const {editRecord: record, errors} = uiState;

  if (record.sendToAdmin) {
    return (
      <div className='mb1'>
        <QuickTip
          trait='caution'
          header={t('employees.profile.role.Conversion of Benefits')}
          paragraphs={[t('employees.profile.role.CONVERSION_OF_BENEFITS_DESC')]}
        />
      </div>
    );
  }

  return (
    <Input
      disabled={record.sendToAdmin}
      value={record.personalEmail}
      onChange={e => record.merge({personalEmail: e.target.value})}
      label='onboard.employee_info.Personal Email'
      errorMessage={errors.personalEmail}
      helpLink={{
        title: t('employees.profile.role.EMAIL_QUICKTIP'),
        explanation: {
          header: 'employees.profile.role.EMAIL_QUICKTIP',
          paragraphs: [t('employees.profile.role.EMAIL_QUICKTIP_DESC')]
        }
      }}
    />
  );
});

const BenefitConversionForm = observer(({uiState}) => {
  const {editRecord: record} = uiState;

  return (
    <div className='mb2'>
      <PersonalEmailInput uiState={uiState} />
      <CheckboxWithLabel
        className='mt1'
        value={record.sendToAdmin}
        onChange={e => record.merge({sendToAdmin: e.checked})}
        label="employees.profile.role.I do not have this employee's personal email."
      />
    </div>
  );
});

const ESACompliance = observer(({uiState}) => {
  const {editRecord: record, errors, displayESA} = uiState;

  if (!displayESA) return null;

  return (
    <FormLayout>
      <div className='mt2'>
        <QuickTip
          trait='caution'
          header={t('employees.profile.role.Employment Standards Act')}
          paragraphs={[
              t('employees.profile.role.ESA_DESC_1'),
              t('employees.profile.role.ESA_DESC_2'),
              t('employees.profile.role.ESA_DESC_3')
          ]}
        />
      </div>
      <RadioButtonGroup name='ESACompliance'
        label='employees.profile.role.ESA Compliance'
        className='mt2'
        selectedValue={record.withinESARequirements}
        onChange={(name, value) => record.merge({withinESARequirements: value})}
        errorMessage={errors.withinEsaRequirements}>
          <RadioButton value={true} >{t('employees.profile.role.Last day of benefits is within ESA requirements')}</RadioButton>
          <RadioButton value={false}>{t('employees.profile.role.This is a severance, and has already been approved by the carrier')}</RadioButton>
      </RadioButtonGroup>
      {record.withinESARequirements === false &&
        <div>
          <FormattedMessage id='employees.profile.role.Upload Proof of Extension of Benefits'/>
          <UploadFiles
            defaultFiles={record.proofOfExtension ? observable([record.proofOfExtension]) : observable([])}
            onChange={(files) => record.merge({proofOfExtension: files[0]})}
            maxFiles={1}
            errorMessage={errors.proofOfExtension}
            modelType='benefit_uploads'
          />
        </div>}
    </FormLayout>
  );
});

const BenefitsForm = observer(({uiState}) => {
  const {editRecord: record, employee, errors} = uiState;
  if (!employee.benefits) return null;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.Benefits')} />
      <BenefitConversionForm uiState={uiState} />
      <DatePicker
        value={record.lastDayOfBenefits}
        onChange={date => uiState.setLastDayOfBenefits(date)}
        errorMessage={errors.lastDayOfBenefits}
        label='employees.profile.role.Last day of benefits'
      />
      <ESACompliance uiState={uiState}/>
    </Panel>
  );
});

export default BenefitsForm;
