import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, Tag, Pagination, Checkbox, QuickTip} from 'components';
import {observer} from 'mobx-react';
import CandidateListEmptyState from './CandidateListEmptyState';
import CandidateRater from './CandidateRater';
import {useRouteMatch} from 'react-router-dom';

const CandidateItem = observer(({viewModel, uiState}) => {
  return (
    <ActionLink onClick={() => uiState.selectCandidate(viewModel)} className='block'>
      <div
        className={`flex align-items-middle clearfix border-bottom px3 py2 ${viewModel.active ? 'bg-sky border-right--dodger' : 'bg-white'} ${viewModel.stateChanged ? 'remove-shrink' : 'max-height-200'}`}>
        <div className='col-2'>
          <Checkbox checked={viewModel.selected} onChange={(e) => uiState.setCandidateChecked(e.checked, viewModel)}/>
        </div>
        <div className='col-10'>
          <div
            className={`table-cell align-items-middle flex flex-wrap ${viewModel.stateChanged ? 'is-fading-out-2' : ''}`}>
            <span className={`medium mr1 ${viewModel.active ? 'dodger' : 'tuatara'}`}>{viewModel.name}</span>
            {viewModel.candidate.score && <div className='mr1'>
              <CandidateRater score={viewModel.candidate.score}/>
            </div>}
            {viewModel.candidate.hired && <Tag colour='meadow' className='mr1'><FormattedMessage id='recruiting.candidate_directory.Hired'/></Tag>}
            {viewModel.candidate.referred && <Tag colour='dodger' className='mr1'><FormattedMessage id='recruiting.candidate_directory.Referral'/></Tag>}
            {viewModel.candidate.disqualified && <Tag colour='rose'><FormattedMessage id='recruiting.candidate_directory.Disqualified'/></Tag>}
          </div>
          <div className={`waterloo ${viewModel.stateChanged ? 'is-fading-out-2' : ''}`}>
            <FormattedMessage
              id='recruiting.hiring_funnel.CANDIDATE_LIST_ENTERED_STAGE'
              values={{stageName: viewModel.currentStageName, date: viewModel.currentStageDate}}
            />
          </div>
        </div>
      </div>
    </ActionLink>
  );
});

const FilteredCandidates = observer(({models, uiState, isUpdating}) => {
  if (!models.length) {
    return <CandidateListEmptyState uiState={uiState}/>;
  }

  return (
    <div className={isUpdating ? 'relative' : ''}>
      {isUpdating && <div className='TableOverlay'></div>}
      {models.map((viewModel, index) =>
        <CandidateItem key={index} viewModel={viewModel} uiState={uiState}/>)}
    </div>
  );
});

const CandidatePagination = observer((props) => {
  return (
    <div className='relative mx2 mb2'>
      <Pagination {...props}/>
    </div>
  );
});

const SelectAllBanner = observer(({uiState}) => {
  const {
    currentPageCandidatesAllChecked,
    allCandidatesSelected,
    selectedCandidatesCount,
    totalCandidatesCount,
    candidatesListPageSize
  } = uiState;

  if (!currentPageCandidatesAllChecked || totalCandidatesCount <= candidatesListPageSize) return;

  let quickTipContent;

  if (allCandidatesSelected) {
    quickTipContent = [
      <FormattedMessage id='recruiting.candidate_directory.ALL_SELECTED'
                        values={{count: totalCandidatesCount}}/>,
      <ActionLink onClick={() => uiState.toggleCurrentPageCandidateSelection(false)}>
        <FormattedMessage id='recruiting.candidate_directory.Clear selection'/>
      </ActionLink>
    ];
  } else {
    quickTipContent = [
      <FormattedMessage id='recruiting.candidate_directory.CURRENT_PAGE_SELECTED'
                        values={{count: selectedCandidatesCount}}/>,
      <ActionLink onClick={() => uiState.selectAllCandidates()}>
        <FormattedMessage id='recruiting.candidate_directory.SELECT_ALL'
                          values={{count: totalCandidatesCount}}/>
      </ActionLink>
    ];
  }

  return (
    <div className='pb2'>
      <QuickTip
        trait='info'
        paragraphs={quickTipContent}
      />
    </div>
  );
});

const BulkActions = observer(({uiState}) => {
  const {currentPageCandidatesAllChecked, selectedCandidatesCount, totalCandidatesCount, displayDisqualifyBulkAction, displayQualifyBulkAction, candidateStageFilter, showMovePositionButton} = uiState;

  return (
    <div className='pl3 px2 py1 bg-smoke border-bottom'>
      <SelectAllBanner uiState={uiState}/>
      <div className='flex align-items-middle'>
        <div className='flex col-2 nonclickable'>
          <Checkbox checked={currentPageCandidatesAllChecked}
                    onChange={(e) => uiState.toggleCurrentPageCandidateSelection(e.checked)}
                    disabled={totalCandidatesCount === 0}
          />
        </div>
        <div className='col-10 relative'>
          <div
            data-toggle={selectedCandidatesCount === 0 ? '' : 'dropdown'}
            className={`flex align-items-middle action-links ${selectedCandidatesCount > 0 ? 'dodger clickable' : 'waterloo nonclickable'}`}
          >
            <FormattedMessage id='recruiting.candidate_directory.Actions'/>
            <i className='material-icons h2'>{'arrow_drop_down'}</i>
          </div>
          <ul className='dropdown-menu'>
            <li>
              <ActionLink className='py1 p2 regular jumbo' onClick={() => uiState.bulkDisqualificationAction()} disabled={!displayDisqualifyBulkAction}>
                <FormattedMessage id='recruiting.candidate_directory.Disqualify'/>
              </ActionLink>
            </li>
            <li>
              <ActionLink className='py1 p2 regular jumbo' onClick={() => uiState.bulkQualify()} disabled={!displayQualifyBulkAction}>
                <FormattedMessage id='recruiting.candidate_directory.Qualify'/>
              </ActionLink>
            </li>
            <li>
              <ActionLink className='py1 p2 regular jumbo' onClick={() => uiState.detailsState.openEditTagsModal(true)}>
                <FormattedMessage id='recruiting.candidate_directory.Add Tags'/>
              </ActionLink>
            </li>
            <li>
              <ActionLink className='py1 p2 regular jumbo' onClick={() => uiState.openBulkMoveStageModal()} disabled={!candidateStageFilter}>
                <FormattedMessage id='recruiting.candidate_directory.Move Stage'/>
              </ActionLink>
            </li>
            {showMovePositionButton && <li>
              <ActionLink className='py1 p2 regular jumbo' onClick={() => uiState.detailsState.openMovePositionModal(true)}>
                <FormattedMessage id='recruiting.candidate_directory.Move Position'/>
              </ActionLink>
            </li>}
          </ul>
        </div>
      </div>
    </div>
  );
});

const CandidateList = ({uiState, ...rest}) => {
  const [stickyToBottom, setStickyToBottom] = useState(false);
  const match = useRouteMatch();

  const [content, setContent] = useState(null);
  const contentRef = useCallback(node => {
    if (node !== null) {
      setContent(node);
    }
  }, []);

  const calculateStickyToBottom = useCallback(() => {
    if (content && (content.offsetHeight + 56 > window.innerHeight)) {
      setStickyToBottom(true);
    } else {
      setStickyToBottom(false);
    }
  }, [content]);

  useEffect(() => {
    calculateStickyToBottom();
  }, [match.params, content]);

  useEffect(() => {
    window.addEventListener("resize", calculateStickyToBottom, false);
  }, []);

  return (
    <div className='col col-4 flex-column flex'>
      {stickyToBottom && <div style={{flexGrow: '1'}}></div>}
      <div ref={contentRef} className='border-right sticky-to-top' style={{marginRight: '-1px', bottom: stickyToBottom ? '0' : '', top: stickyToBottom ? 'unset' : '56px'}}>
        <BulkActions uiState={uiState}/>
        <FilteredCandidates uiState={uiState} {...rest}/>
        <CandidatePagination {...rest}/>
      </div>
    </div>
  );
};

export default observer(CandidateList);
