import React from 'react';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Display} from 'components';

const Subnav = observer(() => {
  return (
    <Display title={t('nmbr.Payroll')}>
      <NavLink exact activeClassName='active' to='/'>
        <FormattedMessage id='nmbr.Manage'/>
      </NavLink>
      <NavLink activeClassName='active' to='/employees'>
        <FormattedMessage id='nmbr.Employees'/>
      </NavLink>
      <NavLink activeClassName='active' to='/updates'>
        <FormattedMessage id='nmbr.Updates'/>
      </NavLink>
    </Display>
  );
});

export default Subnav;
