import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveEmployeeModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      size='md'
      header='nmbr.employees.directory.Remove from payroll'
      subHeader='nmbr.employees.directory.Are you sure you want to remove this employee from payroll?'
      body='nmbr.employees.directory.They will be removed as of the end of the last run payroll, and will not appear in your next payroll cycle.'
      isOpen={modalOpen}
      onHide={() => uiState.closeRemoveEmployeeModal()}
    >
      <ModalDangerButtons
        onSave={() => uiState.removeEmployee()}
        saveCaption={'nmbr.employees.directory.Remove'}
        onCancel={() => uiState.closeRemoveEmployeeModal()}
      />
    </AlertModal>
  );
});

export default RemoveEmployeeModal;
