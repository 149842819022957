import React from 'react';
import {noop, scrollsIntoViewIfErrors} from 'shared/tools';
import {observer} from 'mobx-react';
import RadioButton from 'components/RadioButton';
import RadioButtonGroup from 'components/RadioButtonGroup';
import {CheckboxWithLabel, FormError} from 'components';
import _ from 'lodash';
import Description from 'components/forms/question/components/Description';

const RadioButtons = observer(({answer, disabled, onChanged}) => {
  const options = answer.question.options;
  if (answer.value && !_.find(options, (o) => o.value === answer.value)) {
    options.push({value: answer.value});
  }
  return (
    <RadioButtonGroup
      selectedValue={answer.value}
      onChange={(name, value) => {answer.updateValue(value); onChanged();}}
    >
      {options.map((o, index) => (
        <div key={index} className='my1'>
          <RadioButton className='table-cell pr2 align-middle' value={o.value} disabled={disabled}>
            {o.value}
          </RadioButton>
        </div>
      ))}
    </RadioButtonGroup>
  );
});

function choiceSelected(answer, option) {
  const options = answer.value || [];
  return _.includes(options, option.value);
}

function addToAnswer(answer, option) {
  const options = answer.value || [];
  if (!_.includes(options, option.value)) {
    options.push(option.value);
  }
  answer.updateValue(options);
}

function removeFromAnswer(answer, option) {
  const options = answer.value || [];
  _.remove(options, o => o === option.value);
  answer.updateValue(options);
}

const Checkboxes = observer(({answer, disabled, onChanged}) => {
  const options = answer.question.options;

  if (answer.value) {
    answer.value.forEach(value => {
      if (!_.find(options, (o) => o.value === value)) {
        options.push({value: value});
      }
    });
  }

  return (
    <div>
      {options.map((c, index) => (
        <div key={index}>
          <CheckboxWithLabel
            label={c.value}
            translatable={false}
            disabled={disabled}
            checked={choiceSelected(answer, c)}
            onChange={(e) => {e.checked ? addToAnswer(answer, c) : removeFromAnswer(answer, c); onChanged();}}
          />
        </div>
      ))}
    </div>
  );
});

const QuestionContent = observer(({answer, disabled, onChanged}) => {
  if (answer.question.questionType === 'multi_select') {
    return (
      <Checkboxes answer={answer} disabled={disabled} onChanged={onChanged}/>
    );
  }

  return (
    <RadioButtons answer={answer} disabled={disabled} onChanged={onChanged}/>
  );
});

const MultipleChoiceField = observer(({answer, required, errorMessage, disabled, onChanged}) => {
  return (
    <div className={`${errorMessage ? 'MultipleChoiceField--hasErrors' : ''}`}>
      <Description answer={answer} required={required} />
      <QuestionContent answer={answer} disabled={disabled} onChanged={onChanged} />
      <FormError message={errorMessage}/>
    </div>
  );
});

MultipleChoiceField.defaultProps = {
  onChanged: noop
};

export default scrollsIntoViewIfErrors(MultipleChoiceField, '.MultipleChoiceField--hasErrors');
