import React from 'react';
import {observer} from 'mobx-react';
import useScrollShadows from '../hooks/useScrollShadows';
import ActionLinks from './ActionLinks';
import ModelValue from './ModelValue';

const Row = observer(({
  model,
  columns,
  onEditClicked,
  editRoute,
  onRemoveClicked,
  editModelCaption,
  removeModelCaption,
  showLinks,
  customLinks,
  clickable,
  onClick,
  trait,
  rowStyle,
  onlyShowCustomLinks,
  horizontalScroll,
  containerRef,
  ...rest
}) => {
  const [
    stickyColumnRef,
    showShadow,
    actionLinksRef,
    showActionLinksShadow
  ] = useScrollShadows(horizontalScroll, containerRef);

  return (
    <tr className={`TableRow-${trait} ${clickable ? 'clickable' : 'unclickable'} ${model.rowClasses ? model.rowClasses : ''}`} onClick={onClick}>
      {columns.map((c, index) => (
        <td
          key={index}
          className={`${rowStyle} ${c.className || ''} ${c.sticky ? 'sticky-column' : ''} ${c.sticky && showShadow ? 'sticky-shadow' : ''}`}
          style={horizontalScroll ? {minWidth: `${c.width * 5}rem`} : {width: `${(c.width / 12) * 100}%`}}
          ref={c.sticky ? stickyColumnRef : null}
        >
          <ModelValue model={model} column={c} rest={rest}/>
        </td>
      ))}
      {showLinks &&
        <td
          className={`right-align ${rowStyle} ${horizontalScroll ? 'action-links-column' : ''} ${horizontalScroll && showActionLinksShadow ? 'sticky-shadow' : ''}`}
          ref={actionLinksRef}
        >
          <ActionLinks
            customLinks={customLinks}
            onlyShowCustomLinks={onlyShowCustomLinks}
            model={model}
            action={editRoute || onEditClicked}
            onRemoveClicked={onRemoveClicked}
            editModelCaption={editModelCaption}
            removeModelCaption={removeModelCaption}
          />
        </td>
      }
    </tr>
  );
});

export default Row;
