import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {endpoints, types, t, api} from 'shared/core';
import {successAlert} from 'shared/tools';
import {NmbrPaySchedule} from 'stores/nmbr';
import _ from 'lodash';
import {Employee} from 'stores/employees';

class NmbrEmployeesDirectoryState {
  interactiveAgent;
  store = new DomainStore();

  @observable paySchedules = [];

  @observable selectedEmployee;
  @observable selectedEmployees;
  @observable selectedPayScheduleId;
  @observable addInProgress = false;
  @observable batchAddResult;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.NMBR.PAY_SCHEDULES.ALL
    ]);

    this.paySchedules = this.store._getAll(
      types.NMBR.PAY_SCHEDULE,
      NmbrPaySchedule
    );
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async addEmployee() {
    const {model, errors} = await this.store.post(
      `${this.selectedEmployee.link('addToPayroll')}${this.selectedPayScheduleId ? `?nmbr_pay_schedule_id=${this.selectedPayScheduleId}` : ''}`,
      types.EMPLOYEE
    );

    this.errors = errors;
    if (model) {
      this.closeAddEmployeeModal();
      successAlert(t('nmbr.employees.directory.EMPLOYEE_ADDED', { name: this.selectedEmployee.name }));
      this.interactiveAgent.refresh();
    }
  }

  @action updateSelectedPayScheduleId(payScheduleId) {
    this.selectedPayScheduleId = payScheduleId;
  }

  @action async performBatchAdd() {
    this.addInProgress = true;

    const {data} = await api.post(
      endpoints.NMBR.EMPLOYEES.BATCH_ADD_EMPLOYEES,
      {
        employeeIds: _.map(this.selectedEmployees, 'id'),
        nmbrPayScheduleId: this.selectedPayScheduleId
      }
    );

    this.batchAddResult = _.map(data.data, employee => new Employee({id: employee.id, ...employee.attributes}));
    this.interactiveAgent.refresh();
    this.addInProgress = false;
  }

  @action batchAddEmployees() {
    if (!this.selectedPayScheduleId) {
      this.errors = { nmbrPayScheduleId: t('nmbr.employees.directory.errors.Pay schedule is required') };
      return;
    }

    this.errors = {};
    this.closeBatchAddEmployeesModal();
    this.openBatchAddEmployeesResultsModal();
    this.performBatchAdd();
  }

  @action async removeEmployee() {
    const {model} = await this.store.post(
      this.selectedEmployee.link('removeFromPayroll'),
      types.EMPLOYEE
    );

    if (model) {
      successAlert(t('nmbr.employees.directory.EMPLOYEE_REMOVED', { name: this.selectedEmployee.name }));
      this.closeRemoveEmployeeModal();
      this.interactiveAgent.refresh();
    }
  }

  @computed get addedEmployees() {
    return _.filter(this.batchAddResult, employee => !employee.errorMessage);
  }

  @computed get erroredEmployees() {
    return _.filter(this.batchAddResult, employee => employee.errorMessage);
  }

  customLinksFor(employee) {
    const links = [];

    if (employee.hasLink('addToPayroll')) {
      links.push({
        order: 0,
        text: 'nmbr.employees.directory.Add to payroll',
        action: () => this.openAddEmployeeModal(employee)
      });
    }

    if (employee.hasLink('removeFromPayroll')) {
      links.push({
        order: 0,
        text: 'nmbr.employees.directory.Remove from payroll',
        action: () => this.openRemoveEmployeeModal(employee)
      });
    }

    return links;
  }
}

export default NmbrEmployeesDirectoryState;
