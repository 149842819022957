import React from 'react';
import {observer} from 'mobx-react';
import {Button, Panel, Table, ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const ReorderFieldsButton = observer(({uiState, group}) => {
  return (
    <div className='table-cell'>
      <Button size='sm' trait='default' onClick={() => uiState.openReorderCustomFieldsModal(group)}>
        <FormattedMessage id='company_settings.custom_fields.Reorder Fields'/>
      </Button>
    </div>
  );
});

const AddFieldButton = observer(({uiState, group}) => {
  return (
    <div className='table-cell'>
      <Button className='ml2' size='sm' onClick={() => uiState.openEditFieldModal(group)}>
        <FormattedMessage id='company_settings.custom_fields.+ Add Custom Field'/>
      </Button>
    </div>
  );
});

const HeaderActions = observer(({uiState, group}) => {
  return (
    <ActionLink onClick={() => uiState.openEditGroupModal(group)}>
      <i className='material-icons align-middle submarine ml1 mb1'>{'edit'}</i>
    </ActionLink>
  );
});


const COLUMNS = [
  {
    header: 'company_settings.custom_fields.Name',
    attribute: 'name',
    width: 5
  },
  {
    header: 'company_settings.custom_fields.Type',
    render: model => model.formattedFieldType,
    width: 3
  },
  {
    header: 'company_settings.custom_fields.Employee Group',
    render: model => model.employeeGroup.name,
    width: 3
  }
];

const CustomFieldGroup = observer(({uiState, group}) => {
    const {sortedCustomFields} = group;

    return (
      <Panel>
        <Panel.Header title={group.name} actions={<HeaderActions uiState={uiState} group={group}/>}>
          <div className='inline-block'>
            <ReorderFieldsButton uiState={uiState} group={group}/>
            <AddFieldButton uiState={uiState} group={group}/>
          </div>
        </Panel.Header>
        <Table
          columns={COLUMNS}
          models={sortedCustomFields}
          onEdit={customField => uiState.openEditFieldModal(group, customField)}
          onRemoveClicked={customField => uiState.openRemoveModal(customField)}
        />
      </Panel>
    );
  }
);

export default CustomFieldGroup;
